import {
  React,
} from "react";
import {
  Box,
  Card,
  CardBody,
  Flex,
  Heading,
  HStack,
  VStack,
  Text,
} from '@chakra-ui/react';
import { useNavigate, useParams, Link } from "react-router-dom";
import { useQuery } from 'react-query';
import api from "../utils/api";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import Header from '../components/Header'
import GameCard from '../components/GameCard';
import Avatar from '../components/Avatar';
import FaveStar from '../components/FaveStar';
import ReportFlag from '../components/ReportFlag';
import EmailPopup from '../components/EmailPopup';




dayjs.extend(utc);
const UserProfilePage = ({ mode }) => {
  const navigate = useNavigate();
  const { userId } = useParams();
  const user = useQuery(['user', userId], () => api('/users/' + userId));

  if (user.isLoading) {
    return <Box>
      <Header />
      <Flex direction={'column'} w={'100%'} p={8} alignItems={'center'}>
        <Heading>Loading user...</Heading>
      </Flex>
    </Box>

  }

  return <Box>
    <Header />
    <Flex direction={'column'} w={'100%'} p={8} alignItems={'center'}>
      <Card width="100%" style={{ display: 'inline-block', overflow: 'hidden' }}>
        <CardBody justify="space-between">
          <HStack justify="space-between">
            <Heading>{user && user.data.username} {user?.data?.firstName || user?.data?.lastName ? '(' + user?.data?.firstName + ' ' + user?.data?.lastName + ')' : ''}</Heading>
            <Avatar size={120} avatar={user.data.avatar} />
          </HStack>
          <HStack w="100%" justify="space-between">
            <VStack my={6} align="start">
              <Text fontSize="md" fontWeight="bold">Joined</Text>
              <Text fontSize="md" color="muted">{dayjs(user.data.joined).format('MMMM D, YYYY')}</Text>
            </VStack>
            {user?.data?.birthday && <VStack my={6} align="start">
              <Text fontSize="md" fontWeight="bold">Birth Day</Text>
              <Text fontSize="md" color="muted">{dayjs(user.data.birthday).utc().format('MMMM D')}</Text>
            </VStack>}
            {user?.data?.location && <VStack my={6} align="start">
              <Text fontSize="md" fontWeight="bold">Location</Text>user.
              <Text fontSize="md" color="muted">{user.data.location}</Text>
            </VStack>}
            {user?.data?.pronouns && <VStack my={6} align="start">
              <Text fontSize="md" fontWeight="bold">Pronouns</Text>
              <Text fontSize="md" color="muted">{user.data.pronouns}</Text>
            </VStack>}
            <VStack align="top" mb={8}>
              <FaveStar kind="user" id={user?.data?.id} />
            </VStack>
          </HStack>
          {user?.data?.bio && <Box>
            <Text fontSize="md" fontWeight="bold">Bio</Text>
            <Text fontSize="md" color="muted">
              {user.data.bio}
            </Text>
          </Box>}
          {user.data.facebookLink && <VStack my={6} align="start">
            <Text fontSize="md" fontWeight="bold">Facebook</Text>
            <Text fontSize="md" color="muted"><Link target="_blank" to={user.data.facebookLink} isExternal>{user.data.facebookLink}</Link></Text>
          </VStack>
          }
          {user.data.discordLink && <VStack my={6} align="start">
            <Text fontSize="md" fontWeight="bold">Discord</Text>
            <Text fontSize="md" color="muted"><Link target="_blank" to={user.data.discordLink} isExternal>{user.data.discordLink}</Link></Text>
          </VStack>
          }
          {user.data.twitterLink && <VStack my={6} align="start">
            <Text fontSize="md" fontWeight="bold">Twitter</Text>
            <Text fontSize="md" color="muted"><Link target="_blank" to={user.data.twitterLink} isExternal>{user.data.twitterLink}</Link></Text>
          </VStack>
          }
          {user.data.instagramLink && <VStack my={6} align="start">
            <Text fontSize="md" fontWeight="bold">Instagram</Text>
            <Text fontSize="md" color="muted"><Link target="_blank" to={user.data.instagramLink} isExternal>{user.data.instagramLink}</Link></Text>
          </VStack>
          }
          <EmailPopup label={`Contact ${user.data.username}`} user={user.data} />
          <HStack justify="end">
            <ReportFlag kind="user" id={user?.data?.id} />
          </HStack>
        </CardBody>
      </Card>
      <Flex wrap="wrap" justifyContent="center">
        {user?.data.games && <Heading size="md" mt={6} mb={3} variant="subhead" style={{ textAlign: 'center' }}>{user.data.username}'s Games</Heading>}
        {user?.data.games && user.data.games.map(game => <GameCard game={game} callback={() => navigate(`/games/${game.id}`)} />)}
      </Flex>

    </Flex>
  </Box>
}



export default UserProfilePage;
