import { menuAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(menuAnatomy.keys)

// define the base component styles
const baseStyle = definePartsStyle({
  // define the part you're going to style
  button: {
    // this will style the MenuButton component
    fontWeight: 'medium',
    padding: '13px 20px',
    bg: 'transparent',
    borderRadius: '8px',
    _hover: {
      bg: 'white',
      color: 'navy',
    },
  },
  list: {
    // this will style the MenuList component
    py: 0,
    borderRadius: 0,
    border: 'none',
    bg: 'navy',
  },
})

const variants = {
  navActive: {
    button: {
      color: 'orange',
    }
  },
  options: {
    list: {
      border: '1px solid',
      borderColor: '',
    },
    groupTitle: {
      color: 'orange',
    },
    item: {
      bg: 'lightNavy',
      color: 'white',
      _hover: {
        bg: 'navy',
      },
    }
  }
};
// export the base styles in the component theme
export const menuTheme = defineMultiStyleConfig({ baseStyle, variants })
