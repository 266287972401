import useUserState from "../stores/user";

const api = (url, data = -1, method = 'POST') => {
  if (data === -1) {
    method = 'QUERY';
    data = null;
  } else if (typeof (data) == 'string') {
    method = data;
    data = null;
  }

  var params = { method: method == 'QUERY' ? 'GET' : method, headers: {} };
  const user = useUserState.getState().user
  if (data && data !== -1) {
    if (data instanceof FormData) {
      params.body = data;
    } else if (method == 'GET' || method == 'QUERY') {
      url += '?';
      for (var key in data) {
        url += key + '=' + escape(data[key]) + '&';
      }
    } else {
      params.body = JSON.stringify(data);
      params.headers['Content-Type'] = 'application/json';
    }
  }
  //console.log(user);
  if (user && user.access_token) {
    params.headers['Authorization'] = user.access_token;
  }

  console.log(url, method, params);
  return fetch(process.env.REACT_APP_API_URL + url, params).then(res => res.json()).then((res) => {
    return method == 'QUERY' ? res.data : res
  }).catch((err) => {
    console.log(err);
  });
};

export default api;
