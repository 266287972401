import React, { useState } from 'react';
import {
  Text,
  Link
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import useFilterState from "../stores/filters";
import { useTimezoneSelect, allTimezones } from 'react-timezone-select'
import useModalState from '../stores/modals';


dayjs.extend(utc);
dayjs.extend(timezone);

const LocalTime = ({ time, ...props }) => {
  const { filters } = useFilterState();
  const { options, parseTimezone } = useTimezoneSelect({ timezones: allTimezones });
  const { opentz } = useModalState();

  let lt = dayjs.tz(dayjs().format('YYYY-MM-DD') + ' ' + time, props.tz);
  let tz = props.tz;
  let tzinfo;
  if (filters.timezone) {
    lt = lt.tz(filters.timezone);
    tz = filters.timezone;
    try { tzinfo = parseTimezone(tz); } catch (e) { /* ignore */ }
  }

  const open = (event) => {
    event.stopPropagation();
    event.cancelBubble = true;
    opentz();
  };


  return (
    <Text {...props}>
      {props.justTz ? '' : lt.format('h:mmA')} <Link onClick={open}>{!props.skiptz && tzinfo?.abbrev}</Link>
    </Text>
  )
};
export default LocalTime;
