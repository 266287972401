import React, { useState } from "react";
import {
  Box,
  Button,
  Container,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
} from '@chakra-ui/react';
import { FaFacebook, FaTwitter, FaInstagram } from "react-icons/fa";
import { BsDiscord } from "react-icons/bs";
import { Link } from "react-router-dom";
import Header from '../../components/Header'
import Error from "../../components/Error";
import Success from "../../components/Success";
import useUserState from "../../stores/user";
import api from "../../utils/api";

const ChangePasswordPage = (props) => {
  const { user, update } = useUserState((state) => state);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [p1, setP1] = useState('');
  const [p2, setP2] = useState('');

  const save = () => {
    setSuccess('');
    if (p1 != p2) {
      setError('Passwords do not match');
      return;
    }
    if (p1.length < 8) {
      setError('Password must be at least 8 characters');
      return;
    }
    setLoading(true);
    setError('');
    setSuccess('');
    api('user/me', { password: p1 }).then((res) => {
      if (res.error) setError(res.error);
      else {
        setLoading(false);
        setSuccess('Password updated');
        setP1('');
        setP2('');
      }
    }).catch((e) => {
      setError("Unable to save, try again later.");
      setLoading(false);
    }).finally(() => setLoading(false));
  };


  return <Box>
    <Header />
    <Box mx={4}><Link to='/account'>&lt; Back to Account</Link></Box>
    <Container variant="md" >
      <Flex direction={'column'} w={'100%'} p={8} alignItems={'center'}>
        <Heading>Change Password</Heading>
      </Flex>

      {success && <Success>{success}</Success>}
      {error && <Error>{error}</Error>}
      <FormControl variant="floating" pb={4} mt={4}>
        <Input onChange={(ev) => setP1(ev.target.value)} type="password" placeholder=" " value={p1} />
        <FormLabel>Password</FormLabel>
      </FormControl>
      <FormControl variant="floating" pb={4}>
        <Input onChange={(ev) => setP2(ev.target.value)} type="password" placeholder=" " value={p2} />
        <FormLabel>Confirm Password</FormLabel>
      </FormControl>

      <Button w="100%" onClick={save} disabled={loading} style={{ opacity: loading ? 0.3 : 1 }}>Save</Button>
    </Container>


  </Box>
}

export default ChangePasswordPage;