import { Card, CardBody, HStack, Text, VStack } from '@chakra-ui/react';
import Avatar from './Avatar';
import FaveStar from './FaveStar';



const PlayerCard = ({ player, w, children }) => {
    return <Card w={w || "md"} m={4} style={{ cursor: 'pointer' }} onClick={() => document.location.href = '/users/' + player.id}>
        <CardBody py={4} w="100%">
            <VStack align="start" py={0}>
                <HStack justify="space-between" w="100%" align="start">
                    <HStack >
                        <Avatar avatar={player.avatar} size={60} />
                        <Text ml={4} fontSize="xl" style={{ fontWeight: 'semibold', color: 'white' }}>{player.username}</Text>
                    </HStack>
                    <FaveStar kind="user" id={player.id} />

                </HStack>
                {children}
            </VStack>
        </CardBody>
    </Card>
};

export default PlayerCard;
