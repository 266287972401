import React from 'react';
import {
    useDisclosure,
    Icon,
    Button,
    Textarea,

    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    AlertDialogCloseButton,
} from '@chakra-ui/react';
import { FaBug } from "react-icons/fa";
import { useQuery } from 'react-query';
import api from "../utils/api";


const ReportBug = ({ kind, id }) => {
    const cancelRef = React.useRef()
    const textRef = React.useRef()
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [done, setDone] = React.useState(false);

    const open = () => {
        setDone(false);
        onOpen();
    };
    const report = async (e) => {
        e.preventDefault();
        e.stopPropagation();

        await api('messaging/bugreport', { message: textRef.current.value + "\n\nURL:" + window.location }, 'POST');
        setDone(true);
    };

    return <>
        <AlertDialog
            isOpen={isOpen}
            leastDestructiveRef={cancelRef}
            onClose={onClose}
        >
            <AlertDialogOverlay>
                <AlertDialogContent bg="#272639" borderColor="muted" borderWidth={4} maxWidth="xl" w="xl" h="md" maxHeight="md">
                    <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                        Report a Bug
                    </AlertDialogHeader>

                    {done && <AlertDialogBody>
                        Thank you for your report.
                    </AlertDialogBody>}

                    {!done && <AlertDialogBody>
                        Please describe your issue in as much detail as possible:
                        <Textarea w="100%" h="90%" ref={textRef} />
                    </AlertDialogBody>}

                    <AlertDialogFooter>
                        {done && <Button variant="outline" ref={cancelRef} onClick={onClose}>
                            Close
                        </Button>}
                        {!done && <>
                            <Button variant="outline" ref={cancelRef} onClick={onClose}>
                                Cancel
                            </Button>
                            <Button onClick={report} ml={3}>
                                Report
                            </Button>
                        </>}
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog >
        <Icon
            as={FaBug}
            w={25}
            h={25}
            mx={4}
            color="white"
            cursor="pointer"
            onClick={open}
            style={{ position: "fixed", bottom: "10px", right: "10px", zIndex: "1000" }}
        />
    </>
};

export default ReportBug;
