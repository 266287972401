import { tabsAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(tabsAnatomy.keys)

// define the base component styles
const baseStyle = definePartsStyle({
  // define the part you're going to style
  tab: {
    fontWeight: 'semibold', // change the font weight
    _selected: {
        color: 'white',
        borderBottomColor: 'orange',
    }
  },
  tablist: {
  },
  root: {
    color: 'muted',
  }
});

const variants = {
    line: {
      tab: {
        _selected: {
            borderColor: 'orange',
        }
      }
    },
  };

// export the component theme
export const tabsTheme = defineMultiStyleConfig({ baseStyle, variants })