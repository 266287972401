import React from 'react';
import {
    useDisclosure,
    Icon,
    Button,
    Textarea,
    Link,
    HStack,
    Text,

    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    AlertDialogCloseButton,
} from '@chakra-ui/react';
import { FaFlag } from "react-icons/fa";
import { useQuery } from 'react-query';
import api from "../utils/api";


const ReportFlag = ({ kind, id, text }) => {
    const cancelRef = React.useRef()
    const textRef = React.useRef()
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [done, setDone] = React.useState(false);

    const open = () => {
        setDone(false);
        onOpen();
    };
    const report = async (e) => {
        e.preventDefault();
        e.stopPropagation();

        await api('flags/', { kind, objectId: id, comment: textRef.current.value }, 'POST');
        setDone(true);
    };

    return <>
        <AlertDialog
            isOpen={isOpen}
            leastDestructiveRef={cancelRef}
            onClose={onClose}
        >
            <AlertDialogOverlay>
                <AlertDialogContent bg="#272639" borderColor="muted" borderWidth={4}>
                    <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                        Report Inappropriate Content
                    </AlertDialogHeader>

                    {done && <AlertDialogBody>
                        Thank you for your report.
                    </AlertDialogBody>}

                    {!done && <AlertDialogBody>
                        Why do you want to report this content?
                        <Textarea w="100%" h={30} ref={textRef} />
                    </AlertDialogBody>}

                    <AlertDialogFooter>
                        {done && <Button variant="outline" ref={cancelRef} onClick={onClose}>
                            Close
                        </Button>}
                        {!done && <>
                            <Button variant="outline" ref={cancelRef} onClick={onClose}>
                                Cancel
                            </Button>
                            <Button onClick={report} ml={3}>
                                Report
                            </Button>
                        </>}
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
        <Link onClick={open}>
            <HStack>
                <Icon
                    as={FaFlag}
                    cursor="pointer"
                    pt={text ? 1 : 0}
                />
                {text && <Text>{text}</Text>}
            </HStack>
        </Link>
    </>
};

export default ReportFlag;
