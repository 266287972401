import { extendTheme } from '@chakra-ui/react';
import { cardAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(cardAnatomy.keys)

const baseStyle = definePartsStyle({
  // define the part you're going to style
  container: {
    backgroundColor: '#272639',
    border: '1px solid rgba(255, 255, 255, 0.1);',
    borderRadius: '8px',
  },
});

export const cardTheme = defineMultiStyleConfig({ baseStyle})
