import React, { useState } from "react";
import {
    Box,
    Container,
    Checkbox,
    Flex,
    Heading,
    HStack,
    Input,
    InputGroup,
    InputLeftElement,
    Tab,
    Tabs,
    TabList,
    TabPanel,
    TabPanels,
    Tag,
    VStack,
} from '@chakra-ui/react';
import { useNavigate } from "react-router-dom";
import Header from '../components/Header'
import VenueCard from "../components/VenueCard";
import GameCard from "../components/GameCard";
import PlayerCard from "../components/PlayerCard";
import { useQuery } from 'react-query';
import { SearchIcon } from '@chakra-ui/icons';

import api from "../utils/api";

const FavoritesPage = (props) => {
    const navigate = useNavigate();
    const allTags = useQuery('tags', () => api('games/tags')).data;
    const favoritesQuery = useQuery('favorites', () => api('user/me/favorites'));
    const [tagFilter, setTagFilter] = useState('');

    const people = favoritesQuery.data ? favoritesQuery.data.filter((f) => f.kind == 'user').map((f) => f.item).filter((b) => !!b) : [];
    const venues = favoritesQuery.data ? favoritesQuery.data.filter((f) => f.kind == 'venue').map((f) => f.item).filter((b) => !!b) : [];
    const games = favoritesQuery.data ? favoritesQuery.data.filter((f) => f.kind == 'game').map((f) => f.item).filter((b) => !!b) : [];

    const tags = favoritesQuery.data ? favoritesQuery.data.filter((f) => f.kind == 'tag').map((f) => f.itemId).filter((b) => !!b) : [];
    const toggleTag = async (tag) => {
        if (!favoritesQuery.data) return;

        if (favoritesQuery.data.filter((f) => f.kind == 'tag' && f.itemId == tag.id).length) {
            await api('user/me/favorites', { kind: 'tag', itemId: tag.id }, 'DELETE');
        } else {
            await api('user/me/favorites', { kind: 'tag', itemId: tag.id }, 'POST');
        }
        favoritesQuery.refetch();
    };

    return <Box>
        <Header />
        <Flex direction={'column'} w={'100%'} px={2} alignItems={'center'}>
            <Heading>Favorites</Heading>
            <Tabs w="100%">
                <TabList style={{ maxWidth: '800px', margin: '0px auto' }}>
                    <Tab w="33%">Venues</Tab>
                    <Tab w="33%">Games</Tab>
                    <Tab w="33%">People</Tab>
                </TabList>
                <TabPanels>
                    <TabPanel>
                        <Flex justifyContent={'center'} wrap="wrap">
                            {venues && venues.map((venue) => <VenueCard
                                venue={venue}
                                callback={() => navigate('/venues/' + venue.id)}
                                key={venue.id} />)}
                            {venues.length == 0 && <Heading size="md">No venues favorited yet.</Heading>}
                        </Flex>
                    </TabPanel>
                    <TabPanel>
                        <Flex justifyContent={'center'} wrap="wrap">
                            {games && games.map((game) => <GameCard
                                game={game}
                                callback={() => navigate('/games/' + game.id)}
                                key={game.id} />)}
                            {games.length == 0 && <Heading size="md">No games favorited yet.</Heading>}
                        </Flex>
                    </TabPanel>
                    <TabPanel>
                        <Flex justifyContent={'center'} wrap="wrap">
                            {people && people.map((player) => <a href={"/users/" + player.id}><PlayerCard
                                player={player}
                                key={player.id} /></a>)}
                            {people.length == 0 && <Heading size="md">No people favorited yet.</Heading>}
                        </Flex>
                    </TabPanel>
                    {false && <TabPanel>
                        <Container size="md">
                            <VStack align="start">
                                <InputGroup mb={4}>
                                    <InputLeftElement
                                        pointerEvents='none'
                                        children={<SearchIcon color='gray.300' />}
                                    />
                                    <Input placeholder="Search" variant="muted" value={tagFilter} onChange={(e) => setTagFilter(e.target.value)} />
                                </InputGroup>
                                {allTags && allTags.map((tag) => {
                                    return (tagFilter.length == 0 || tag.name.toLowerCase().indexOf(tagFilter.toLowerCase()) > -1) ? <Box key={tag.id}>
                                        <Checkbox isChecked={tags.includes(tag.id)} onChange={(e) => toggleTag(tag)} mr={2}>
                                            <Tag variant={tag.variant}>{tag.name}</Tag>
                                        </Checkbox>
                                    </Box> : null
                                })}
                            </VStack>
                        </Container>
                    </TabPanel>}
                </TabPanels>
            </Tabs>
        </Flex>
    </Box>
}

export default FavoritesPage;
