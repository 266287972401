import {
    Box,
    Text,
} from '@chakra-ui/react';

const Error = (props) => {
    return <Box p={2} style={{ borderWidth: 1, borderColor: 'red', backgroundColor: 'rgba(255, 200, 200, 1.0)', borderRadius: '5px', marginBottom: '0.5em' }}>
        <Text style={{ fontWeight: 'bold', color: 'red' }}>{props.children}</Text>
    </Box>
};

export default Error;