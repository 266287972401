import React, {
    useState,
    useRef,
} from "react";

import {
    Box,
    Button,
    Container,
    Checkbox,
    CheckboxGroup,
    Flex,
    FormControl,
    FormLabel,
    Heading,
    HStack,
    VStack,
    Icon,
    Input,
    InputGroup,
    InputLeftElement,
    Image,
    SimpleGrid,
    Text,
    Textarea,
    Drawer,
    DrawerContent,
    DrawerOverlay,
    useDisclosure,
    useCheckboxGroup,
} from '@chakra-ui/react';
import { Link } from "react-router-dom";
import { useNavigate, useParams } from "react-router-dom";
import useGamesState from "../../stores/games";
import useUserState from "../../stores/user";
import _ from "underscore";
import Header from '../../components/Header';
import { useQuery } from 'react-query';
import api from '../../utils/api'
import { Select } from "chakra-react-select";
import reactSelectStyles from "../../theme/reactSelectStyles";
import { FaFacebook, FaTwitter, FaInstagram } from "react-icons/fa";
import { BsDiscord } from "react-icons/bs";
import Success from "../../components/Success";
import Error from "../../components/Error";
import { set } from "date-fns";


const VenueStaffPage = ({ }) => {
    const user = useUserState((state) => state.user);
    const [loading, setLoading] = useState(false);
    const { venueId } = useParams();
    const navigate = useNavigate();
    const [permissions, setPermissions] = useState([]);
    const emailRef = useRef();
    const { value, setValue } = useCheckboxGroup({ defaultValue: [], });
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);

    const venue = useQuery(['venue', venueId], () => api('/venues/' + venueId)).data;
    const staffQuery = useQuery(['venue', venueId, 'staff'], () => api('/venues/' + venueId + '/staff'));
    const staff = staffQuery.data;

    const addStaff = () => {
        setError(null);
        setSuccess(null);
        if (!value.length) return setError('Please select at least one permission');
        if (!emailRef.current.value || emailRef.current.value.length < 1) return setError('Please enter a username or email');
        setLoading(true);

        api('/venues/' + venueId + '/staff', { username: emailRef.current.value, permissions: value }).then((res) => {
            setLoading(false);
            if (res.data?.error) setError(res.data.error);
            else if (res.data?.result) {
                setSuccess(res.data.result);
                emailRef.current.value = '';
                setValue([]);
                staffQuery.refetch();
            }
        });
    }

    const pNames = (p) => {
        switch (p) {
            case 'staff': return 'Manage Staff';
            case 'edit': return 'Edit Venue';
            case 'calendar': return 'Manage Calendar';
        }
    };

    const remove = (s) => {
        api('/venues/' + venueId + '/staff/' + s.id, {}, 'DELETE').then((res) => {
            if (res.data?.error) setError(res.data.error);
            else if (res.data?.result) {
                setSuccess(res.data.result);
                emailRef.current.value = '';
                setValue([]);
                staffQuery.refetch();
            }
        });
    }


    return <Box>
        <Header />
        <Container variant="md" >
            <Flex direction={'column'} w={'100%'} p={8} alignItems={'center'}>
                <Heading><i>{venue && venue.name}</i> - Staff</Heading>
            </Flex>
            <Flex wrap="wrap" justifyContent="center">
                <Heading size="md" mb={4}>Current Staff</Heading>
                <VStack w={'100%'} alignItems={'start'} mb={8}>
                    {staff && staff.length < 1 && <Text>No staff members</Text>}
                    {staff && staff.map((s) => <HStack w="100%" justifyContent="space-between" key={s.id} bg="lightNavy" p={1} mb={1} >
                        <Text>{s.username}</Text>
                        <Text>{s.email}</Text>
                        <Text>{s.permissions.map(pNames).join(', ')}</Text>
                        <Text><a href="#" onClick={() => remove(s)}>Remove</a></Text>
                    </HStack>)}
                </VStack>
                <Heading size="md" mb={4}>Add Staff Member</Heading>
                <VStack w={'100%'} alignItems={'start'}>
                    {success && <Success>{success}</Success>}
                    {error && <Error>{error}</Error>}
                    <FormControl isRequired variant="floating" pb={4}>
                        <Input type="text" placeholder=" " ref={emailRef} />
                        <FormLabel>Username or E-Mail</FormLabel>
                    </FormControl>
                    <Heading size="sm" mb={1}>Permissions</Heading>
                    <CheckboxGroup onChange={setValue}>
                        <Checkbox value="staff">Manage Staff (can add new Staff members to this Venue in The Adventure Nexus)</Checkbox>
                        <Checkbox value="edit">Edit Venue (can change Venue Profile information)</Checkbox>
                        <Checkbox value="calendar">Manage Calendar (Can create games and other events for the Venue calendar)</Checkbox>
                    </CheckboxGroup>
                    <Button w="100%" onClick={addStaff} disabled={loading} style={{ opacity: loading ? 0.3 : 1 }}>Add</Button>


                </VStack>
            </Flex>
        </Container>
    </Box >
}

export default VenueStaffPage;
