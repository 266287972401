import {
    React,
    useState,
    useRef,
} from "react";
import {
    Box,
    Button,
    Container,
    Flex,
    FormControl,
    FormLabel,
    HStack,
    Input,
    Link,
    Image,
    Divider,
    Heading,
    Text,
    VStack,

    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Textarea,
} from '@chakra-ui/react';
import api from "../../utils/api";
import { useQuery } from 'react-query';
import Header from '../../components/Header';
import Success from '../../components/Success';


const HelpPage = ({ }) => {
    const messageRef = useRef();
    const emailRef = useRef();
    const [success, setSuccess] = useState(false);

    const send = async () => {
        await api('messaging/invite', {
            message: messageRef.current.value,
            email: emailRef.current.value
        }, 'POST');
        messageRef.current.value = '';
        setSuccess('Invite sent!');
    };

    return <Box>
        <Header back="/account" backLabel="Back to Account" />
        <Flex direction={'column'} w={'100%'} p={8} alignItems={'center'}>
            <Box order={{ base: 1, md: 2 }} width={{ base: "100%", md: "40%" }} pl={{ base: "0%", md: "10%" }} mb={12}>
                <Heading >Invite a Friend</Heading>
                {success && <Success>
                    <Box>{success}</Box>
                    <HStack justify="end"><a href="#" style={{ color: "black" }} onClick={() => setSuccess(false)}>Send another?</a></HStack>
                </Success>}
                {!success && <>
                    <FormControl variant="floating" mt={4} position="relative">
                        <Input mb={4} ref={emailRef} placeholder=" " />
                        <FormLabel>Your Friend's E-Mail Address</FormLabel>
                    </FormControl>
                    <FormControl variant="floating" mt={4} position="relative">
                        <Textarea h="xs" ref={messageRef} placeholder=" " />
                        <FormLabel>Type your message here...</FormLabel>
                    </FormControl>
                    <HStack justify="end" mt={4}>
                        <Button onClick={send}>Send Message</Button>
                    </HStack>
                </>}
            </Box>
        </Flex>
    </Box >
};

export default HelpPage;
