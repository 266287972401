import {
    React,
    useState,
    useRef,
} from "react";
import {
    Box,
    Button,
    Container,
    Flex,
    FormControl,
    FormLabel,
    HStack,
    Input,
    Image,
    Divider,
    Heading,
    Text,
    VStack,

    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Textarea,
} from '@chakra-ui/react';
import api from "../../utils/api";
import { useQuery } from 'react-query';
import Header from '../../components/Header';
import Success from '../../components/Success';


const HelpPage = ({ }) => {
    const messageRef = useRef();
    const [success, setSuccess] = useState(false);
    const faqs = useQuery('faqs', () => api('faqs')).data;

    const send = async () => {
        await api('messaging/contact', { message: messageRef.current.value }, 'POST');
        messageRef.current.value = '';
        setSuccess('Message sent! We will get back to you shortly.');
    };

    return <Box>
        <Header back="/account" backLabel="Back to Account" />
        <Box mt={4} display="flex" width="100%" px={4} flexDirection={{ base: "column", md: "row" }}>
            <Box order={{ base: 2, md: 1 }} width={{ base: "100%", md: "55%" }}>
                <Heading>Frequently Asked Questions</Heading>
                <Accordion allowToggle>
                    {faqs && faqs.map((faq) => <AccordionItem>
                        <h2>
                            <AccordionButton _expanded={{ bg: 'orange', color: 'white' }}>
                                <Box as="span" flex='1' textAlign='left'>
                                    {faq.question}
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                        </h2>
                        <AccordionPanel>
                            <p>{faq.answer}</p>
                        </AccordionPanel>
                    </AccordionItem>)}
                </Accordion>
            </Box>
            <Box order={{ base: 1, md: 2 }} width={{ base: "100%", md: "40%" }} pl={{ base: "0%", md: "10%" }} mb={12}>
                <Heading >Contact Us</Heading>
                {success && <Success>{success}</Success>}
                {!success && <>
                    <Textarea ref={messageRef} placeholder="Type your message here..." />
                    <HStack justify="end">
                        <Button mt={2} onClick={send}>Send Message</Button>
                    </HStack>
                </>}
            </Box>
        </Box>
    </Box >
};

export default HelpPage;