import React, { useState } from 'react';
import {
    useDisclosure,
    Icon,
    Button,
    Textarea,
    Text,

    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    AlertDialogCloseButton,

    Box,
} from '@chakra-ui/react';
import api from "../utils/api";
import { useTimezoneSelect, allTimezones } from 'react-timezone-select'
import { Select } from "chakra-react-select";
import reactSelectStyles from "../theme/reactSelectStyles";
import useFilterState from "../stores/filters";
import useModalState from "../stores/modals";
import { FaEdit } from 'react-icons/fa';


const ZonePicker = ({ kind, id }) => {
    const cancelRef = React.useRef()
    const textRef = React.useRef()
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [done, setDone] = React.useState(false);
    const { filters, saveFilters, resetFilters } = useFilterState();
    const { tzModal, closetz } = useModalState();

    const { options, parseTimezone } = useTimezoneSelect({ labelStyle: "original", timezones: allTimezones })
    const tz = filters.timezone || parseTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone) || "America/New_York";
    let tmp = parseTimezone(tz);
    const [selectedTimezone, setSelectedTimezone] = useState(
        tmp
    )

    if (tzModal && !isOpen) {
        onOpen();
    } if (!tzModal && isOpen) {
        onClose();
    }

    const setTimezone = (timezone) => {
        setSelectedTimezone(timezone);
        filters.timezone = timezone?.value;
        saveFilters(filters);
    }

    const Picker = ({ onChange }) => {

        return (
            <Select
                onChange={e => onChange(parseTimezone(e))}
                options={options}
                chakraStyles={reactSelectStyles}
                classNamePrefix="chakra-react-select"
                value={selectedTimezone}
            >
            </Select>
        )
    }

    const open = () => {
        setDone(false);
        onOpen();
    };
    const report = async (e) => {
        e.preventDefault();
        e.stopPropagation();

        await api('messaging/bugreport', { message: textRef.current.value + "\n\nURL:" + window.location }, 'POST');
        setDone(true);
    };

    return <>
        <AlertDialog
            isOpen={isOpen}
            leastDestructiveRef={cancelRef}
            onClose={onClose}
        >
            <AlertDialogOverlay>
                <AlertDialogContent bg="#272639" borderColor="muted" borderWidth={4} maxWidth="xl" w="xl" h="sm" maxHeight="sm">
                    <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                        Select your Time Zone
                    </AlertDialogHeader>

                    <AlertDialogBody>
                        <Picker
                            value={selectedTimezone}
                            onChange={setTimezone}
                        />
                    </AlertDialogBody>

                    <AlertDialogFooter>
                        <Button variant="outline" ref={cancelRef} onClick={closetz}>
                            Close
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog >

    </>
};

export default ZonePicker;
