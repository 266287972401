import { React, useState } from "react";
import {
  useDisclosure,
  Box,
  Button,
  Checkbox,
  Container,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  Flex,
  FormControl,
  FormLabel,
  Spacer,
  Heading,
  Image,
  Input,
  Text,
  Link,
  VStack,
  Code,
  Grid
} from '@chakra-ui/react';
import { useNavigate } from "react-router-dom";
import logo from "../assets/logo.png";
import Error from "../components/Error";
import useUserState from "../stores/user";
import api from "../utils/api";

const RegisterPage = (props) => {
  const { user, login } = useUserState((state) => state);
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [loading, setLoading] = useState(false);
  const [isChecked, setChecked] = useState(false);
  const [errors, setErrors] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure()


  const navigate = useNavigate();

  const handleRegister = () => {
    let tmpErrors = [];
    if (password !== password2) tmpErrors.push("Passwords don't match");
    if (password.length < 8) tmpErrors.push("Password must be at least 8 characters");
    if (!email.match(/.*@.*\..*/)) tmpErrors.push("Please enter a valid password");
    if (username.length < 1) tmpErrors.push("Username is required.");
    if (!isChecked) tmpErrors.push("You must be at least 13 years old to register.");

    setErrors(tmpErrors);
    if (tmpErrors.length) return;


    setLoading(true);

    api('/user', { username, password, email }).then((res) => {
      if (res.error) setErrors([res.error]);
      else {
        login({ ...res.data.user, access_token: res.data.access_token });
        onOpen();
      }
    }).catch((e) => {
      setErrors(["There was a problem registering, please try again later."]);
    }).finally(() => setLoading(false));

  }

  return <Container variant="md" >
    <VStack w={'100%'} p={8}>
      <Image src={logo} w={103} mb={2} />
      <Heading>Sign In</Heading>
      <Text fontWeight="light">Sign up to the AdventureNexus to continue</Text>
      {errors.map((err) => <Error>{err}</Error>)}
      <FormControl isRequired variant="floating" pb={4}>
        <Input onChange={(ev) => setEmail(ev.target.value)} placeholder=" " />
        <FormLabel>Email</FormLabel>
      </FormControl>
      <FormControl isRequired variant="floating" pb={4}>
        <Input onChange={(ev) => setUsername(ev.target.value)} placeholder=" " />
        <FormLabel>Username</FormLabel>
      </FormControl>
      <FormControl isRequired variant="floating" pb={4}>
        <Input onChange={(ev) => setPassword(ev.target.value)} type="password" placeholder=" " />
        <FormLabel>Password</FormLabel>
      </FormControl>
      <FormControl isRequired variant="floating" pb={4}>
        <Input onChange={(ev) => setPassword2(ev.target.value)} type="password" placeholder=" " />
        <FormLabel>Confirm Password</FormLabel>
      </FormControl>
      <Flex flexDirection="row" w={"100%"} pb={4} pl={1}>
        <Checkbox isChecked={isChecked} onChange={(ev) => setChecked(ev.target.checked)}>I am 13 or older</Checkbox>
      </Flex>
      <Button w="100%" onClick={handleRegister} disabled={loading} style={{ opacity: loading ? 0.5 : 1 }}>Sign Up</Button>
      <Text fontWeight="light">Have an account? <Link href="/login">Sign In</Link></Text>
    </VStack>

    <Drawer placement={"bottom"} isOpen={isOpen} >
      <DrawerOverlay />
      <DrawerContent>
        <div style={{ flexDirection: 'row', justifyContent: 'center', display: 'flex', backgroundColor: 'var(--chakra-colors-lightNavy)' }}>
          <div style={{ maxWidth: '500px', padding: '1em' }}>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Montes nascetur ridiculus mus mauris vitae ultricies. Quis lectus nulla at volutpat diam ut venenatis tellus. Amet dictum sit amet justo donec enim diam. Ligula ullamcorper malesuada proin libero nunc. Diam in arcu cursus euismod quis viverra nibh. Quis lectus nulla at volutpat diam ut venenatis tellus in. Velit scelerisque in dictum non consectetur a erat nam.
            </p>

            <p>Dictumst vestibulum rhoncus est pellentesque elit ullamcorper dignissim. Leo duis ut diam quam. Euismod quis viverra nibh cras pulvinar mattis. Ut etiam sit amet nisl purus in mollis nunc sed. Sit amet dictum sit amet. Porta lorem mollis aliquam ut porttitor leo a. Erat imperdiet sed euismod nisi porta lorem mollis aliquam. Porttitor rhoncus dolor purus non enim praesent elementum. Lacinia quis vel eros donec ac odio tempor orci dapibus. Massa tempor nec feugiat nisl pretium fusce.</p>

            <Button mt={4} w="100%" onClick={() => navigate("/")}>Okay, I got it</Button>
          </div>
        </div>
      </DrawerContent>
    </Drawer>
  </Container>
}

export default RegisterPage;
