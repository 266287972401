export default {
  container: (provided, state) => ({
    ...provided,
    cursor: 'pointer',
    width: '100%',
  }),
  control: (provided, state) => ({
    ...provided,
    minHeight: 'var(--input-height)',
    height: "auto"
  }),
  menu: (provided, state) => ({
    ...provided,
    cursor: 'pointer',
    zIndex: 9999,
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? 'navy' : 'lightNavy',
    color: 'white',
    border: '1px solid white',
    cursor: 'pointer',
  })
};