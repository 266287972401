import React, { useRef, useState } from "react";
import {
  useDisclosure,
  Box,
  Button,
  HStack,
  Flex,
  Heading,
  Icon,
  VStack,
  Text,
  Tab,
  Tabs,
  TabList,
  TabPanel,
  TabPanels,
  FormControl,
  Switch,
  InputGroup,
  InputLeftElement,
  Input,
  Radio,
  RadioGroup,
  FormLabel,
  Drawer,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from '@chakra-ui/react';
import { useNavigate } from "react-router-dom";
import Header from '../components/Header'
import VenueCard from '../components/VenueCard';
import api from '../utils/api';
import { useQuery } from 'react-query'
import { FaMapPin, FaLocationArrow } from "react-icons/fa";
import GooglePlacesAutocomplete, { geocodeByPlaceId } from 'chakra-ui-google-places-autocomplete';

import useUserState from "../stores/user";
import { SearchIcon } from '@chakra-ui/icons';
import GoogleMap from 'google-maps-react-markers'
import { openStatus } from "../utils/venues";
import { useGeolocated } from "react-geolocated";
import reactSelectStyles from "../theme/reactSelectStyles";
import {
  CreatableSelect as Combo,
  AsyncSelect, Select
} from "chakra-react-select";


const Marker = ({ label, url }) => <HStack style={{ marginLeft: '-24px', marginTop: '-24px' }} role="group">
  <Icon as={FaMapPin} color="orange" w="36px" h="36px" style={{ zIndex: 98 }} onClick={() => document.location = url} />
  <Text bg="muted" color="white" style={{ whiteSpace: 'nowrap', fontSize: '24px', borderRadius: '24px', padding: '4px 10px 4px 10px' }}
    onClick={() => document.location = url}

    display="none"
    _groupHover={{
      display: '-webkit-box !important',
      zIndex: 99,
      maxH: '72px',
    }}>{label}</Text>
</HStack>


const VenuesPage = (props) => {
  const venuesQuery = useQuery('venues', () => api('venues'));
  const navigate = useNavigate();
  const user = useUserState(state => state.user);
  const mapRef = useRef(null)
  const [showMap, setShowMap] = useState(false);
  const { isOpen: drawerIsOpen, onOpen: drawerOnOpen, onClose: drawerOnClose } = useDisclosure()
  const [filters, setFilters] = useState({});

  const { coords } = useGeolocated({
    positionOptions: {
      enableHighAccuracy: false,
    },
    userDecisionTimeout: 5000,
    onSuccess: (pos) => {
      console.log('Geolocation successful', pos);
      if (pos?.latitude && pos?.longitude) {
        if (!filters.location) {
          filters.location = { lat: pos.latitude, lon: pos.longitude, label: 'Current Location', value: { place_id: 0 } };
        }
      }
    }
  });

  const setCurrentLocation = () => {
    if (coords?.latitude && coords?.longitude) {
      if (!filters.location) {
        filters.location = { lat: coords.latitude, lon: coords.longitude, label: 'Current Location', value: { place_id: 0 } };
      }
    }
  };

  const updateFilter = async (key, val) => {
    filters[key] = val;
    if (key === 'location') {
      if (val && val.value && val.value.place_id) {
        var res = await geocodeByPlaceId(val.value.place_id);
        if (res && res.length) {
          filters.location.lat = res[0].geometry.location.lat();
          filters.location.lon = res[0].geometry.location.lng();
        }
      } else if (val === null) {
        filters.location = null;
      }
    }
  }

  const milesApart = (loca, locb) => {
    let inlat1 = parseFloat(loca?.lat);
    let inlon1 = parseFloat(loca?.lon);
    let inlat2 = parseFloat(locb?.lat);
    let inlon2 = parseFloat(locb?.lon);
    const toRad = (Value) => Value * Math.PI / 180;
    let R = 6371; // km
    let dLat = toRad(inlat2 - inlat1);
    let dLon = toRad(inlon2 - inlon1);

    let a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(inlat1) * Math.cos(inlat2);
    let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    let d = R * c;
    let miles = d / 0.6214; // miles per km

    return miles;
  };

  const filteredVenues = venuesQuery?.data?.filter((v) => {
console.log('filteredVenus running')
    if (filters.name && (!v.name || !v.name.toLowerCase().includes(filters.name.toLowerCase()))) return false;
    if (filters.open && openStatus(v) !== 'Open') return false;
    let inrange = true;
    if (filters.location && filters.location?.lat && filters.location?.lon && filters.distance) {
      let miles = milesApart(v, filters.location);
      inrange = !isNaN(miles) && miles < filters.distance;
    }
    if (!inrange) return false;
    return true;
  }).sort((a, b) => a?.name?.replace(/^ */, '').localeCompare(b?.name?.replace(/^ */, '')));

  const resetFilters = () => {
    setFilters({});
  };


  const onGoogleApiLoaded = ({ map }) => {
    mapRef.current = map
    let bounds = new window.google.maps.LatLngBounds();
    venuesQuery?.data.forEach((v) => {
      if (v.lat) bounds.extend({ lat: parseFloat(v.lat), lng: parseFloat(v.lon) });
    });
    mapRef.current.fitBounds(bounds);
    mapRef.current.setCenter(bounds.getCenter());
  };

  return <Box>
    <Header />
    <Flex direction={'column'} w={'100%'} alignItems={'center'}>
      <Heading>Venues</Heading>
      <Tabs w="100%" defaultIndex={props.mine ? 0 : 1} >
        <TabList>
          <Tab w="50%">My Venues</Tab>
          <Tab w="50%">
            <HStack justify="space-between" w="100%">
              <Text></Text>
              <Text>Nearby</Text>
              <HStack>
                <FormLabel fontSize="sm" pt={1}>View Map</FormLabel>
                <Switch size="lg" onChange={(ev) => setShowMap(ev.target.checked)} />
              </HStack>
            </HStack>

          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <VStack justify="center">
              <Button variant="wide" onClick={() => navigate('/venues/create')} mb={4}>Create New Venue</Button>
            </VStack>
            <Flex wrap="wrap" justifyContent="center">
              {venuesQuery.data && venuesQuery.data.filter((v) => v.userId === user.id).map((venue) => <VenueCard
                venue={venue}
                callback={(venue) => navigate('/venues/' + venue.id)}
                key={venue.id} />)}
            </Flex>
          </TabPanel>
          <TabPanel position="relative">
            <VStack justify="center">
              <Button onClick={drawerOnOpen} variant="wide" >Click to Set Search Filters</Button>
              <HStack mt={2} mb={4} style={{ maxWidth: 'var(--chakra-sizes-md)', width: '100%' }}>
                <InputGroup>
                  <InputLeftElement
                    pointerEvents='none'
                    children={<SearchIcon color='gray.300' />}
                  />
                  <Input placeholder="Search by name" variant="muted" value={filters.name} onChange={(e) => updateFilter('name', e.target.value)} />
                </InputGroup>

                {filters.online || filters.name || filters.system || filters.type ? <Button onClick={resetFilters} variant='outline' px={6}>Clear Filters</Button> : ''}

              </HStack>
            </VStack>
            {showMap ? <>
              {venuesQuery?.data && <Box w="100%" mt={4}>
                <GoogleMap
                  apiKey={process.env.REACT_APP_MAPS_KEY}
                  defaultCenter={{ lat: 40.7128, lng: -74.0060 }}
                  defaultZoom={14}
                  mapMinHeight="70vh"
                  onGoogleApiLoaded={onGoogleApiLoaded}
                >
                  {venuesQuery.data.map((v) => v.lat ? <Marker
                    lat={parseFloat(v.lat)}
                    lng={parseFloat(v.lon)}
                    label={v.name}
                    url={'/venues/' + v.id}
                    markerId={v.id}
                    key={v.id}
                  /> : null)}
                </GoogleMap>
              </Box>}
            </> : <>
              <Flex wrap="wrap" justifyContent="center">

                {filteredVenues && filteredVenues.map((venue) => <VenueCard venue={venue} callback={(venue) => navigate('/venues/' + venue.id)} />)}
              </Flex>
            </>}
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Flex>

    <Drawer
      isOpen={drawerIsOpen}
      placement='right'
      onClose={drawerOnClose}
      size="lg"
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader >Filters</DrawerHeader>
        <Box p={6}>

          <InputGroup pb={6}>
            <InputLeftElement
              pointerEvents='none'
              children={<SearchIcon color='gray.300' />}
            />
            <Input placeholder="Search by name" variant="muted" value={filters.name} onChange={(e) => updateFilter('name', e.target.value)} />
          </InputGroup>

          <RadioGroup alignItems="start" w="100%" pb={6} value={filters.open || ""} onChange={(ev) => updateFilter('open', ev)}>
            <HStack align="start">
              <Radio value='1' mr={6}>Open Venues</Radio>
              <Radio value=''>All Venues</Radio>
            </HStack>
          </RadioGroup>

          <FormControl variant="floating" pb={3}>
            <Select
              onChange={(e) => updateFilter('distance', e ? e.value : false)}
              defaultValue={filters.distance ? { label: filters.distance + ' miles', value: filters.distance } : null}
              isClearable={true}
              classNamePrefix="chakra-react-select"
              options={[10, 25, 50, 100, 250].map((s) => { return { label: s + ' miles', value: s } })}
              chakraStyles={reactSelectStyles}
            />
            <FormLabel>Search Radius</FormLabel>
          </FormControl>
          <HStack pb={6}>
            <FormControl variant="floating" >
              <GooglePlacesAutocomplete
                apiKey={process.env.REACT_APP_MAPS_KEY}
                placeholder=" "
                selectProps={{
                  placeholder: "Enter an Address...",
                  chakraStyles: reactSelectStyles,
                  classNamePrefix: "chakra-react-select",
                  isClearable: true,
                  value: filters?.location,
                  onChange: (value) => updateFilter('location', value),
                }} />
              <FormLabel>Location</FormLabel>
            </FormControl>
            <Button variant="outline" onClick={setCurrentLocation}><Icon as={FaLocationArrow} /></Button>
          </HStack>

          <VStack w="100%" mt={12}>
            <Button onClick={drawerOnClose} variant="wide">Search</Button>
          </VStack>
        </Box>

      </DrawerContent>
    </Drawer>

  </Box >
}

export default VenuesPage;
