import {
  React,
  useState,
  useRef,
  useEffect,
} from "react";
import {
  useDisclosure,
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  IconButton,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
  Link,
  VStack,
  Switch,

  Menu,
  MenuButton,
  MenuList,
  MenuOptionGroup,
  MenuItemOption,

  Radio,
  RadioGroup,

  Drawer,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,

  Tab,
  Tabs,
  TabList,
  TabPanel,
  TabPanels,
} from '@chakra-ui/react';
import { useNavigate } from "react-router-dom";
import {
  CreatableSelect as Combo,
  AsyncSelect, Select
} from "chakra-react-select";
import _, { filter, has } from "underscore";
import dayjs from 'dayjs';

import api from "../utils/api";
import useUserState from "../stores/user";
import useFilterState from "../stores/filters";
import { useQuery } from 'react-query'
import reactSelectStyles from "../theme/reactSelectStyles";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


import { SearchIcon } from '@chakra-ui/icons';
import { FaSortAmountDown, FaCalendar, FaMapPin, FaLocationArrow, FaPlus, FaMinus } from 'react-icons/fa';

import Header from '../components/Header'
import ZonePicker from '../components/ZonePicker'
import GameCard from '../components/GameCard';

import { useGeolocated } from "react-geolocated";
import GooglePlacesAutocomplete, { geocodeByPlaceId } from 'chakra-ui-google-places-autocomplete';
import GoogleMap from 'google-maps-react-markers'
const Marker = ({ label, url }) => <HStack style={{ marginLeft: '-24px', marginTop: '-24px' }} role="group">
  <Icon as={FaMapPin} color="orange" w="36px" h="36px" style={{ zIndex: 98 }} onClick={() => document.location = url} />
  <Text bg="muted" color="white" style={{ whiteSpace: 'nowrap', fontSize: '24px', borderRadius: '24px', padding: '4px 10px 4px 10px' }}
    onClick={() => document.location = url}
    display="none"
    _groupHover={{
      display: '-webkit-box !important',
      zIndex: 99,
      maxH: '72px',
    }}>{label}</Text>
</HStack>


const GamesPage = ({ mode }) => {
  const { user } = useUserState();
  const { filters, saveFilters, resetFilters } = useFilterState();
  const [sort, setSort] = useState('date');
  const [showMap, setShowMap] = useState(false);
  const [showMyOldGames, setShowMyOldGames] = useState(false);

  const { coords } =
    useGeolocated({
      positionOptions: {
        enableHighAccuracy: false,
      },
      userDecisionTimeout: 5000,
      onSuccess: (pos) => {
        console.log('Geolocation successful', pos);
        if (pos?.latitude && pos?.longitude) {
          if (!filters.location) {
            filters.location = { lat: pos.latitude, lon: pos.longitude, label: 'Current Location', value: { place_id: 0 } };
            saveFilters(filters);
          }
        }
      }
    });

  const { data: myRawGames } = useQuery(['games', 'mine'], () => api('/games/mine', {}, 'QUERY'));
  const { data: games, isLoading: gamesLoading } = useQuery(['games', filters.startDate, filters.endDate], () => api('/games', { startDate: filters.startDate, endDate: filters.endDate }, 'QUERY'), {
    refetchInterval: 1000 * 30,
  });
  const tags = useQuery('tags', () => api('games/tags', false, 'QUERY')).data;
  const systemsQuery = useQuery('systems', () => api('games/systems', false, 'QUERY'))
  const systems = systemsQuery.data ? systemsQuery.data.map((s) => { return { label: s.name, value: s.id, type: s.type } }) : [];
  const navigate = useNavigate();
  const { isOpen: drawerIsOpen, onOpen: drawerOnOpen, onClose: drawerOnClose } = useDisclosure()
  const mapRef = useRef(null)


  const setCurrentLocation = () => {
    if (coords?.latitude && coords?.longitude) {
      if (!filters.location) {
        filters.location = { lat: coords.latitude, lon: coords.longitude, label: 'Current Location', value: { place_id: 0 } };
        saveFilters(filters);
      }
    }
  };

  const updateFilter = async (key, val) => {
    filters[key] = val;
    saveFilters(filters);
    if (key === 'location') {
      if (val && val.value && val.value.place_id) {
        var res = await geocodeByPlaceId(val.value.place_id);
        if (res && res.length) {
          filters.location.lat = res[0].geometry.location.lat();
          filters.location.lon = res[0].geometry.location.lng();
        }
      } else if (val === null) {
        filters.location = null;
      }
    }
  }

  const milesApart = (loca, locb) => {
    let inlat1 = parseFloat(loca?.lat);
    let inlon1 = parseFloat(loca?.lon);
    let inlat2 = parseFloat(locb?.lat);
    let inlon2 = parseFloat(locb?.lon);
    const toRad = (Value) => Value * Math.PI / 180;
    let R = 6371; // km
    let dLat = toRad(inlat2 - inlat1);
    let dLon = toRad(inlon2 - inlon1);

    let a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(inlat1) * Math.cos(inlat2);
    let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    let d = R * c;
    let miles = d / 0.6214; // miles per km

    return miles;
  };

  const sortGames = (a, b) => {
    if (sort === 'createdAt') {
      return new Date(b.createdAt) - new Date(a.createdAt);
    } else if (sort === 'name') {
      return a.name.localeCompare(b.name);
    } else if (sort === 'date') {
      return new Date(a.date) - new Date(b.date);
    } else if (sort === 'distance') {
      return milesApart(a, filters.location) - milesApart(b, filters.location);
    }
  };

  const filteredGames = (games ? games.filter((g) => {
    let tomatch = g.name.toLowerCase() +
      g.tags.map((t) => t.name).join(',').toLowerCase() +
      g.system.toLowerCase() +
      g.host.name.toLowerCase();

    let tagsmatch = true;
    if (filters.tags) {
      tagsmatch = filters.tags.every((t) => g.tags.map((t) => t.name).indexOf(t.label) > -1)
    }

    let inrange = true;
    if (filters.location && filters.location?.lat && filters.location?.lon && filters.distance) {
      let miles = milesApart(g, filters.location);
      inrange = !isNaN(miles) && miles < filters.distance;
    }

    return (!filters.name || tomatch.indexOf(filters.name.toLowerCase()) > -1) &&
      ((!filters.system || filters.system.length == 0) || (g.system &&
        ((filters.system.toLowerCase && filters.system.toLowerCase() === g.system.toLowerCase())) || filters.system.map((s) => s.label.toLowerCase()).indexOf(g.system.toLowerCase()) > -1))
      && (filters.verified === "1" ? g.host.trustxVerified : true) &&
      (filters.online ? ((g.online && filters.online === "1") || (!g.online && filters.online === "0")) : true) &&
      tagsmatch &&
      inrange;
  }
  ) : []).sort(sortGames).slice(0, 50);



  const myGames = (myRawGames ? myRawGames.filter((g) => {
    let tomatch = g.name.toLowerCase() +
      g.tags.map((t) => t.name).join(',').toLowerCase() +
      g.system.toLowerCase() +
      g.host.name.toLowerCase();
    if (!showMyOldGames) {
      if (dayjs(g.recurring ? g.endDate : g.date).isBefore(dayjs(), 'day')) return false;
    }
    return (!filters.name || tomatch.indexOf(filters.name.toLowerCase()) > -1)
  }) : []).sort(sortGames);

  const hasOldGames = (myGames ? myGames.filter((g) => {
    if (new Date(g.recurring ? g.endDate : g.date) < new Date()) return true;
  }).length : false);

  const locatedGames = filteredGames.filter((g) => {
    return (g.lat && g.lon) || (g.venue?.lat && g.venue?.lon);
  }).map((g) => {
    if (!g.lat || g.venue?.lat) {
      g.lat = g.venue.lat;
      g.lon = g.venue.lon;
    }
    return g;
  });

  useEffect(() => {
    centerMap();
  });

  const centerMap = () => {
    if (!mapRef.current || !locatedGames) return;
    let bounds = new window.google.maps.LatLngBounds();
    let did = 0;
    locatedGames?.forEach((v) => {
      if (v.lat) {
        bounds.extend({ lat: parseFloat(v.lat), lng: parseFloat(v.lon) });
        did++;
      }
    });
    if (did == 0) {
      if (coords?.latitude && coords?.longitude && 0) {
        bounds.extend({ lat: coords.latitude, lng: coords.longitude });
        bounds.extend({ lat: coords.latitude - 1, lng: coords.longitude - 1 });
        bounds.extend({ lat: coords.latitude + 1, lng: coords.longitude + 1 });
      } else {
        bounds.extend({ lat: 38, lng: -97 });
        bounds.extend({ lat: 34, lng: -93 });
        bounds.extend({ lat: 48, lng: -101 });
      }
    }
    mapRef.current.fitBounds(bounds);
    mapRef.current.setCenter(bounds.getCenter());
  };

  const onGoogleApiLoaded = ({ map }) => {
    mapRef.current = map
    centerMap();
  };

  const distanceMode = () => {
    return filters.location && filters.location?.lat && filters.distance
  };

  const saveNotification = async () => {
    await api('/notifications', { filters: filters }, 'POST');
    document.location = '/account/notifications'
  };
  const activeFilter = (filters.system && filters.system.length > 0) ||
    (filters.tags && filters.tags.length > 0) ||
    filters.name ||
    filters.verified ||
    filters.online ||
    filters.startDate ||
    filters.endDate ||
    filters.location ||
    filters.distance;


  const showGame = (game) => {
    navigate("/games/" + game.id);
  };


  return <>
    <Box>
      <Header />
      <Flex direction={'column'} w={'100%'} alignItems={'center'} style={{ position: 'relative' }}>
        <Box>
          <Heading align="center">Games</Heading>
        </Box>
        <Tabs w="100%" defaultIndex={mode === 'mine' ? 0 : 1} px={2}>
          <TabList px={8}>
            <Tab w="50%">My Games</Tab>
            <Tab w="50%">
              <HStack justify="space-between" w="100%">
                <Text></Text>
                <Text>Games</Text>
                <HStack>
                  <FormLabel fontSize="sm" pt={1}>View Map</FormLabel>
                  <Switch size="lg" onChange={(ev) => setShowMap(ev.target.checked)} />
                </HStack>
              </HStack>
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel align="center">
              {user.trustxVerified || true ?
                <Button onClick={() => navigate('/games/create')} variant="wide">Host a Game</Button>
                : <Button onClick={() => navigate('/account')} variant="wide">Get Verified to host a Game</Button>}
              <HStack mt={4} style={{ maxWidth: 'var(--chakra-sizes-md)', width: '100%' }}>
                <InputGroup>
                  <InputLeftElement
                    pointerEvents='none'
                    children={<SearchIcon color='gray.300' />}
                  />
                  <Input placeholder="Search by name, host, system or tags" variant="muted" value={filters.name} onChange={(e) => updateFilter('name', e.target.value)} />
                </InputGroup>
                <Menu variant='options'>
                  <MenuButton
                    transition='all 0.2s'
                    variant='outline'
                    as={IconButton}
                    icon={<FaSortAmountDown w={3} h={3} />}
                  >
                  </MenuButton>
                  <MenuList>
                    <MenuOptionGroup defaultValue={sort} onChange={setSort} title='Sort By' type='radio'>
                      <MenuItemOption value='createdAt'>Recently Created</MenuItemOption>
                      <MenuItemOption value='name'>Name</MenuItemOption>
                      <MenuItemOption value='date'>Happening Soon</MenuItemOption>
                    </MenuOptionGroup>
                  </MenuList>
                </Menu>
                {filters.online || filters.name || filters.system || filters.type ? <Button onClick={resetFilters} variant='outline' px={6}>Clear filters</Button> : ''}

              </HStack>
              {!!hasOldGames && <Button onClick={() => setShowMyOldGames(!showMyOldGames)} variant="outline" mt={4} mb={4}><Icon mr={2} as={showMyOldGames ? FaMinus : FaPlus} />{showMyOldGames ? 'Hide' : 'Show'} Older Games</Button>}
              <Flex wrap="wrap" justifyContent="center">
                {myGames.length ? myGames.map((game) => <GameCard hosted={game.userId === user.id} callback={showGame} key={game.id} game={game} />)
                  : (gamesLoading ?
                    <Box m={4} p={4}><Text fontSize="xl" fontWeight="bold">Loading game list...</Text></Box> :
                    <Box m={4} p={4}>
                      <Text fontSize="xl" fontWeight="bold">No joined or hosted games found.</Text>
                    </Box>
                  )}
              </Flex>
            </TabPanel>
            <TabPanel align="center" position="relative">
              <Button onClick={drawerOnOpen} variant="wide">Click to Set Search Filters</Button>
              <HStack mt={4} style={{ maxWidth: 'var(--chakra-sizes-md)', width: '100%' }}>
                <InputGroup>
                  <InputLeftElement
                    pointerEvents='none'
                    children={<SearchIcon color='gray.300' />}
                  />
                  <Input placeholder="Search by name, host, system or tags" variant="muted" value={filters.name} onChange={(e) => updateFilter('name', e.target.value)} />
                </InputGroup>
                <Menu variant='options'>
                  <MenuButton
                    transition='all 0.2s'
                    variant='outline'
                    as={IconButton}
                    icon={<FaSortAmountDown w={3} h={3} />}
                  >
                  </MenuButton>
                  <MenuList>
                    <MenuOptionGroup defaultValue={sort} onChange={setSort} title='Sort By' type='radio'>
                      <MenuItemOption value='createdAt'>Recently Created</MenuItemOption>
                      <MenuItemOption value='name'>Name</MenuItemOption>
                      <MenuItemOption value='date'>Happening Soon</MenuItemOption>
                      {distanceMode() ? <MenuItemOption value='distance'>Closest to me</MenuItemOption> : null}
                    </MenuOptionGroup>
                  </MenuList>
                </Menu>
                {filters.online || filters.name || filters.system || filters.type ? <Button onClick={resetFilters} variant='outline' px={6}>Clear filters</Button> : ''}
              </HStack>
              {activeFilter && <Link onClick={saveNotification} ><Text pt={4}>Notify me about future games match this search</Text></Link>}
              {!showMap && <Flex wrap="wrap" justifyContent="center">
                {filteredGames.length ? filteredGames.map((game) =>
                  <GameCard
                    callback={showGame}
                    key={game.id}
                    game={game}
                    distance={distanceMode() ? milesApart(game, filters.location).toFixed(2) : null}
                  />)
                  : (gamesLoading ?
                    <Box m={4} p={4}><Text fontSize="xl" fontWeight="bold">Loading game list...</Text></Box> :
                    <Box m={4} p={4}><Text fontSize="xl" fontWeight="bold">No games found</Text><Link onClick={resetFilters}>Clear filters...</Link></Box>
                  )}
              </Flex>}
              {(showMap && locatedGames) && <Box w="100%" mt={4}>
                <GoogleMap
                  apiKey={process.env.REACT_APP_MAPS_KEY}
                  defaultCenter={{ lat: 40.7128, lng: -74.0060 }}
                  defaultZoom={14}
                  mapMinHeight="70vh"
                  onGoogleApiLoaded={onGoogleApiLoaded}
                >
                  {locatedGames.map((v) => v.lat ? <Marker
                    lat={parseFloat(v.lat)}
                    lng={parseFloat(v.lon)}
                    label={v.name}
                    url={'/games/' + v.id}
                    markerId={v.id}
                    key={v.id}
                  /> : null)}
                </GoogleMap>
              </Box>}
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Flex>

      <Drawer
        isOpen={drawerIsOpen}
        placement='right'
        onClose={drawerOnClose}
        size="lg"
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>
            Filters
            {filters.online || filters.name || filters.system || filters.type ? <Button onClick={resetFilters} variant='outline' mx={12}>Clear filters</Button> : ''}
          </DrawerHeader>

          <Box p={6}>
            <InputGroup mb={6}>
              <InputLeftElement
                pointerEvents='none'
                children={<SearchIcon color='gray.300' />}
              />
              <Input placeholder="Search by name, host, system or tags" variant="muted" value={filters.name} onChange={(e) => updateFilter('name', e.target.value)} />
            </InputGroup>
            {false &&
              <FormControl variant="floating" pb={6}>
                <Select
                  onChange={(e) => updateFilter('type', e ? e.value : false)}
                  defaultValue={filters.type ? { label: filters.type, value: filters.type } : null}
                  isClearable={true}
                  classNamePrefix="chakra-react-select"
                  options={_.uniq((systems || []).map((g) => g.type)).map((s) => { return { label: s, value: s } })}
                  chakraStyles={reactSelectStyles}
                />
                <FormLabel>Game Type</FormLabel>
              </FormControl>}
            <FormControl variant="floating" pb={6}>
              <AsyncSelect
                isClearable={true}
                isMulti
                onChange={(e) => updateFilter('system', e)}
                name="tags"
                defaultOptions={systems.slice(0, 7)}
                classNamePrefix="chakra-react-select"
                noOptionsMessage={() => <div><a href="/help">Missing a Game?  Click here to request it.</a></div>}
                loadOptions={(str) => new Promise((resolve, reject) => {
                  resolve(systems
                    .filter((g) => g.label.toLowerCase().indexOf(str.toLowerCase()) > -1)
                    .sort((a, b) => a.label.localeCompare(b.label))
                  )
                })}
                tagVariant="ugc"
                chakraStyles={reactSelectStyles}
              />
              <FormLabel>Game System</FormLabel>
            </FormControl>

            <Box pb={6}>
              <Combo
                defaultValue={filters.tags}
                onChange={(e) => { updateFilter('tags', e) }}
                isMulti
                name="tags"
                options={tags ? tags.map((t) => { return { variant: t.variant, value: t.id, label: t.name } }).sort((a, b) => a.label.localeCompare(b.label)) : []}
                tagVariant="ugc"
                classPrefix="test"
                h={'300px'}
                chakraStyles={reactSelectStyles}
                placeholder="Search by preferences and tags..."
              />
            </Box>

            <RadioGroup alignItems="start" w="100%" pb={6} value={filters.verified || "0"} onChange={(ev) => updateFilter('verified', ev)}>
              <HStack align="start">
                <Text>Games hosted by: </Text>
                <Radio value='0'>Anyone</Radio>
                <Radio value='1' mr={6}>Verified Users</Radio>
              </HStack>
            </RadioGroup>

            <HStack justify="middle" pb={6}>
              <Text>Between</Text>
              <DatePicker
                autoComplete='off'
                name="date-input"
                showIcon
                isClearable
                placeholderText="Start Date"
                icon={<Icon as={FaCalendar} h={16} w={16} color="white" />}
                selected={filters.startDate ? dayjs(filters.startDate).toDate() : null}
                onChange={(date) => updateFilter('startDate', date ? dayjs(date).format('YYYY-MM-DD') : null)}
              />
              <Text>and</Text>
              <DatePicker
                autoComplete='off'
                name="date-input"
                showIcon
                isClearable
                placeholderText="End Date"
                icon={<Icon as={FaCalendar} h={16} w={16} color="white" />}
                selected={filters.endDate ? dayjs(filters.endDate).toDate() : null}
                onChange={(date) => updateFilter('endDate', date ? dayjs(date).format('YYYY-MM-DD') : null)}
              />
            </HStack>

            <RadioGroup alignItems="start" w="100%" pb={6} value={filters.online || ""} onChange={(ev) => updateFilter('online', ev)}>
              <HStack align="start">
                <Radio value='1' mr={6}>Online</Radio>
                <Radio value='0'>In-Person</Radio>
              </HStack>
            </RadioGroup>



            {filters.online === "0" && <>
              <FormControl variant="floating" pb={3}>
                <Select
                  onChange={(e) => updateFilter('distance', e ? e.value : false)}
                  defaultValue={filters.distance ? { label: filters.distance + ' miles', value: filters.distance } : null}
                  isClearable={true}
                  classNamePrefix="chakra-react-select"
                  options={[10, 25, 50, 100, 250].map((s) => { return { label: s + ' miles', value: s } })}
                  chakraStyles={reactSelectStyles}
                />
                <FormLabel>Search Radius</FormLabel>
              </FormControl>
              <HStack pb={6}>
                <FormControl variant="floating" >
                  <GooglePlacesAutocomplete
                    apiKey={process.env.REACT_APP_MAPS_KEY}
                    placeholder=" "
                    selectProps={{
                      placeholder: "Enter an Address...",
                      chakraStyles: reactSelectStyles,
                      classNamePrefix: "chakra-react-select",
                      isClearable: true,
                      value: filters?.location,
                      onChange: (value) => updateFilter('location', value),
                    }} />
                  <FormLabel>Location</FormLabel>
                </FormControl>
                <Button variant="outline" onClick={setCurrentLocation}><Icon as={FaLocationArrow} /></Button>
              </HStack>
            </>}

            <VStack w="100%" mt={12}>
              <Button onClick={drawerOnClose} variant="wide">Search</Button>
            </VStack>
          </Box>

        </DrawerContent>
      </Drawer>

      <ZonePicker />
    </Box>
  </>
}



export default GamesPage;
