import { create } from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'


const useGamesState = create(
  persist(
    (set, get) => { let tmp = {
      banners: [
        { section: 'Card Games', images: [ ] },
        { section: 'Board Games', images: [ ] },
        {
          section: 'Feywild', images: [
            { url: "/gamebanners/feywild/banners-feywild-3.png", thumb: "/gamebanners/feywild/banners-feywild-3-thumb.png" },
            { url: "/gamebanners/feywild/banners-feywild-2.png", thumb: "/gamebanners/feywild/banners-feywild-2-thumb.png" },
            { url: "/gamebanners/feywild/banners-feywild-1.png", thumb: "/gamebanners/feywild/banners-feywild-1-thumb.png" },
            { url: "/gamebanners/feywild/banners-feywild-5.png", thumb: "/gamebanners/feywild/banners-feywild-5-thumb.png" },
            { url: "/gamebanners/feywild/banners-feywild-4.png", thumb: "/gamebanners/feywild/banners-feywild-4-thumb.png" },
            { url: "/gamebanners/feywild/banners-feywild-6.png", thumb: "/gamebanners/feywild/banners-feywild-6-thumb.png" },
            { url: "/gamebanners/feywild/banners-feywild-7.png", thumb: "/gamebanners/feywild/banners-feywild-7-thumb.png" },
            { url: "/gamebanners/feywild/banners-feywild-9.png", thumb: "/gamebanners/feywild/banners-feywild-9-thumb.png" },
            { url: "/gamebanners/feywild/banners-feywild-8.png", thumb: "/gamebanners/feywild/banners-feywild-8-thumb.png" },
          ]
        },
        {
          section: 'Dungeon', images: [
            { url: "/gamebanners/dungeon/banners-dungeon-1.png", thumb: "/gamebanners/dungeon/banners-dungeon-1-thumb.png" },
            { url: "/gamebanners/dungeon/banners-dungeon-3.png", thumb: "/gamebanners/dungeon/banners-dungeon-3-thumb.png" },
            { url: "/gamebanners/dungeon/banners-dungeon-2.png", thumb: "/gamebanners/dungeon/banners-dungeon-2-thumb.png" },
            { url: "/gamebanners/dungeon/banners-dungeon-6.png", thumb: "/gamebanners/dungeon/banners-dungeon-6-thumb.png" },
            { url: "/gamebanners/dungeon/banners-dungeon-7.png", thumb: "/gamebanners/dungeon/banners-dungeon-7-thumb.png" },
            { url: "/gamebanners/dungeon/banners-dungeon-5.png", thumb: "/gamebanners/dungeon/banners-dungeon-5-thumb.png" },
            { url: "/gamebanners/dungeon/banners-dungeon-4.png", thumb: "/gamebanners/dungeon/banners-dungeon-4-thumb.png" },
          ]
        },
        {
          section: 'Underdark', images: [
            { url: "/gamebanners/underdark/banners-underdark-1.png", thumb: "/gamebanners/underdark/banners-underdark-1-thumb.png" },
            { url: "/gamebanners/underdark/banners-underdark-3.png", thumb: "/gamebanners/underdark/banners-underdark-3-thumb.png" },
            { url: "/gamebanners/underdark/banners-underdark-2.png", thumb: "/gamebanners/underdark/banners-underdark-2-thumb.png" },
            { url: "/gamebanners/underdark/banners-underdark-5.png", thumb: "/gamebanners/underdark/banners-underdark-5-thumb.png" },
            { url: "/gamebanners/underdark/banners-underdark-4.png", thumb: "/gamebanners/underdark/banners-underdark-4-thumb.png" },
          ]
        },
        {
          section: 'Frozen Wastes', images: [
            { url: "/gamebanners/frozen wastes/banners-frozen wastes-2.png", thumb: "/gamebanners/frozen wastes/banners-frozen wastes-2-thumb.png" },
            { url: "/gamebanners/frozen wastes/banners-frozen wastes-3.png", thumb: "/gamebanners/frozen wastes/banners-frozen wastes-3-thumb.png" },
            { url: "/gamebanners/frozen wastes/banners-frozen wastes-1.png", thumb: "/gamebanners/frozen wastes/banners-frozen wastes-1-thumb.png" },
            { url: "/gamebanners/frozen wastes/banners-frozen wastes-4.png", thumb: "/gamebanners/frozen wastes/banners-frozen wastes-4-thumb.png" },
            { url: "/gamebanners/frozen wastes/banners-frozen wastes-5.png", thumb: "/gamebanners/frozen wastes/banners-frozen wastes-5-thumb.png" },
            { url: "/gamebanners/frozen wastes/banners-frozen wastes-7.png", thumb: "/gamebanners/frozen wastes/banners-frozen wastes-7-thumb.png" },
            { url: "/gamebanners/frozen wastes/banners-frozen wastes-6.png", thumb: "/gamebanners/frozen wastes/banners-frozen wastes-6-thumb.png" },
            { url: "/gamebanners/frozen wastes/banners-frozen wastes-8.png", thumb: "/gamebanners/frozen wastes/banners-frozen wastes-8-thumb.png" },
            { url: "/gamebanners/frozen wastes/banners-frozen wastes-9.png", thumb: "/gamebanners/frozen wastes/banners-frozen wastes-9-thumb.png" },
            { url: "/gamebanners/frozen wastes/banners-frozen wastes-10.png", thumb: "/gamebanners/frozen wastes/banners-frozen wastes-10-thumb.png" },
          ]
        },
        {
          section: 'Historical', images: [
            { url: "/gamebanners/historical/banners-historical-2.png", thumb: "/gamebanners/historical/banners-historical-2-thumb.png" },
            { url: "/gamebanners/historical/banners-historical-3.png", thumb: "/gamebanners/historical/banners-historical-3-thumb.png" },
            { url: "/gamebanners/historical/banners-historical-1.png", thumb: "/gamebanners/historical/banners-historical-1-thumb.png" },
            { url: "/gamebanners/historical/banners-historical-4.png", thumb: "/gamebanners/historical/banners-historical-4-thumb.png" },
            { url: "/gamebanners/historical/banners-historical-5.png", thumb: "/gamebanners/historical/banners-historical-5-thumb.png" },
            { url: "/gamebanners/historical/banners-historical-7.png", thumb: "/gamebanners/historical/banners-historical-7-thumb.png" },
            { url: "/gamebanners/historical/banners-historical-6.png", thumb: "/gamebanners/historical/banners-historical-6-thumb.png" },
            { url: "/gamebanners/historical/banners-historical-8.png", thumb: "/gamebanners/historical/banners-historical-8-thumb.png" },
          ]
        },
        {
          section: 'Seascape', images: [
            { url: "/gamebanners/seascape/banners-seascape-3.png", thumb: "/gamebanners/seascape/banners-seascape-3-thumb.png" },
            { url: "/gamebanners/seascape/banners-seascape-2.png", thumb: "/gamebanners/seascape/banners-seascape-2-thumb.png" },
            { url: "/gamebanners/seascape/banners-seascape-1.png", thumb: "/gamebanners/seascape/banners-seascape-1-thumb.png" },
            { url: "/gamebanners/seascape/banners-seascape-5.png", thumb: "/gamebanners/seascape/banners-seascape-5-thumb.png" },
            { url: "/gamebanners/seascape/banners-seascape-4.png", thumb: "/gamebanners/seascape/banners-seascape-4-thumb.png" },
            { url: "/gamebanners/seascape/banners-seascape-6.png", thumb: "/gamebanners/seascape/banners-seascape-6-thumb.png" },
            { url: "/gamebanners/seascape/banners-seascape-7.png", thumb: "/gamebanners/seascape/banners-seascape-7-thumb.png" },
            { url: "/gamebanners/seascape/banners-seascape-11.png", thumb: "/gamebanners/seascape/banners-seascape-11-thumb.png" },
            { url: "/gamebanners/seascape/banners-seascape-10.png", thumb: "/gamebanners/seascape/banners-seascape-10-thumb.png" },
            { url: "/gamebanners/seascape/banners-seascape-9.png", thumb: "/gamebanners/seascape/banners-seascape-9-thumb.png" },
            { url: "/gamebanners/seascape/banners-seascape-8.png", thumb: "/gamebanners/seascape/banners-seascape-8-thumb.png" },
          ]
        },
        {
          section: 'Cyberpunk', images: [
            { url: "/gamebanners/cyberpunk/banners-cyberpunk-3.png", thumb: "/gamebanners/cyberpunk/banners-cyberpunk-3-thumb.png" },
            { url: "/gamebanners/cyberpunk/banners-cyberpunk-2.png", thumb: "/gamebanners/cyberpunk/banners-cyberpunk-2-thumb.png" },
            { url: "/gamebanners/cyberpunk/banners-cyberpunk-1.png", thumb: "/gamebanners/cyberpunk/banners-cyberpunk-1-thumb.png" },
            { url: "/gamebanners/cyberpunk/banners-cyberpunk-5.png", thumb: "/gamebanners/cyberpunk/banners-cyberpunk-5-thumb.png" },
            { url: "/gamebanners/cyberpunk/banners-cyberpunk-4.png", thumb: "/gamebanners/cyberpunk/banners-cyberpunk-4-thumb.png" },
            { url: "/gamebanners/cyberpunk/banners-cyberpunk-6.png", thumb: "/gamebanners/cyberpunk/banners-cyberpunk-6-thumb.png" },
            { url: "/gamebanners/cyberpunk/banners-cyberpunk-7.png", thumb: "/gamebanners/cyberpunk/banners-cyberpunk-7-thumb.png" },
          ]
        },
        {
          section: 'Enchanted Forest', images: [
            { url: "/gamebanners/enchanted forest/banners-enchanted forest-8.png", thumb: "/gamebanners/enchanted forest/banners-enchanted forest-8-thumb.png" },
            { url: "/gamebanners/enchanted forest/banners-enchanted forest-9.png", thumb: "/gamebanners/enchanted forest/banners-enchanted forest-9-thumb.png" },
            { url: "/gamebanners/enchanted forest/banners-enchanted forest-10.png", thumb: "/gamebanners/enchanted forest/banners-enchanted forest-10-thumb.png" },
            { url: "/gamebanners/enchanted forest/banners-enchanted forest-2.png", thumb: "/gamebanners/enchanted forest/banners-enchanted forest-2-thumb.png" },
            { url: "/gamebanners/enchanted forest/banners-enchanted forest-3.png", thumb: "/gamebanners/enchanted forest/banners-enchanted forest-3-thumb.png" },
            { url: "/gamebanners/enchanted forest/banners-enchanted forest-1.png", thumb: "/gamebanners/enchanted forest/banners-enchanted forest-1-thumb.png" },
            { url: "/gamebanners/enchanted forest/banners-enchanted forest-4.png", thumb: "/gamebanners/enchanted forest/banners-enchanted forest-4-thumb.png" },
            { url: "/gamebanners/enchanted forest/banners-enchanted forest-5.png", thumb: "/gamebanners/enchanted forest/banners-enchanted forest-5-thumb.png" },
            { url: "/gamebanners/enchanted forest/banners-enchanted forest-7.png", thumb: "/gamebanners/enchanted forest/banners-enchanted forest-7-thumb.png" },
            { url: "/gamebanners/enchanted forest/banners-enchanted forest-6.png", thumb: "/gamebanners/enchanted forest/banners-enchanted forest-6-thumb.png" },
          ]
        },
        {
          section: 'Alternate Dimension', images: [
            { url: "/gamebanners/alternate dimension/banners-alternate dimension-7.png", thumb: "/gamebanners/alternate dimension/banners-alternate dimension-7-thumb.png" },
            { url: "/gamebanners/alternate dimension/banners-alternate dimension-6.png", thumb: "/gamebanners/alternate dimension/banners-alternate dimension-6-thumb.png" },
            { url: "/gamebanners/alternate dimension/banners-alternate dimension-4.png", thumb: "/gamebanners/alternate dimension/banners-alternate dimension-4-thumb.png" },
            { url: "/gamebanners/alternate dimension/banners-alternate dimension-5.png", thumb: "/gamebanners/alternate dimension/banners-alternate dimension-5-thumb.png" },
            { url: "/gamebanners/alternate dimension/banners-alternate dimension-1.png", thumb: "/gamebanners/alternate dimension/banners-alternate dimension-1-thumb.png" },
            { url: "/gamebanners/alternate dimension/banners-alternate dimension-2.png", thumb: "/gamebanners/alternate dimension/banners-alternate dimension-2-thumb.png" },
            { url: "/gamebanners/alternate dimension/banners-alternate dimension-3.png", thumb: "/gamebanners/alternate dimension/banners-alternate dimension-3-thumb.png" },
            { url: "/gamebanners/alternate dimension/banners-alternate dimension-8.png", thumb: "/gamebanners/alternate dimension/banners-alternate dimension-8-thumb.png" },
            { url: "/gamebanners/alternate dimension/banners-alternate dimension-9.png", thumb: "/gamebanners/alternate dimension/banners-alternate dimension-9-thumb.png" },
            { url: "/gamebanners/alternate dimension/banners-alternate dimension-10.png", thumb: "/gamebanners/alternate dimension/banners-alternate dimension-10-thumb.png" },
            { url: "/gamebanners/alternate dimension/banners-alternate dimension-11.png", thumb: "/gamebanners/alternate dimension/banners-alternate dimension-11-thumb.png" },
            { url: "/gamebanners/alternate dimension/banners-alternate dimension-13.png", thumb: "/gamebanners/alternate dimension/banners-alternate dimension-13-thumb.png" },
            { url: "/gamebanners/alternate dimension/banners-alternate dimension-12.png", thumb: "/gamebanners/alternate dimension/banners-alternate dimension-12-thumb.png" },
          ]
        },
        {
          section: 'Gothic', images: [
            { url: "/gamebanners/gothic/banners-gothic-4.png", thumb: "/gamebanners/gothic/banners-gothic-4-thumb.png" },
            { url: "/gamebanners/gothic/banners-gothic-5.png", thumb: "/gamebanners/gothic/banners-gothic-5-thumb.png" },
            { url: "/gamebanners/gothic/banners-gothic-6.png", thumb: "/gamebanners/gothic/banners-gothic-6-thumb.png" },
            { url: "/gamebanners/gothic/banners-gothic-2.png", thumb: "/gamebanners/gothic/banners-gothic-2-thumb.png" },
            { url: "/gamebanners/gothic/banners-gothic-3.png", thumb: "/gamebanners/gothic/banners-gothic-3-thumb.png" },
            { url: "/gamebanners/gothic/banners-gothic-1.png", thumb: "/gamebanners/gothic/banners-gothic-1-thumb.png" },
          ]
        },
        {
          section: 'Dark Fortress', images: [
            { url: "/gamebanners/dark fortress/banners-dark fortress-3.png", thumb: "/gamebanners/dark fortress/banners-dark fortress-3-thumb.png" },
            { url: "/gamebanners/dark fortress/banners-dark fortress-2.png", thumb: "/gamebanners/dark fortress/banners-dark fortress-2-thumb.png" },
            { url: "/gamebanners/dark fortress/banners-dark fortress-1.png", thumb: "/gamebanners/dark fortress/banners-dark fortress-1-thumb.png" },
            { url: "/gamebanners/dark fortress/banners-dark fortress-5.png", thumb: "/gamebanners/dark fortress/banners-dark fortress-5-thumb.png" },
            { url: "/gamebanners/dark fortress/banners-dark fortress-4.png", thumb: "/gamebanners/dark fortress/banners-dark fortress-4-thumb.png" },
            { url: "/gamebanners/dark fortress/banners-dark fortress-6.png", thumb: "/gamebanners/dark fortress/banners-dark fortress-6-thumb.png" },
            { url: "/gamebanners/dark fortress/banners-dark fortress-7.png", thumb: "/gamebanners/dark fortress/banners-dark fortress-7-thumb.png" },
          ]
        },
        {
          section: 'Celestial', images: [
            { url: "/gamebanners/celestial/banners-celestial-8.png", thumb: "/gamebanners/celestial/banners-celestial-8-thumb.png" },
            { url: "/gamebanners/celestial/banners-celestial-5.png", thumb: "/gamebanners/celestial/banners-celestial-5-thumb.png" },
            { url: "/gamebanners/celestial/banners-celestial-4.png", thumb: "/gamebanners/celestial/banners-celestial-4-thumb.png" },
            { url: "/gamebanners/celestial/banners-celestial-6.png", thumb: "/gamebanners/celestial/banners-celestial-6-thumb.png" },
            { url: "/gamebanners/celestial/banners-celestial-7.png", thumb: "/gamebanners/celestial/banners-celestial-7-thumb.png" },
            { url: "/gamebanners/celestial/banners-celestial-3.png", thumb: "/gamebanners/celestial/banners-celestial-3-thumb.png" },
            { url: "/gamebanners/celestial/banners-celestial-2.png", thumb: "/gamebanners/celestial/banners-celestial-2-thumb.png" },
            { url: "/gamebanners/celestial/banners-celestial-1.png", thumb: "/gamebanners/celestial/banners-celestial-1-thumb.png" },
          ]
        },
        {
          section: 'Hellscape', images: [
            { url: "/gamebanners/hellscape/banners-hellscape-4.png", thumb: "/gamebanners/hellscape/banners-hellscape-4-thumb.png" },
            { url: "/gamebanners/hellscape/banners-hellscape-3.png", thumb: "/gamebanners/hellscape/banners-hellscape-3-thumb.png" },
            { url: "/gamebanners/hellscape/banners-hellscape-2.png", thumb: "/gamebanners/hellscape/banners-hellscape-2-thumb.png" },
            { url: "/gamebanners/hellscape/banners-hellscape-1.png", thumb: "/gamebanners/hellscape/banners-hellscape-1-thumb.png" },
          ]
        },
        {
          section: 'TTRPG', images: [
            { url: "/gamebanners/ttrpg/TTRPG-D&D and Pathfinder-1.png", thumb: "/gamebanners/ttrpg/TTRPG-D&D and Pathfinder-1-thumb.png" },
            { url: "/gamebanners/ttrpg/TTRPG-D&D and Pathfinder-10.png", thumb: "/gamebanners/ttrpg/TTRPG-D&D and Pathfinder-10-thumb.png" },
            { url: "/gamebanners/ttrpg/TTRPG-D&D and Pathfinder-11.png", thumb: "/gamebanners/ttrpg/TTRPG-D&D and Pathfinder-11-thumb.png" },
            { url: "/gamebanners/ttrpg/TTRPG-D&D and Pathfinder-12.png", thumb: "/gamebanners/ttrpg/TTRPG-D&D and Pathfinder-12-thumb.png" },
            { url: "/gamebanners/ttrpg/TTRPG-D&D and Pathfinder-13.png", thumb: "/gamebanners/ttrpg/TTRPG-D&D and Pathfinder-13-thumb.png" },
            { url: "/gamebanners/ttrpg/TTRPG-D&D and Pathfinder-14.png", thumb: "/gamebanners/ttrpg/TTRPG-D&D and Pathfinder-14-thumb.png" },
            { url: "/gamebanners/ttrpg/TTRPG-D&D and Pathfinder-15.png", thumb: "/gamebanners/ttrpg/TTRPG-D&D and Pathfinder-15-thumb.png" },
            { url: "/gamebanners/ttrpg/TTRPG-D&D and Pathfinder-16.png", thumb: "/gamebanners/ttrpg/TTRPG-D&D and Pathfinder-16-thumb.png" },
            { url: "/gamebanners/ttrpg/TTRPG-D&D and Pathfinder-17.png", thumb: "/gamebanners/ttrpg/TTRPG-D&D and Pathfinder-17-thumb.png" },
            { url: "/gamebanners/ttrpg/TTRPG-D&D and Pathfinder-18.png", thumb: "/gamebanners/ttrpg/TTRPG-D&D and Pathfinder-18-thumb.png" },
            { url: "/gamebanners/ttrpg/TTRPG-D&D and Pathfinder-19.png", thumb: "/gamebanners/ttrpg/TTRPG-D&D and Pathfinder-19-thumb.png" },
            { url: "/gamebanners/ttrpg/TTRPG-D&D and Pathfinder-2.png", thumb: "/gamebanners/ttrpg/TTRPG-D&D and Pathfinder-2-thumb.png" },
            { url: "/gamebanners/ttrpg/TTRPG-D&D and Pathfinder-3.png", thumb: "/gamebanners/ttrpg/TTRPG-D&D and Pathfinder-3-thumb.png" },
            { url: "/gamebanners/ttrpg/TTRPG-D&D and Pathfinder-4.png", thumb: "/gamebanners/ttrpg/TTRPG-D&D and Pathfinder-4-thumb.png" },
            { url: "/gamebanners/ttrpg/TTRPG-D&D and Pathfinder-5.png", thumb: "/gamebanners/ttrpg/TTRPG-D&D and Pathfinder-5-thumb.png" },
            { url: "/gamebanners/ttrpg/TTRPG-D&D and Pathfinder-6.png", thumb: "/gamebanners/ttrpg/TTRPG-D&D and Pathfinder-6-thumb.png" },
            { url: "/gamebanners/ttrpg/TTRPG-D&D and Pathfinder-7.png", thumb: "/gamebanners/ttrpg/TTRPG-D&D and Pathfinder-7-thumb.png" },
            { url: "/gamebanners/ttrpg/TTRPG-D&D and Pathfinder-8.png", thumb: "/gamebanners/ttrpg/TTRPG-D&D and Pathfinder-8-thumb.png" },
            { url: "/gamebanners/ttrpg/TTRPG-D&D and Pathfinder-9.png", thumb: "/gamebanners/ttrpg/TTRPG-D&D and Pathfinder-9-thumb.png" },
            { url: "/gamebanners/ttrpg/TTRPG-Honey Heist-1.png", thumb: "/gamebanners/ttrpg/TTRPG-Honey Heist-1-thumb.png" },
            { url: "/gamebanners/ttrpg/TTRPG-Honey Heist-10.png", thumb: "/gamebanners/ttrpg/TTRPG-Honey Heist-10-thumb.png" },
            { url: "/gamebanners/ttrpg/TTRPG-Honey Heist-11.png", thumb: "/gamebanners/ttrpg/TTRPG-Honey Heist-11-thumb.png" },
            { url: "/gamebanners/ttrpg/TTRPG-Honey Heist-12.png", thumb: "/gamebanners/ttrpg/TTRPG-Honey Heist-12-thumb.png" },
            { url: "/gamebanners/ttrpg/TTRPG-Honey Heist-13.png", thumb: "/gamebanners/ttrpg/TTRPG-Honey Heist-13-thumb.png" },
            { url: "/gamebanners/ttrpg/TTRPG-Honey Heist-14.png", thumb: "/gamebanners/ttrpg/TTRPG-Honey Heist-14-thumb.png" },
            { url: "/gamebanners/ttrpg/TTRPG-Honey Heist-15.png", thumb: "/gamebanners/ttrpg/TTRPG-Honey Heist-15-thumb.png" },
            { url: "/gamebanners/ttrpg/TTRPG-Honey Heist-16.png", thumb: "/gamebanners/ttrpg/TTRPG-Honey Heist-16-thumb.png" },
            { url: "/gamebanners/ttrpg/TTRPG-Honey Heist-17.png", thumb: "/gamebanners/ttrpg/TTRPG-Honey Heist-17-thumb.png" },
            { url: "/gamebanners/ttrpg/TTRPG-Honey Heist-18.png", thumb: "/gamebanners/ttrpg/TTRPG-Honey Heist-18-thumb.png" },
            { url: "/gamebanners/ttrpg/TTRPG-Honey Heist-19.png", thumb: "/gamebanners/ttrpg/TTRPG-Honey Heist-19-thumb.png" },
            { url: "/gamebanners/ttrpg/TTRPG-Honey Heist-2.png", thumb: "/gamebanners/ttrpg/TTRPG-Honey Heist-2-thumb.png" },
            { url: "/gamebanners/ttrpg/TTRPG-Honey Heist-3.png", thumb: "/gamebanners/ttrpg/TTRPG-Honey Heist-3-thumb.png" },
            { url: "/gamebanners/ttrpg/TTRPG-Honey Heist-4.png", thumb: "/gamebanners/ttrpg/TTRPG-Honey Heist-4-thumb.png" },
            { url: "/gamebanners/ttrpg/TTRPG-Honey Heist-5.png", thumb: "/gamebanners/ttrpg/TTRPG-Honey Heist-5-thumb.png" },
            { url: "/gamebanners/ttrpg/TTRPG-Honey Heist-6.png", thumb: "/gamebanners/ttrpg/TTRPG-Honey Heist-6-thumb.png" },
            { url: "/gamebanners/ttrpg/TTRPG-Honey Heist-7.png", thumb: "/gamebanners/ttrpg/TTRPG-Honey Heist-7-thumb.png" },
            { url: "/gamebanners/ttrpg/TTRPG-Honey Heist-8.png", thumb: "/gamebanners/ttrpg/TTRPG-Honey Heist-8-thumb.png" },
            { url: "/gamebanners/ttrpg/TTRPG-Honey Heist-9.png", thumb: "/gamebanners/ttrpg/TTRPG-Honey Heist-9-thumb.png" },
            { url: "/gamebanners/ttrpg/TTRPG-Kids Riding Bikes-1.png", thumb: "/gamebanners/ttrpg/TTRPG-Kids Riding Bikes-1-thumb.png" },
            { url: "/gamebanners/ttrpg/TTRPG-Kids Riding Bikes-10.png", thumb: "/gamebanners/ttrpg/TTRPG-Kids Riding Bikes-10-thumb.png" },
            { url: "/gamebanners/ttrpg/TTRPG-Kids Riding Bikes-11.png", thumb: "/gamebanners/ttrpg/TTRPG-Kids Riding Bikes-11-thumb.png" },
            { url: "/gamebanners/ttrpg/TTRPG-Kids Riding Bikes-12.png", thumb: "/gamebanners/ttrpg/TTRPG-Kids Riding Bikes-12-thumb.png" },
            { url: "/gamebanners/ttrpg/TTRPG-Kids Riding Bikes-13.png", thumb: "/gamebanners/ttrpg/TTRPG-Kids Riding Bikes-13-thumb.png" },
            { url: "/gamebanners/ttrpg/TTRPG-Kids Riding Bikes-14.png", thumb: "/gamebanners/ttrpg/TTRPG-Kids Riding Bikes-14-thumb.png" },
            { url: "/gamebanners/ttrpg/TTRPG-Kids Riding Bikes-15.png", thumb: "/gamebanners/ttrpg/TTRPG-Kids Riding Bikes-15-thumb.png" },
            { url: "/gamebanners/ttrpg/TTRPG-Kids Riding Bikes-16.png", thumb: "/gamebanners/ttrpg/TTRPG-Kids Riding Bikes-16-thumb.png" },
            { url: "/gamebanners/ttrpg/TTRPG-Kids Riding Bikes-17.png", thumb: "/gamebanners/ttrpg/TTRPG-Kids Riding Bikes-17-thumb.png" },
            { url: "/gamebanners/ttrpg/TTRPG-Kids Riding Bikes-18.png", thumb: "/gamebanners/ttrpg/TTRPG-Kids Riding Bikes-18-thumb.png" },
            { url: "/gamebanners/ttrpg/TTRPG-Kids Riding Bikes-19.png", thumb: "/gamebanners/ttrpg/TTRPG-Kids Riding Bikes-19-thumb.png" },
            { url: "/gamebanners/ttrpg/TTRPG-Kids Riding Bikes-2.png", thumb: "/gamebanners/ttrpg/TTRPG-Kids Riding Bikes-2-thumb.png" },
            { url: "/gamebanners/ttrpg/TTRPG-Kids Riding Bikes-3.png", thumb: "/gamebanners/ttrpg/TTRPG-Kids Riding Bikes-3-thumb.png" },
            { url: "/gamebanners/ttrpg/TTRPG-Kids Riding Bikes-4.png", thumb: "/gamebanners/ttrpg/TTRPG-Kids Riding Bikes-4-thumb.png" },
            { url: "/gamebanners/ttrpg/TTRPG-Kids Riding Bikes-5.png", thumb: "/gamebanners/ttrpg/TTRPG-Kids Riding Bikes-5-thumb.png" },
            { url: "/gamebanners/ttrpg/TTRPG-Kids Riding Bikes-6.png", thumb: "/gamebanners/ttrpg/TTRPG-Kids Riding Bikes-6-thumb.png" },
            { url: "/gamebanners/ttrpg/TTRPG-Kids Riding Bikes-7.png", thumb: "/gamebanners/ttrpg/TTRPG-Kids Riding Bikes-7-thumb.png" },
            { url: "/gamebanners/ttrpg/TTRPG-Kids Riding Bikes-8.png", thumb: "/gamebanners/ttrpg/TTRPG-Kids Riding Bikes-8-thumb.png" },
            { url: "/gamebanners/ttrpg/TTRPG-Kids Riding Bikes-9.png", thumb: "/gamebanners/ttrpg/TTRPG-Kids Riding Bikes-9-thumb.png" },
            { url: "/gamebanners/ttrpg/TTRPG-Lancer-1.png", thumb: "/gamebanners/ttrpg/TTRPG-Lancer-1-thumb.png" },
            { url: "/gamebanners/ttrpg/TTRPG-Lancer-10.png", thumb: "/gamebanners/ttrpg/TTRPG-Lancer-10-thumb.png" },
            { url: "/gamebanners/ttrpg/TTRPG-Lancer-11.png", thumb: "/gamebanners/ttrpg/TTRPG-Lancer-11-thumb.png" },
            { url: "/gamebanners/ttrpg/TTRPG-Lancer-12.png", thumb: "/gamebanners/ttrpg/TTRPG-Lancer-12-thumb.png" },
            { url: "/gamebanners/ttrpg/TTRPG-Lancer-13.png", thumb: "/gamebanners/ttrpg/TTRPG-Lancer-13-thumb.png" },
            { url: "/gamebanners/ttrpg/TTRPG-Lancer-14.png", thumb: "/gamebanners/ttrpg/TTRPG-Lancer-14-thumb.png" },
            { url: "/gamebanners/ttrpg/TTRPG-Lancer-15.png", thumb: "/gamebanners/ttrpg/TTRPG-Lancer-15-thumb.png" },
            { url: "/gamebanners/ttrpg/TTRPG-Lancer-16.png", thumb: "/gamebanners/ttrpg/TTRPG-Lancer-16-thumb.png" },
            { url: "/gamebanners/ttrpg/TTRPG-Lancer-17.png", thumb: "/gamebanners/ttrpg/TTRPG-Lancer-17-thumb.png" },
            { url: "/gamebanners/ttrpg/TTRPG-Lancer-18.png", thumb: "/gamebanners/ttrpg/TTRPG-Lancer-18-thumb.png" },
            { url: "/gamebanners/ttrpg/TTRPG-Lancer-19.png", thumb: "/gamebanners/ttrpg/TTRPG-Lancer-19-thumb.png" },
            { url: "/gamebanners/ttrpg/TTRPG-Lancer-2.png", thumb: "/gamebanners/ttrpg/TTRPG-Lancer-2-thumb.png" },
            { url: "/gamebanners/ttrpg/TTRPG-Lancer-3.png", thumb: "/gamebanners/ttrpg/TTRPG-Lancer-3-thumb.png" },
            { url: "/gamebanners/ttrpg/TTRPG-Lancer-4.png", thumb: "/gamebanners/ttrpg/TTRPG-Lancer-4-thumb.png" },
            { url: "/gamebanners/ttrpg/TTRPG-Lancer-5.png", thumb: "/gamebanners/ttrpg/TTRPG-Lancer-5-thumb.png" },
            { url: "/gamebanners/ttrpg/TTRPG-Lancer-6.png", thumb: "/gamebanners/ttrpg/TTRPG-Lancer-6-thumb.png" },
            { url: "/gamebanners/ttrpg/TTRPG-Lancer-7.png", thumb: "/gamebanners/ttrpg/TTRPG-Lancer-7-thumb.png" },
            { url: "/gamebanners/ttrpg/TTRPG-Lancer-8.png", thumb: "/gamebanners/ttrpg/TTRPG-Lancer-8-thumb.png" },
            { url: "/gamebanners/ttrpg/TTRPG-Lancer-9.png", thumb: "/gamebanners/ttrpg/TTRPG-Lancer-9-thumb.png" },
            { url: "/gamebanners/ttrpg/TTRPG-Vampire The Masquerade-1.png", thumb: "/gamebanners/ttrpg/TTRPG-Vampire The Masquerade-1-thumb.png" },
            { url: "/gamebanners/ttrpg/TTRPG-Vampire The Masquerade-10.png", thumb: "/gamebanners/ttrpg/TTRPG-Vampire The Masquerade-10-thumb.png" },
            { url: "/gamebanners/ttrpg/TTRPG-Vampire The Masquerade-11.png", thumb: "/gamebanners/ttrpg/TTRPG-Vampire The Masquerade-11-thumb.png" },
            { url: "/gamebanners/ttrpg/TTRPG-Vampire The Masquerade-12.png", thumb: "/gamebanners/ttrpg/TTRPG-Vampire The Masquerade-12-thumb.png" },
            { url: "/gamebanners/ttrpg/TTRPG-Vampire The Masquerade-13.png", thumb: "/gamebanners/ttrpg/TTRPG-Vampire The Masquerade-13-thumb.png" },
            { url: "/gamebanners/ttrpg/TTRPG-Vampire The Masquerade-14.png", thumb: "/gamebanners/ttrpg/TTRPG-Vampire The Masquerade-14-thumb.png" },
            { url: "/gamebanners/ttrpg/TTRPG-Vampire The Masquerade-15.png", thumb: "/gamebanners/ttrpg/TTRPG-Vampire The Masquerade-15-thumb.png" },
            { url: "/gamebanners/ttrpg/TTRPG-Vampire The Masquerade-16.png", thumb: "/gamebanners/ttrpg/TTRPG-Vampire The Masquerade-16-thumb.png" },
            { url: "/gamebanners/ttrpg/TTRPG-Vampire The Masquerade-17.png", thumb: "/gamebanners/ttrpg/TTRPG-Vampire The Masquerade-17-thumb.png" },
            { url: "/gamebanners/ttrpg/TTRPG-Vampire The Masquerade-18.png", thumb: "/gamebanners/ttrpg/TTRPG-Vampire The Masquerade-18-thumb.png" },
            { url: "/gamebanners/ttrpg/TTRPG-Vampire The Masquerade-2.png", thumb: "/gamebanners/ttrpg/TTRPG-Vampire The Masquerade-2-thumb.png" },
            { url: "/gamebanners/ttrpg/TTRPG-Vampire The Masquerade-3.png", thumb: "/gamebanners/ttrpg/TTRPG-Vampire The Masquerade-3-thumb.png" },
            { url: "/gamebanners/ttrpg/TTRPG-Vampire The Masquerade-4.png", thumb: "/gamebanners/ttrpg/TTRPG-Vampire The Masquerade-4-thumb.png" },
            { url: "/gamebanners/ttrpg/TTRPG-Vampire The Masquerade-5.png", thumb: "/gamebanners/ttrpg/TTRPG-Vampire The Masquerade-5-thumb.png" },
            { url: "/gamebanners/ttrpg/TTRPG-Vampire The Masquerade-6.png", thumb: "/gamebanners/ttrpg/TTRPG-Vampire The Masquerade-6-thumb.png" },
            { url: "/gamebanners/ttrpg/TTRPG-Vampire The Masquerade-7.png", thumb: "/gamebanners/ttrpg/TTRPG-Vampire The Masquerade-7-thumb.png" },
            { url: "/gamebanners/ttrpg/TTRPG-Vampire The Masquerade-8.png", thumb: "/gamebanners/ttrpg/TTRPG-Vampire The Masquerade-8-thumb.png" },
            { url: "/gamebanners/ttrpg/TTRPG-Vampire The Masquerade-9.png", thumb: "/gamebanners/ttrpg/TTRPG-Vampire The Masquerade-9-thumb.png" },
            { url: "/gamebanners/ttrpg/TTRPG-Warhammer-1.png", thumb: "/gamebanners/ttrpg/TTRPG-Warhammer-1-thumb.png" },
            { url: "/gamebanners/ttrpg/TTRPG-Warhammer-10.png", thumb: "/gamebanners/ttrpg/TTRPG-Warhammer-10-thumb.png" },
            { url: "/gamebanners/ttrpg/TTRPG-Warhammer-11.png", thumb: "/gamebanners/ttrpg/TTRPG-Warhammer-11-thumb.png" },
            { url: "/gamebanners/ttrpg/TTRPG-Warhammer-12.png", thumb: "/gamebanners/ttrpg/TTRPG-Warhammer-12-thumb.png" },
            { url: "/gamebanners/ttrpg/TTRPG-Warhammer-13.png", thumb: "/gamebanners/ttrpg/TTRPG-Warhammer-13-thumb.png" },
            { url: "/gamebanners/ttrpg/TTRPG-Warhammer-14.png", thumb: "/gamebanners/ttrpg/TTRPG-Warhammer-14-thumb.png" },
            { url: "/gamebanners/ttrpg/TTRPG-Warhammer-15.png", thumb: "/gamebanners/ttrpg/TTRPG-Warhammer-15-thumb.png" },
            { url: "/gamebanners/ttrpg/TTRPG-Warhammer-16.png", thumb: "/gamebanners/ttrpg/TTRPG-Warhammer-16-thumb.png" },
            { url: "/gamebanners/ttrpg/TTRPG-Warhammer-17.png", thumb: "/gamebanners/ttrpg/TTRPG-Warhammer-17-thumb.png" },
            { url: "/gamebanners/ttrpg/TTRPG-Warhammer-18.png", thumb: "/gamebanners/ttrpg/TTRPG-Warhammer-18-thumb.png" },
            { url: "/gamebanners/ttrpg/TTRPG-Warhammer-19.png", thumb: "/gamebanners/ttrpg/TTRPG-Warhammer-19-thumb.png" },
            { url: "/gamebanners/ttrpg/TTRPG-Warhammer-2.png", thumb: "/gamebanners/ttrpg/TTRPG-Warhammer-2-thumb.png" },
            { url: "/gamebanners/ttrpg/TTRPG-Warhammer-3.png", thumb: "/gamebanners/ttrpg/TTRPG-Warhammer-3-thumb.png" },
            { url: "/gamebanners/ttrpg/TTRPG-Warhammer-4.png", thumb: "/gamebanners/ttrpg/TTRPG-Warhammer-4-thumb.png" },
            { url: "/gamebanners/ttrpg/TTRPG-Warhammer-5.png", thumb: "/gamebanners/ttrpg/TTRPG-Warhammer-5-thumb.png" },
            { url: "/gamebanners/ttrpg/TTRPG-Warhammer-50.png", thumb: "/gamebanners/ttrpg/TTRPG-Warhammer-50-thumb.png" },
            { url: "/gamebanners/ttrpg/TTRPG-Warhammer-6.png", thumb: "/gamebanners/ttrpg/TTRPG-Warhammer-6-thumb.png" },
            { url: "/gamebanners/ttrpg/TTRPG-Warhammer-7.png", thumb: "/gamebanners/ttrpg/TTRPG-Warhammer-7-thumb.png" },
            { url: "/gamebanners/ttrpg/TTRPG-Warhammer-8.png", thumb: "/gamebanners/ttrpg/TTRPG-Warhammer-8-thumb.png" },
            { url: "/gamebanners/ttrpg/TTRPG-Warhammer-9.png", thumb: "/gamebanners/ttrpg/TTRPG-Warhammer-9-thumb.png" },
            { url: "/gamebanners/ttrpg/TTRPG-Werewolf The Apocalypse-1.png", thumb: "/gamebanners/ttrpg/TTRPG-Werewolf The Apocalypse-1-thumb.png" },
            { url: "/gamebanners/ttrpg/TTRPG-Werewolf The Apocalypse-10.png", thumb: "/gamebanners/ttrpg/TTRPG-Werewolf The Apocalypse-10-thumb.png" },
            { url: "/gamebanners/ttrpg/TTRPG-Werewolf The Apocalypse-11.png", thumb: "/gamebanners/ttrpg/TTRPG-Werewolf The Apocalypse-11-thumb.png" },
            { url: "/gamebanners/ttrpg/TTRPG-Werewolf The Apocalypse-12.png", thumb: "/gamebanners/ttrpg/TTRPG-Werewolf The Apocalypse-12-thumb.png" },
            { url: "/gamebanners/ttrpg/TTRPG-Werewolf The Apocalypse-13.png", thumb: "/gamebanners/ttrpg/TTRPG-Werewolf The Apocalypse-13-thumb.png" },
            { url: "/gamebanners/ttrpg/TTRPG-Werewolf The Apocalypse-14.png", thumb: "/gamebanners/ttrpg/TTRPG-Werewolf The Apocalypse-14-thumb.png" },
            { url: "/gamebanners/ttrpg/TTRPG-Werewolf The Apocalypse-15.png", thumb: "/gamebanners/ttrpg/TTRPG-Werewolf The Apocalypse-15-thumb.png" },
            { url: "/gamebanners/ttrpg/TTRPG-Werewolf The Apocalypse-16.png", thumb: "/gamebanners/ttrpg/TTRPG-Werewolf The Apocalypse-16-thumb.png" },
            { url: "/gamebanners/ttrpg/TTRPG-Werewolf The Apocalypse-17.png", thumb: "/gamebanners/ttrpg/TTRPG-Werewolf The Apocalypse-17-thumb.png" },
            { url: "/gamebanners/ttrpg/TTRPG-Werewolf The Apocalypse-18.png", thumb: "/gamebanners/ttrpg/TTRPG-Werewolf The Apocalypse-18-thumb.png" },
            { url: "/gamebanners/ttrpg/TTRPG-Werewolf The Apocalypse-19.png", thumb: "/gamebanners/ttrpg/TTRPG-Werewolf The Apocalypse-19-thumb.png" },
            { url: "/gamebanners/ttrpg/TTRPG-Werewolf The Apocalypse-2.png", thumb: "/gamebanners/ttrpg/TTRPG-Werewolf The Apocalypse-2-thumb.png" },
            { url: "/gamebanners/ttrpg/TTRPG-Werewolf The Apocalypse-3.png", thumb: "/gamebanners/ttrpg/TTRPG-Werewolf The Apocalypse-3-thumb.png" },
            { url: "/gamebanners/ttrpg/TTRPG-Werewolf The Apocalypse-4.png", thumb: "/gamebanners/ttrpg/TTRPG-Werewolf The Apocalypse-4-thumb.png" },
            { url: "/gamebanners/ttrpg/TTRPG-Werewolf The Apocalypse-5.png", thumb: "/gamebanners/ttrpg/TTRPG-Werewolf The Apocalypse-5-thumb.png" },
            { url: "/gamebanners/ttrpg/TTRPG-Werewolf The Apocalypse-6.png", thumb: "/gamebanners/ttrpg/TTRPG-Werewolf The Apocalypse-6-thumb.png" },
            { url: "/gamebanners/ttrpg/TTRPG-Werewolf The Apocalypse-7.png", thumb: "/gamebanners/ttrpg/TTRPG-Werewolf The Apocalypse-7-thumb.png" },
            { url: "/gamebanners/ttrpg/TTRPG-Werewolf The Apocalypse-8.png", thumb: "/gamebanners/ttrpg/TTRPG-Werewolf The Apocalypse-8-thumb.png" },
            { url: "/gamebanners/ttrpg/TTRPG-Werewolf The Apocalypse-9.png", thumb: "/gamebanners/ttrpg/TTRPG-Werewolf The Apocalypse-9-thumb.png" },
          ]
        },
        {
          section: 'Minis', images: [
            { url: "/gamebanners/minis/minatures-misc. miniatures games-1.png", thumb: "/gamebanners/minis/minatures-misc. miniatures games-1-thumb.png" },
            { url: "/gamebanners/minis/minatures-misc. miniatures games-10.png", thumb: "/gamebanners/minis/minatures-misc. miniatures games-10-thumb.png" },
            { url: "/gamebanners/minis/minatures-misc. miniatures games-11.png", thumb: "/gamebanners/minis/minatures-misc. miniatures games-11-thumb.png" },
            { url: "/gamebanners/minis/minatures-misc. miniatures games-12.png", thumb: "/gamebanners/minis/minatures-misc. miniatures games-12-thumb.png" },
            { url: "/gamebanners/minis/minatures-misc. miniatures games-13.png", thumb: "/gamebanners/minis/minatures-misc. miniatures games-13-thumb.png" },
            { url: "/gamebanners/minis/minatures-misc. miniatures games-14.png", thumb: "/gamebanners/minis/minatures-misc. miniatures games-14-thumb.png" },
            { url: "/gamebanners/minis/minatures-misc. miniatures games-15.png", thumb: "/gamebanners/minis/minatures-misc. miniatures games-15-thumb.png" },
            { url: "/gamebanners/minis/minatures-misc. miniatures games-16.png", thumb: "/gamebanners/minis/minatures-misc. miniatures games-16-thumb.png" },
            { url: "/gamebanners/minis/minatures-misc. miniatures games-17.png", thumb: "/gamebanners/minis/minatures-misc. miniatures games-17-thumb.png" },
            { url: "/gamebanners/minis/minatures-misc. miniatures games-18.png", thumb: "/gamebanners/minis/minatures-misc. miniatures games-18-thumb.png" },
            { url: "/gamebanners/minis/minatures-misc. miniatures games-19.png", thumb: "/gamebanners/minis/minatures-misc. miniatures games-19-thumb.png" },
            { url: "/gamebanners/minis/minatures-misc. miniatures games-2.png", thumb: "/gamebanners/minis/minatures-misc. miniatures games-2-thumb.png" },
            { url: "/gamebanners/minis/minatures-misc. miniatures games-20.png", thumb: "/gamebanners/minis/minatures-misc. miniatures games-20-thumb.png" },
            { url: "/gamebanners/minis/minatures-misc. miniatures games-21.png", thumb: "/gamebanners/minis/minatures-misc. miniatures games-21-thumb.png" },
            { url: "/gamebanners/minis/minatures-misc. miniatures games-22.png", thumb: "/gamebanners/minis/minatures-misc. miniatures games-22-thumb.png" },
            { url: "/gamebanners/minis/minatures-misc. miniatures games-23.png", thumb: "/gamebanners/minis/minatures-misc. miniatures games-23-thumb.png" },
            { url: "/gamebanners/minis/minatures-misc. miniatures games-24.png", thumb: "/gamebanners/minis/minatures-misc. miniatures games-24-thumb.png" },
            { url: "/gamebanners/minis/minatures-misc. miniatures games-25.png", thumb: "/gamebanners/minis/minatures-misc. miniatures games-25-thumb.png" },
            { url: "/gamebanners/minis/minatures-misc. miniatures games-26.png", thumb: "/gamebanners/minis/minatures-misc. miniatures games-26-thumb.png" },
            { url: "/gamebanners/minis/minatures-misc. miniatures games-27.png", thumb: "/gamebanners/minis/minatures-misc. miniatures games-27-thumb.png" },
            { url: "/gamebanners/minis/minatures-misc. miniatures games-28.png", thumb: "/gamebanners/minis/minatures-misc. miniatures games-28-thumb.png" },
            { url: "/gamebanners/minis/minatures-misc. miniatures games-29.png", thumb: "/gamebanners/minis/minatures-misc. miniatures games-29-thumb.png" },
            { url: "/gamebanners/minis/minatures-misc. miniatures games-3.png", thumb: "/gamebanners/minis/minatures-misc. miniatures games-3-thumb.png" },
            { url: "/gamebanners/minis/minatures-misc. miniatures games-30.png", thumb: "/gamebanners/minis/minatures-misc. miniatures games-30-thumb.png" },
            { url: "/gamebanners/minis/minatures-misc. miniatures games-31.png", thumb: "/gamebanners/minis/minatures-misc. miniatures games-31-thumb.png" },
            { url: "/gamebanners/minis/minatures-misc. miniatures games-32.png", thumb: "/gamebanners/minis/minatures-misc. miniatures games-32-thumb.png" },
            { url: "/gamebanners/minis/minatures-misc. miniatures games-33.png", thumb: "/gamebanners/minis/minatures-misc. miniatures games-33-thumb.png" },
            { url: "/gamebanners/minis/minatures-misc. miniatures games-34.png", thumb: "/gamebanners/minis/minatures-misc. miniatures games-34-thumb.png" },
            { url: "/gamebanners/minis/minatures-misc. miniatures games-35.png", thumb: "/gamebanners/minis/minatures-misc. miniatures games-35-thumb.png" },
            { url: "/gamebanners/minis/minatures-misc. miniatures games-36.png", thumb: "/gamebanners/minis/minatures-misc. miniatures games-36-thumb.png" },
            { url: "/gamebanners/minis/minatures-misc. miniatures games-37.png", thumb: "/gamebanners/minis/minatures-misc. miniatures games-37-thumb.png" },
            { url: "/gamebanners/minis/minatures-misc. miniatures games-38.png", thumb: "/gamebanners/minis/minatures-misc. miniatures games-38-thumb.png" },
            { url: "/gamebanners/minis/minatures-misc. miniatures games-39.png", thumb: "/gamebanners/minis/minatures-misc. miniatures games-39-thumb.png" },
            { url: "/gamebanners/minis/minatures-misc. miniatures games-4.png", thumb: "/gamebanners/minis/minatures-misc. miniatures games-4-thumb.png" },
            { url: "/gamebanners/minis/minatures-misc. miniatures games-40.png", thumb: "/gamebanners/minis/minatures-misc. miniatures games-40-thumb.png" },
            { url: "/gamebanners/minis/minatures-misc. miniatures games-41.png", thumb: "/gamebanners/minis/minatures-misc. miniatures games-41-thumb.png" },
            { url: "/gamebanners/minis/minatures-misc. miniatures games-42.png", thumb: "/gamebanners/minis/minatures-misc. miniatures games-42-thumb.png" },
            { url: "/gamebanners/minis/minatures-misc. miniatures games-5.png", thumb: "/gamebanners/minis/minatures-misc. miniatures games-5-thumb.png" },
            { url: "/gamebanners/minis/minatures-misc. miniatures games-6.png", thumb: "/gamebanners/minis/minatures-misc. miniatures games-6-thumb.png" },
            { url: "/gamebanners/minis/minatures-misc. miniatures games-7.png", thumb: "/gamebanners/minis/minatures-misc. miniatures games-7-thumb.png" },
            { url: "/gamebanners/minis/minatures-misc. miniatures games-8.png", thumb: "/gamebanners/minis/minatures-misc. miniatures games-8-thumb.png" },
            { url: "/gamebanners/minis/minatures-misc. miniatures games-9.png", thumb: "/gamebanners/minis/minatures-misc. miniatures games-9-thumb.png" },
          ]
        },
      ]
    };
    for (let i = 1; i<63; i++) {
      tmp.banners[0].images.push(
           { url: `/gamebanners/cards/Trading Card Games-misc. Trading Card Games-${i}.png`, thumb: `/gamebanners/cards/Trading Card Games-misc. Trading Card Games-${i}-thumb.png` },
      );
    }
    for (let i = 1; i<42; i++) {
      tmp.banners[1].images.push(
           { url: `/gamebanners/boardgames/board games-misc. board games-${i}.png`, thumb: `/gamebanners/boardgames/board games-misc. board games-${i}-thumb.png` },
      );
    }
    return tmp; },
    { name: 'advnexus-gameList11' }
  ));

export default useGamesState;
