import { create } from 'zustand'


const useModalState = create(
    (set, get) => ({
        tzModal: false,
        opentz: (filters) => set((state) => ({ ...state, tzModal: true })),
        closetz: (filters) => set((state) => ({ ...state, tzModal: false })),
    })
);

export default useModalState;
