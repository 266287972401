import { React, useState} from "react";
import {
  useDisclosure,
  Box,
  Button,
  Checkbox,
  Container,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  Flex,
  FormControl,
  FormLabel,
  Spacer,
  Heading,
  Image,
  Input,
  Text,
  Link,
  VStack,
  Code,
  Grid
} from '@chakra-ui/react';
import { useNavigate, useSearchParams } from "react-router-dom";
import logo from "../assets/logo.png";
import Error from "../components/Error";
import Success from "../components/Success";
import useUserState from "../stores/user";
import api from "../utils/api";

const RegisterPage = (props) => {
  const [ password, setPassword ] = useState('');
  const [ password2, setPassword2 ] = useState('');
  const [ loading, setLoading ] = useState(false);
  const [ errors, setErrors ] = useState([]);
  const [ success, setSuccess ] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();


  const navigate = useNavigate();
  const code = searchParams.get('code');

  const handleReset = () => {
    let tmpErrors = [];
    if (password !== password2) tmpErrors.push("Passwords don't match"); 

    setErrors(tmpErrors);
    if (tmpErrors.length) return;


    setLoading(true);

    api('auth/reset-password', {code, password}).then((res) => {
      if (res.error) setErrors([res.error]);
      else {
        setSuccess(res.data.message);
        navigate("/login",{state:{success: res.data.message}})
      }
    }).catch((e) => {
      setErrors(["There was a problem registering, please try again later."]);
    }).finally(() => setLoading(false));
        
  }

  return <Container variant="md" >
      <VStack w={'100%'} p={8}>
        <Image src={logo} w={103} mb={2}/>
        <Heading>Reset Your Password</Heading>
        {errors.map((err) => <Error>{err}</Error>)}
        {success && <Success>{success}</Success>}
        {!success && <>
          <FormControl isRequired variant="floating" pb={4}>
            <Input onChange={(ev) => setPassword(ev.target.value)} type="password" placeholder=" " />
            <FormLabel>Password</FormLabel>
          </FormControl>
          <FormControl isRequired variant="floating" pb={4}>
            <Input onChange={(ev) => setPassword2(ev.target.value)} type="password" placeholder=" " />
            <FormLabel>Confirm Password</FormLabel>
          </FormControl>
          <Button w="100%" onClick={handleReset} disabled={loading} style={{opacity: loading ? 0.5 : 1}}>Reset Password</Button>
        <Text fontWeight="light">Just want to login? <Link href="/login">Sign In</Link></Text>
        </>}
    </VStack>
  </Container>
}

export default RegisterPage;
