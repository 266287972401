import { extendTheme } from '@chakra-ui/react';
import { menuTheme } from './menu';
import { cardTheme } from './card';
import { tagTheme } from './tag';
import { selectTheme } from './select';
import { tabsTheme } from './tabs';
import { switchTheme } from './switch';





const activeLabelStyles = {
  transform: "scale(0.85) translateY(-22px)"
};

export const theme = extendTheme({
  initialColorMode: 'dark',
  useSystemColorMode: false,
  colors: {
    gray: '#5F5F5F',
    navy: '#151522',
    lightNavy: '#272639',
    orange: '#FF6900',
    orangey:
    {
      50: '#ffeedb',
      100: '#ffd1ae',
      200: '#ffb37e',
      300: '#ff964c',
      400: '#ff781a',
      500: '#e65e00',
      600: '#b44900',
      700: '#813400',
      800: '#4f1d00',
      900: '#200800',
    },
    warning: '#ff6666',
    muted: '#9595A5',
  },
  styles: {
    global: (props) => {
      return {
        body: {
          bg: 'navy',
          color: 'white',
          fontFamily: "'Inter', serif;",
        }
      }
    }
  },
  components: {
    Card: cardTheme,
    Menu: menuTheme,
    Tag: tagTheme,
    Switch: switchTheme,
    Drawer: {
      defaultProps: {
        variant: 'navy'
      },
      variants: {
        navy: {
          dialog: {
            background: 'navy',
          }
        },
        notifications: {
          dialog: {
            background: '#272639',
          }
        },
      },
    },
    Form: {
      variants: {
        floating: {
          container: {
            _focusWithin: {
              label: {
                ...activeLabelStyles
              }
            },
            "input:not(:placeholder-shown) + label, .chakra-react-select--has-value + label, textarea:not(:placeholder-shown) ~ label, .react-datepicker-wrapper + label": {
              ...activeLabelStyles
            },
            label: {
              top: 0,
              left: 0,
              zIndex: 2,
              position: "absolute",
              backgroundColor: "navy",
              pointerEvents: "none",
              mx: 3,
              px: 1,
              my: 2,
              transformOrigin: "left top"
            },
          },
          helperText: {
            top: 0,
            bg: 'muted',
            padding: '1em',
            borderRadius: '10px',
            color: 'navy',
            fontStyle: 'italic',
            fontSize: 'sm',
            opacity: 0.8,
          }
        }
      }
    },
    Select: selectTheme,
    Tabs: tabsTheme,
    Input: {
      variants: {
        muted: {
          field: {
            backgroundColor: 'transparent',
            border: '1px solid #9595A5',
            _placeholder: {
              color: 'muted',
            }
          }
        }
      }
    },
    Link: {
      baseStyle: {
        color: 'orange',
        fontWeight: 'bold',
      }
    },
    Heading: {
      baseStyle: {
        fontFamily: "'Libre Baskerville', serif;",
        marginBottom: 4
      },
      variants: {
        subhead: {
          fontSize: '1.2rem',
          fontWeight: 'bold',
          textAlign: 'left',
          fontFamily: "'Inter', serif;",
          width: '100%'
        }
      }
    },
    Progress: {
      baseStyle: {
        track: {
          border: '1px solid white',
          borderRadius: '10px',
        }
      }
    },
    Text: {
      variants: {
        badge: {
          backgroundColor: 'orange',
          lineHeight: '1.0rem',
          width: '1.2rem',
          padding: '0.1rem',
          color: 'white',
          fontSize: '0.75rem',
          marginBottom: '0.9rem',
          marginLeft: '0.25rem',
          borderRadius: 9999
        }
      }
    },
    Button: {
      baseStyle: {
        // ...define your base styles
        backgroundColor: 'orange',
        color: 'white',
        borderRadius: '8px',
      },
      variants: {
        base: {
          height: '48px',
          padding: '13px 20px',
        },
        secondary: {
          height: '48px',
          padding: '13px 20px',
          color: 'muted',
          backgroundColor: 'transparent',
          borderColor: 'muted',
          borderWidth: '2px',
        },
        actionList: {
          backgroundColor: 'lightNavy',
          color: 'white',
          width: '100%',
          textAlign: 'left',
          padding: '2rem',
          whiteSpace: 'auto',
        },
        wide: {
          height: '48px',
          padding: '13px 20px',
          width: '100%',
          maxWidth: 'md',
        },
        tag: {
          padding: '0.2em 0.6em',
          fontSize: "xs",
          fontWeight: 'normal',
          height: "1.4em",
          borderRadius: '4px',
        },
        navActive: {
          backgroundColor: 'transparent',
          color: 'orange',
        },
        nav: {
          backgroundColor: 'transparent',
          _hover: {
            backgroundColor: 'white',
            color: 'navy',
          }
        },
        mobileNavActive: {
          backgroundColor: 'transparent',
          color: 'orange',
        },
        mobileNav: {
          backgroundColor: 'transparent',
          color: 'muted',
          _hover: {
            backgroundColor: 'white',
            color: 'navy',
          }
        },
        outline: {
          backgroundColor: 'transparent',
          border: '1px solid #9595A5',
        },
        disabled: {
          backgroundColor: 'transparent',
          border: '1px solid #9595A5',
          color: 'muted',
          cursor: 'default'
        }
      },
      defaultProps: {
        variant: 'base'
      }
    },
  },
});
