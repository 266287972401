import {
  Box,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Heading,
  Image,
  IconButton,
  Icon,
  Flex,
  Button,
  HStack,
  Stack,
  VStack,
  Text,
  chakra,
  MenuItem,
  Menu,
  MenuButton,
  MenuList,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import { useAuth0 } from '@auth0/auth0-react';
import logo from '../assets/logo.png';
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import useUserState from "../stores/user";
import {
  HamburgerIcon,
  CloseIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  ChevronRightIcon,
} from '@chakra-ui/icons';
import { useLocation, useParams } from 'react-router-dom';
import { useQuery } from 'react-query'
import api from "../utils/api";
import Avatar from './Avatar';
import dayjs from 'dayjs';
import ReportBug from './ReportBug';
import { FiLogOut, FiUser } from "react-icons/fi";
import { GiDiceTwentyFacesTwenty } from "react-icons/gi";
import { FaRegStar, FaRegMap, FaBell, FaCalendar } from "react-icons/fa";
import { IoCheckmarkDoneSharp } from "react-icons/io5";

const DesktopNavLink = ({ item }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { pathname } = useLocation();
  if (item.subnav) {
    return <Menu isOpen={isOpen} variant={item.url == pathname ? 'navActive' : null}>
      <Link to={item.url}>
        <MenuButton
          fontWeight="bold"
          onMouseEnter={onOpen}
          onMouseLeave={onClose}
        >
          {item.icon ? <Icon as={item.icon} mr={1} style={{ verticalAlign: 'middle' }} /> : null} {item.label}
          {isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
        </MenuButton>
      </Link>

      <MenuList onMouseEnter={onOpen} onMouseLeave={onClose} mt={0}>
        <VStack alignItems="start">
          {item.subnav.map((s, idx) => <Link key={idx} to={s.url} onClick={s.function} w="100%">
            <Button variant={pathname == s.url ? "navActive" : "nav"} w="100%"><Box mt={1} mr={1}>{s.icon ? <Icon as={s.icon} /> : null}</Box>{s.label} </Button>
          </Link>)}
        </VStack>
      </MenuList>
    </Menu>
  } else {
    return <Link to={item.url} onClick={item.function}>
      <Button variant={pathname == item.url ? "navActive" : "nav"}>{item.icon ? <Icon as={item.icon} mr={1} /> : null} {item.label} </Button>
    </Link>
  }
};


export default function Header({ back, backLabel, rightSide }) {
  const { user, logout } = useUserState((state) => state);
  const { logout: auth0logout } = useAuth0();
  const { pathname } = useLocation();
  const notificationsQuery = useQuery('notifications', () => api('user/me/notifications'));
  const notifications = notificationsQuery.data;
  const navigate = useNavigate();

  const userQuery = useQuery(['user', user?.id], () => api('/users/' + user?.id));


  const doLogout = async () => {
    logout();
    auth0logout({ logoutParams: { returnTo: window.location.origin + '/login' } });
    navigate('/login');
  }

  const links = user ? [
    { label: 'Games', url: '/games', icon: GiDiceTwentyFacesTwenty },
    { label: 'Venues', url: '/venues', icon: FaRegMap },
    {
      label: 'My Account', url: '/account', icon: FiUser, subnav: [
        { label: 'My Calendar', url: '/games/Calendar', icon: FaCalendar },
        { label: 'My Favorites', url: '/favorites', icon: FaRegStar },
      ]
    },
    { label: 'Logout', function: doLogout, url: '/login', icon: FiLogOut }
  ] : [];

  const { isOpen, onToggle, onClose } = useDisclosure();
  const { isOpen: notificationsIsOpen, onToggle: notificationsToggle, onClose: notificationsOnClose } = useDisclosure();

  const markRead = async () => {
    if (notifications && notifications.length) {
      await api('user/me/notifications/read', { ids: notifications.map((n) => n.id) }, 'POST');
      notificationsQuery.refetch();
    }
  };

  const deleteNotification = async (id) => {
    await api('user/me/notifications', { id }, 'DELETE');
    notificationsQuery.refetch();
  };


  const btnRef = React.useRef();
  return (
    <chakra.header id="header">
      <Flex
        w="100%"
        px="6"
        py="5"
        align="center"
        justify="space-between"
      >
        <Box style={{ position: "relative" }}>
          <Link to="/"><Image src={logo} h="50px" /></Link>
          <Text style={{ position: "absolute", textAlign: "center", top: "15px", left: "-5px", transform: "rotate(-14deg)", opacity: "0.9" }} w="115%" fontSize="xs" fontWeight="bold" color="navy" bgColor="orange">BETA</Text>
        </Box>

        <VStack as="nav" display={{ base: 'none', lg: 'flex' }} alignItems="end" spacing={0}>
          <HStack>
            {user && <Text color="muted" mr={3}>Well met, {userQuery.data?.username}.</Text>}
            {user && notifications && notifications.filter((n) => !n.read).length > 0 && <Box style={{ display: 'inline-block', backgroundColor: 'red', color: 'white', borderRadius: 9999, padding: '2px 4px 2px 4px' }} ml={2} onClick={notificationsToggle}>
              <Text>
                <Icon as={FaBell} mr={2} />
                {notifications.length}
              </Text>
            </Box>}
            {user && (!notifications || notifications.filter((n) => !n.read).length == 0) && <Text style={{ display: 'inline-block' }} ml={2} onClick={notificationsToggle}><Icon as={FaBell} /></Text>}
          </HStack>
          <HStack >
            {links.map((item, idx) => <DesktopNavLink item={item} key={idx} />)}
          </HStack>
        </VStack>
        <Flex ml={{ base: -2 }} mr={4} display={{ base: 'flex', lg: 'none' }}>
          {user && notifications && notifications.filter((n) => !n.read).length > 0 && <Box style={{ display: 'inline-block', backgroundColor: 'red', color: 'white', borderRadius: 9999, padding: '2px 4px 2px 4px', height: '1.5em', marginTop: '0.5em' }} ml={2} onClick={notificationsToggle}>
            <Text>
              <Icon as={FaBell} mr={2} />
              {notifications.length}
            </Text>
          </Box>}
          {user && (!notifications || notifications.filter((n) => !n.read).length == 0) && <Text ml={2} mt={2} onClick={notificationsToggle}><Icon as={FaBell} /></Text>}
          {user && <IconButton
            onClick={onToggle}
            icon={
              isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />
            }
            variant={'nav'}
            aria-label={'Toggle Navigation'}
          />}
        </Flex>
      </Flex>
      <HStack mx={4} justify={(back && rightSide) ? "space-between" : (back ? "start" : "end")}>
        {back && <Link to={back}>&lt; {backLabel || "Back"}</Link>}
        {rightSide}
      </HStack>

      <Drawer
        variant="navy"
        isOpen={isOpen}
        onClose={onClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>
          </DrawerHeader>

          <DrawerBody>
            <VStack alignItems="center">
              <Avatar size={100} avatar={user?.avatar} />
              <Text fontWeight="bold">{user?.username}</Text>
            </VStack>
            <VStack alignItems="left">
              {links.map((item, i) => {
                if (item.subnav) return <Link key={i} to={item.url} onClick={item.function}>
                  <Button style={{ cursor: 'default', pointerEvents: 'none' }} variant={pathname == item.url ? 'mobileNavActive' : 'mobileNav'}><Box w={8} mt={1}>{item.icon ? <Icon as={item.icon} /> : null}</Box>{item.label} {null && <Icon as={ChevronDownIcon} ml={24} />}</Button>
                  <VStack alignItems="left" ml={8}>
                    {item.subnav.map((s, idx) => <Link key={idx} to={s.url} onClick={s.function}>
                      <Button variant={pathname == s.url ? 'mobileNavActive' : 'mobileNav'}><Box w={8} mt={1} >{s.icon ? <Icon as={s.icon} /> : null}</Box>{s.label} </Button>
                    </Link>)}
                  </VStack>
                </Link>
                else return <Link key={i} to={item.url} onClick={item.function}>
                  <Button variant={pathname == item.url ? 'mobileNavActive' : 'mobileNav'}><Box w={8} mt={1}>{item.icon ? <Icon as={item.icon} /> : null}</Box>{item.label} </Button>
                </Link>
              })}
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>

      <Drawer
        variant="notifications"
        isOpen={notificationsIsOpen}
        onClose={notificationsOnClose}
      >
        <DrawerContent>
          <DrawerHeader>
            <DrawerCloseButton onClick={notificationsOnClose} />
          </DrawerHeader>
          <DrawerBody >
            <VStack alignItems="left">
              <HStack align="end" justify="space-between">
                <Text size="lg">Notifications</Text>
                <Button onClick={markRead} variant="outline"><Icon as={IoCheckmarkDoneSharp} /></Button>
              </HStack>
              {notifications && notifications.map((n) => <Box
                key={n.id}
                p={2}
                border="1px solid"
                borderColor={n.read ? 'muted' : 'orange'}
                borderRadius={4}
                mb={2}
                key={n.id}
                cursor="pointer"
                onClick={() => { if (n.link) navigate(n.link) }}>
                <HStack justify="space-between">
                  <Text color="white" fontWeight={n.read ? 'bold' : 'auto'}>{n.headline}</Text>
                  <Link onClick={() => deleteNotification(n.id)} style={{ fontSize: '12px' }}><Icon color="muted" as={CloseIcon} /></Link>
                </HStack>
                <Text color="muted" >{n.body}</Text>
                <HStack justifyContent="end">
                  <Text color="muted" fontSize="xs" color="muted">{dayjs(n.createdAt).format('dddd, MMMM D, h:mm A')}</Text>
                </HStack>
              </Box>)}
              {(!notifications || notifications.length == 0) && <Text>You have no notifications</Text>}
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      <ReportBug />
    </chakra.header >
  );
}
