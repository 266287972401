import React, { useState } from "react";
import {
  Box,
  Button,
  Container,
  Flex,
  FormControl,
  FormLabel,
  FormHelperText,
  Heading,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Textarea,
} from '@chakra-ui/react';
import { FaFacebook, FaTwitter, FaInstagram, FaInfoCircle } from "react-icons/fa";
import { BsDiscord } from "react-icons/bs";
import { Form, Link } from "react-router-dom";
import dayjs from 'dayjs';
import DatePicker from "react-datepicker";
import Header from '../../components/Header'
import Error from "../../components/Error";
import Success from "../../components/Success";
import useUserState from "../../stores/user";
import api from "../../utils/api";
import { useQuery } from 'react-query';
import "react-datepicker/dist/react-datepicker.css";

const AccountForm = ({ children, user, userQuery, update }) => {
  const [showBioHelp, setShowBioHelp] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [username, setUsername] = useState(user.username);
  const [firstName, setFirstName] = useState(user.firstName);
  const [lastName, setLastName] = useState(user.lastName);
  const [bio, setBio] = useState(user.bio);
  const [pronouns, setPronouns] = useState(user.pronouns);
  const [location, setLocation] = useState(user.location);
  const [birthday, setBirthday] = useState(user.birthday);
  const [facebookLink, setFacebookLink] = useState(user.facebookLink);
  const [instagramLink, setInstagramLink] = useState(user.instagramLink);
  const [twitterLink, setTwitterLink] = useState(user.twitterLink);
  const [discordLink, setDiscordLink] = useState(user.discordLink);

  const save = () => {
    setLoading(true);
    setError('');
    setSuccess('');
    api('user/me', { username, firstName, lastName, facebookLink, instagramLink, twitterLink, discordLink, bio, pronouns, location, birthday }).then((res) => {
      if (res.error) setError(res.error);
      else {
        userQuery.refetch();
        setLoading(false);
        setSuccess('Account updated');
      }
    }).catch((e) => {
      setError("Unable to save, try again later.");
      setLoading(false);
    }).finally(() => setLoading(false));
  };


  return <Box>
    <Header />
    <Box mx={4}><Link to='/account'>&lt; Back to Account</Link></Box>
    <Container variant="md" >
      <Flex direction={'column'} w={'100%'} p={8} alignItems={'center'}>
        <Heading>Edit Public Profile</Heading>
      </Flex>

      {success && <Success>{success}</Success>}
      {error && <Error>{error}</Error>}

      <Heading as='h2' size='md'>Basics</Heading>
      <FormControl variant="floating" >
        <Input onChange={(ev) => setUsername(ev.target.value)} type="text" placeholder=" " value={username} />
        <FormLabel>Username</FormLabel>
      </FormControl>
      <HStack mt={4}>
        <FormControl variant="floating" >
          <Input onChange={(ev) => setFirstName(ev.target.value)} type="text" placeholder=" " value={firstName} />
          <FormLabel>First Name</FormLabel>
        </FormControl>
        <FormControl variant="floating" >
          <Input onChange={(ev) => setLastName(ev.target.value)} type="text" placeholder=" " value={lastName} />
          <FormLabel>Last Name</FormLabel>
        </FormControl>
      </HStack>
      <HStack mt={4}>
        <FormControl variant="floating" >
          <Input onChange={(ev) => setPronouns(ev.target.value)} type="text" placeholder=" " value={pronouns} />
          <FormLabel>Pronouns</FormLabel>
        </FormControl>
        <FormControl variant="floating" >
          <Input onChange={(ev) => setLocation(ev.target.value)} type="text" placeholder=" " value={location} />
          <FormLabel>Location</FormLabel>
        </FormControl>
      </HStack>
      <HStack mt={4}>
        <FormControl variant="floating" >
          <DatePicker
            placeholderText=" "
            name="date-input"
            showIcon
            selected={birthday ? dayjs(birthday).toDate() : null}
            onChange={(date) => setBirthday(date ? dayjs(date).format('YYYY-MM-DD') : null)}
            isClearable={true}
            style={{ width: '100%' }}

          />
          <FormLabel>Birth Day</FormLabel>
        </FormControl>
      </HStack>

      <FormControl variant="floating" mt={4} position="relative">
        <Textarea placeholder=" " value={bio} onChange={(ev) => setBio(ev.target.value)} h={'10em'} onFocus={() => setShowBioHelp(true)} onBlur={() => setShowBioHelp(false)} />
        <FormLabel>Bio</FormLabel>
        {showBioHelp && <FormHelperText>
          <Icon as={FaInfoCircle} style={{ position: 'relative', top: 2 }} /> Include details about tools you use during a game, playstyle, or anything else you want to share.
        </FormHelperText>}
      </FormControl>

      <Heading as='h2' size='md' mt={8}>Social</Heading>
      <FormControl variant="floating" pb={4}>
        <InputGroup>
          <InputLeftElement pointerEvents='none'>
            <Icon as={FaFacebook} color='gray.300' />
          </InputLeftElement>
          <Input onChange={(ev) => setFacebookLink(ev.target.value)} type="text" placeholder=" " value={facebookLink} />
          <FormLabel>Facebook Link</FormLabel>
        </InputGroup>
      </FormControl>
      <FormControl variant="floating" pb={4}>
        <InputGroup>
          <InputLeftElement pointerEvents='none'>
            <Icon as={BsDiscord} color='gray.300' />
          </InputLeftElement>
          <Input onChange={(ev) => setDiscordLink(ev.target.value)} type="text" placeholder=" " value={discordLink} />
          <FormLabel>Discord Link</FormLabel>
        </InputGroup>
      </FormControl>
      <FormControl variant="floating" pb={4}>
        <InputGroup>
          <InputLeftElement pointerEvents='none'>
            <Icon as={FaInstagram} color='gray.300' />
          </InputLeftElement>
          <Input onChange={(ev) => setInstagramLink(ev.target.value)} type="text" placeholder=" " value={instagramLink} />
          <FormLabel>Instagram Link</FormLabel>
        </InputGroup>
      </FormControl>
      <FormControl variant="floating" pb={4}>
        <InputGroup>
          <InputLeftElement pointerEvents='none'>
            <Icon as={FaTwitter} color='gray.300' />
          </InputLeftElement>
          <Input onChange={(ev) => setTwitterLink(ev.target.value)} type="text" placeholder=" " value={twitterLink} />
          <FormLabel>Twitter Link</FormLabel>
        </InputGroup>
      </FormControl>
      <Button w="100%" onClick={save} disabled={loading} style={{ opacity: loading ? 0.3 : 1 }}>Save</Button>
    </Container>


  </Box>
}

const SetupAccountPage = (props) => {
  const { user: stateUser, update } = useUserState((state) => state);
  const userQuery = useQuery(['user', stateUser.id], () => api('/users/' + stateUser.id));
  if (userQuery.isLoading) return <Box>Loading...</Box>
  const user = userQuery.isLoading ? {} : userQuery.data;

  return <AccountForm user={user} userQuery={userQuery} update={update} />
}

export default SetupAccountPage;
