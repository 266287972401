import {
    React,
    useState,
    useEffect,
    createRef
} from "react";
import {
    Box,
    Button,
    CheckboxGroup,
    Checkbox,
    Container,
    Flex,
    FormControl,
    FormLabel,
    FormHelperText,
    FormErrorMessage,
    Heading,
    HStack,
    VStack,
    Icon,
    Input,
    Image,
    Progress,
    Radio,
    RadioGroup,
    Textarea,
    Text,

    Drawer,
    DrawerContent,
    DrawerOverlay,

    Tab,
    Tabs,
    TabList,
    TabPanel,
    TabPanels,

    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,

    useDisclosure,
} from '@chakra-ui/react';
import { Link, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import useGamesState from "../stores/games";
import useUserState from "../stores/user";
import _ from "underscore";
import dayjs from 'dayjs';
import { useQuery } from 'react-query'
import reactSelectStyles from "../theme/reactSelectStyles";
import {
    CreatableSelect as Combo,
    AsyncSelect,
    Select,
    components
} from "chakra-react-select";
import Header from '../components/Header'
import GameDetails from '../components/GameDetails'
import api from '../utils/api'
import { FaMinus, FaPlus, FaInfoCircle } from 'react-icons/fa';
import "react-datepicker/dist/react-datepicker.css";
import GooglePlacesAutocomplete from 'chakra-ui-google-places-autocomplete';
import useFilterState from "../stores/filters";
import { useTimezoneSelect, allTimezones } from 'react-timezone-select'
import ZonePicker from "../components/ZonePicker";
import LocalTime from "../components/LocalTime";
import { create } from "zustand";



const CreateGamePage = ({ mode }) => {
    const { venueId } = useParams();
    const { banners } = useGamesState();
    const systemsQuery = useQuery('systems', () => api('games/systems', false, 'QUERY'))
    const systems = systemsQuery.data ? systemsQuery.data.map((s) => { return { label: s.name, value: s.id } }) : [];
    const venuesQuery = useQuery('venues', () => api('venues'));
    const tags = useQuery('tags', () => api('games/tags', false, 'QUERY')).data;
    const user = useUserState((state) => state.user);
    const navigate = useNavigate();
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [bannerImage, setBannerImage] = useState(false);
    const banner = createRef();


    const { gameId } = useParams();
    const { data: currentGame, isLoading } = useQuery(['games', gameId], () => api('/games/' + gameId));
    useEffect(() => {
        if (currentGame) {
            let system = systems.filter((s) => s.value == currentGame.systemId)[0].label;
            let tags = currentGame.tags.map((t) => { return { label: t.name, value: t.id, variant: t.variant } });
            setGame({
                ...currentGame,
                system,
                tags,
                locationAddress: currentGame.locationAddress ? { label: currentGame.locationAddress, value: { place_id: 0 } } : '',
                online: currentGame.online ? '1' : '0',
                manualApproval: currentGame.manualApproval ? '1' : '0',
                recurring: currentGame.recurring + ""
            });
            setBannerImage(process.env.REACT_APP_IMAGE_BASE  + currentGame.image);
        }
    }, [currentGame]);

    const [game, setGame] = useState({
        userId: user.id,
        name: '',
        maxPlayers: '',
        minPlayers: '',
        recurring: '0',
        manualApproval: '0',
        online: venueId ? '0' : '',
        system: '',
        price: 0,
        tags: [],
        locationAddress: '',
        venueId: venueId || null,
        venueHosted: venueId ? 1 : 0,
        startTime: '',
        endTime: '',
        date: dayjs().format('YYYY-MM-DD'),
        endDate: dayjs().format('YYYY-MM-DD'),
        description: '',
        image: '',
        recurPeriod: 'week',
        recurEveryX: 1,
        recurDays: [],
    });
    const [step, setStep] = useState(1);
    const [wantStep, setWantStep] = useState(1);
    const [image, setImage] = useState('');
    const [errors, setErrors] = useState({});
    const [validatedSteps, setValidatedSteps] = useState([]);
    const { filters, saveFilters, resetFilters } = useFilterState();

    const validate = (_steps) => {
        if (!_steps) _steps = validatedSteps;
        let err = {};
        if (_steps.includes(1)) {
            if (game.online === "") err.online = 'Please select a game type';
            if (game.name === "") err.name = 'You must provide a name';
            if (game.maxPlayers === "") err.maxPlayers = 'Provide a valid maximum number of players (enter 0 for unlimited)';
            if (game.system == "") err.system = 'You must select a game system';
        }
        if (_steps.includes(2)) {
            if (game.startTime === "") err.startTime = 'Select a start time';

            if (game.recurring === '1') {
                if (dayjs(game.endDate).diff(dayjs(game.date), 'day') < 0) err.endDate = 'End date must be after start date';
            }
        }
        if (_steps.includes(3)) {
            if (game.image === "" && banner.current?.files?.length < 1) err.image = 'Please select a background image';
        }
        setErrors(err);
        return err;
    };
    useEffect(() => { validate(); }, [game]);

    const nextStep = () => {
        if (step < 4) {
            let vsteps = _.uniq(validatedSteps.concat(step)).filter((s) => s <= step);
            setValidatedSteps(vsteps);
            const err = validate(vsteps)
            if (Object.keys(err).length == 0) {
                setStep(step + 1);
            }
        }
        else {
            let data = { ...game, locationAddress: game.locationAddress.label };
            data.tags = game.tags.map((t) => t.value);
            data.systemId = systems.filter((s) => s.label == game.system)[0].value;
            data.timezone = filters.timezone || 'America/New_York';
            if (mode == 'dupe') delete data.id;

            let body = new FormData();
            Object.keys(data).forEach((key) => {
                if (key === 'links') {
                    data.links.forEach((l) => {
                        body.append('links[][url]', l.url);
                        body.append('links[][label]', l.label);
                    })
                } else {
                    body.append(key, data[key]);
                }
            });

            const images = banner.current?.files;
            console.log("mages", images)
            if (images && images.length) {
                body.append('image', images[0]);
            }
            api('games', body).then((res) => {
                // TODO errors
                navigate('/games/mine');
            });
        }
    };

    const stubData = (game) => {
        let out = { ...game };
        out.tags = game.tags.map((t) => {
            return { name: t.label, variant: t.variant }
        });
        out.host = { id: user.id, name: user.username };
        out.locationAddress = game.locationAddress?.label;
        if (out.venueId) {
            out.venue = venuesQuery.data.filter((v) => v.id == out.venueId)[0];
        }

        out.timezone = filters.timezone || 'America/New_York';
        out.image = game.image || bannerImage;
        return out;
    };

    const title = () => {
        let out = (game.id ? 'Edit' : 'Host a') + ' Game';
        if (venueId) {
            let v = venuesQuery.data?.filter((v) => v.id == venueId);
            if (v && v.length) {
                out += ' at ' + v[0].name
            }
        }
        return out;
    }

    const handleFileChange = (event) => {
        console.log(event);
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                console.log(reader.result);
                setBannerImage(reader.result);
                setGame({ ...game, image: null });
            };
            reader.readAsDataURL(file);
        } else {
            setBannerImage(null);
        }
    };

    return <Box>
        <Header />
        <Container variant="md" >
            <Flex direction={'column'} w={'100%'} p={8} alignItems={'center'}>
                <Heading>{step < 4 ? title() : 'Game Preview'}</Heading>
            </Flex>
            <Flex wrap="wrap" justifyContent="center">
                {step < 4 && <b>Step {step} of 3</b>}
                {step < 4 && <Progress value={step * 33 + 1} w={'100%'} mb={6} colorScheme="orangey" mx={8} />}

                <Step1 step={step} errors={errors} systems={systems} tags={tags} game={game} setGame={setGame} venueId={venueId} />
                <Step2 step={step} errors={errors} game={game} setGame={setGame} />
                <Step3 step={step} errors={errors} banner={banner} venueHosted={venueId > 0} game={game} onOpen={onOpen} setBannerImage={setBannerImage} bannerImage={bannerImage} setGame={setGame} venuesQuery={venuesQuery} venueId={venueId} />
                {step == 4 && <GameDetails game={stubData(game)} />}

                <HStack w="100%" justify="space-between">
                    {step > 1 && <Button my={6} onClick={() => setStep(step - 1)} px={8} py={6} variant="outline">Previous Step</Button>}
                    <Button w="100%" my={6} onClick={nextStep} >{step < 3 ? 'Next Step' : (step == 3 ? 'Preview Game' : (game.id ? (mode == 'dupe' ? 'Publish Game' : 'Save Changes') : 'Publish Game'))}</Button>
                </HStack>
                <HStack w="100%" justify="space-between">
                    <Link to="/games/mine">Cancel</Link>
                </HStack>

            </Flex>
        </Container>
        <Input placeholder=" " type="file" ref={banner} onChange={handleFileChange} accept="image/*" style={{ display: 'none' }} />
        <Drawer placement={"bottom"} isOpen={isOpen}>
            <DrawerOverlay />
            <DrawerContent>
                <div style={{ flexDirection: 'row', justifyContent: 'center', display: 'flex', backgroundColor: 'var(--chakra-colors-lightNavy)' }}>
                    <div style={{ padding: '1em', maxHeight: '50vh', width: '100%' }}>
                        <VStack style={{ maxHeight: '100%' }} w="100%">
                            <Heading size="md" mb={3} variant="subhead">Banner Image</Heading>
                            <Accordion allowToggle w="100%" style={{ overflow: 'auto' }}>
                                {banners.sort((a, b) => a.section.localeCompare(b.section)).map((section) => <AccordionItem w="100%">
                                    <h2>
                                        <AccordionButton _expanded={{ bg: 'orange', color: 'white' }}>
                                            <Box as="span" flex='1' textAlign='left'>
                                                {section.section}
                                            </Box>
                                            <AccordionIcon />
                                        </AccordionButton>
                                    </h2>
                                    <AccordionPanel w="100%">
                                        <Flex wrap="wrap" justifyContent="center">
                                            {section.images.map((b) =>
                                                <Image
                                                    style={{
                                                        height: '10vh',
                                                        margin: '1em',
                                                        opacity: b.url == image.url ? 1 : 0.8,
                                                        border: '2px solid ' + (b.url == image.url ? 'orange' : 'gray'),
                                                        display: 'inline-block',
                                                        cursor: 'pointer',
                                                        borderRadius: 30
                                                    }}
                                                    onClick={() => setImage(b)}
                                                    src={process.env.REACT_APP_IMAGE_BASE + b.thumb}
                                                    key={b.url}
                                                />
                                            )}
                                        </Flex>
                                    </AccordionPanel>
                                </AccordionItem>)}
                            </Accordion>
                            <Button w="50%" onClick={() => { onClose(); setGame({ ...game, image: image.url }); setBannerImage(process.env.REACT_APP_IMAGE_BASE + image.thumb) }}>Set Image</Button>
                            <Link onClick={onClose}>Cancel</Link>
                        </VStack>
                    </div>
                </div>
            </DrawerContent>
        </Drawer>
        <ZonePicker />
    </Box>
}

const ValidationError = ({ children }) => {
    return children ? <Text ml={2} px={2} color="warning" style={{ border: '1px dashed red', fontWeight: 'bold', float: 'right' }}>{children}</Text> : null
};

const Step1 = ({ step, errors, systems, tags, game, setGame, venueId }) => {
    const [showDescHelp, setShowDescHelp] = useState(false);
    if (step != 1) return null;
    return (
        <>
            {venueId == null && <>
                <RadioGroup alignItems="start" w="100%" pb={6} value={game.online} onChange={(ev) => setGame({ ...game, online: ev })} >
                    <HStack align="start">
                        <Radio value='1' mr={6}>Online</Radio>
                        <Radio value='0'>In-Person</Radio>
                        <ValidationError>{errors.online}</ValidationError>
                    </HStack>
                </RadioGroup>
            </>}

            <FormControl variant="floating" pb={6} isRequired>
                <Input placeholder=" " value={game.name} onChange={(ev) => setGame({ ...game, name: ev.target.value })} />
                <FormLabel>Game Name
                    <ValidationError>{errors.name}</ValidationError>
                </FormLabel>
            </FormControl>

            <FormControl variant="floating" pb={6} isRequired>
                <Input placeholder=" " type="number" value={game.maxPlayers} onChange={(ev) => setGame({ ...game, maxPlayers: ev.target.value })} />
                <FormLabel>
                    Maximum Number of Players
                    <ValidationError>{errors.maxPlayers}</ValidationError>
                </FormLabel>
                <FormHelperText>Enter 0 for unlimited or to disable signup</FormHelperText>
            </FormControl>

            <FormControl variant="floating" pb={6}>
                <Input placeholder=" " type="number" value={game.minPlayers} onChange={(ev) => setGame({ ...game, minPlayers: ev.target.value })} />
                <FormLabel>
                    Minimum Number of Players
                    <ValidationError>{errors.minPlayers}</ValidationError>
                </FormLabel>
            </FormControl>

            {venueId != null && <FormControl variant="floating" pb={6} isRequired>
                <Input placeholder=" " type="number" step="0.01" value={game.price} onChange={(ev) => setGame({ ...game, price: ev.target.value })} />
                <FormLabel>
                    Price
                    <ValidationError>{errors.price}</ValidationError>
                </FormLabel>
                <FormHelperText>Enter 0 for Free events</FormHelperText>
            </FormControl>}

            <FormControl variant="floating" pb={6} isRequired>
                <AsyncSelect
                    value={game.system ? { label: game.system } : null}
                    onChange={(e) => setGame({ ...game, system: e ? e.label : '' })}
                    defaultOptions={systems && systems.slice ? systems.slice(0, 7) : []}
                    classNamePrefix="chakra-react-select"
                    placeholder=""
                    components={{ MenuList: (props) => <components.MenuList {...props}>
                    <Box p={1} bg="gray" color="white" border="1px solid white">(Select a game below or type to search all games...)</Box>
                    <Box>
                    {props.children}
                    </Box>
                  </components.MenuList>}}
                    noOptionsMessage={() => <div><a href="/help">Missing a Game?  Click here to request it.</a></div>}
                    loadOptions={(str) => new Promise((resolve, reject) => {
                        resolve(systems
                            .filter((g) => g.label.toLowerCase().indexOf(str.toLowerCase()) > -1)
                            .sort((a, b) => a.label.localeCompare(b.label))
                        )
                    })}
                    chakraStyles={reactSelectStyles}
                />
                <FormLabel>Game System
                    <ValidationError>{errors.system}</ValidationError>
                </FormLabel>
            </FormControl >

            <FormControl variant="floating" pb={6}>
                <Textarea placeholder=" " h='10em' value={game.description} onChange={(ev) => setGame({ ...game, description: ev.target.value })} onFocus={() => setShowDescHelp(true)} onBlur={() => setShowDescHelp(false)} />
                <FormLabel>Description</FormLabel>
                {showDescHelp && <FormHelperText>
                    <Icon as={FaInfoCircle} style={{ position: 'relative', top: 2 }} /> Include details and links about the tools you plan to use in your game.  Describe the setting, themes, and tone of your game.  If you have specific safety tools you plan to use, please include them here.
                </FormHelperText>}
            </FormControl>

            <Heading size="md" mb={3} variant="subhead">Tags</Heading>
            <Combo
                value={game.tags}
                onChange={(e, x) => {
                    console.log(x, e);
                    switch (x.action) {
                        case 'create-option':
                            setGame({ ...game, tags: e });
                            break;
                        case 'clear':
                            setGame({ ...game, tags: [] })
                            break;
                        case 'remove-value':
                            let r = x.removedValue;
                            setGame({ ...game, tags: game.tags.filter((t) => !(r.label == t.label && r.ugc == t.ugc)) })
                            break;
                        default:
                            setGame({ ...game, tags: _.uniq(game.tags.concat(e)) })
                            break;
                    }
                }}
                isMulti
                name="tags"
                options={tags ? tags.map((t) => { return { variant: t.variant, value: t.id, label: t.name } }).sort((a, b) => a.label.localeCompare(b.label)) : []}
                tagVariant="ugc"
                chakraStyles={reactSelectStyles}
            />
        </>
    );
};

const Step2 = ({ step, errors, game, setGame }) => {
    const { filters } = useFilterState();
    const hours = _.flatten([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23].map((t) => [
        { value: `${t}:00`, label: t == 0 ? '12:00 AM' : (t > 12 ? t - 12 : t) + ':00' + (t > 11 ? ' PM' : ' AM') },
        { value: `${t}:30`, label: t == 0 ? '12:30 AM' : (t > 12 ? t - 12 : t) + ':30' + (t > 11 ? ' PM' : ' AM') }
    ]))
    if (step != 2) return null;
    return (
        <>
            <Heading size="md" mb={3} variant="subhead">Game Time</Heading>
            <HStack justify="space-between" w="100%" alignItems="center">
                <FormControl variant="floating" pb={6}>
                    <Select
                        placeholder=" "
                        value={game.startTime ? hours.filter((h) => h.value == game.startTime)[0] : null}
                        onChange={(ev) => setGame({ ...game, startTime: ev.value })}
                        chakraStyles={reactSelectStyles}
                        classNamePrefix="chakra-react-select"
                        options={hours}
                    />
                    <FormLabel>Start Time
                        <ValidationError>{errors.startTime}</ValidationError>
                    </FormLabel>
                </FormControl>
                <FormControl variant="floating" pb={6}>
                    <Select
                        placeholder=" "
                        value={game.endTime ? hours.filter((h) => h.value == game.endTime)[0] : null}
                        onChange={(ev) => setGame({ ...game, endTime: ev.value })}
                        chakraStyles={reactSelectStyles}
                        classNamePrefix="chakra-react-select"
                        options={hours}
                    />
                    <FormLabel>End Time</FormLabel>
                </FormControl>
                <LocalTime justTz={true} time={game.startTime} tz={filters.timezone} fontSize="xl" pb={6} />
            </HStack>

            <Heading size="md" mb={3} variant="subhead">Approval</Heading>
            <RadioGroup alignItems="start" w="100%" pb={6} value={game.manualApproval} onChange={(ev) => setGame({ ...game, manualApproval: ev })}>
                <HStack align="start">
                    <Radio value='1' mr={6}>Manual Approval</Radio>
                    <Radio value='0'>Auto Approval</Radio>
                    <Radio value='2'>Auto Approve Verified Users</Radio>
                </HStack>
            </RadioGroup>

            <Heading size="md" mb={3} variant="subhead">Reccurance</Heading>
            <RadioGroup alignItems="start" w="100%" pb={6} value={game.recurring} onChange={(ev) => setGame({ ...game, recurring: ev })}>
                <VStack align="start">
                    <Radio value='0' mr={6}>One-Shot</Radio>
                    <Radio value='1'>Series of Games with variable attendance (ex. Friday Night magic, Adventurer’s League, Drop-in Campaign)</Radio>
                    <Radio value='2'>Recurring Game with same players (ex. Ongoing RPG Campaign, multi-day tournament)</Radio>
                </VStack>
            </RadioGroup>

            {game.recurring === "0" && <>
                <Heading size="md" mb={3} variant="subhead">Date</Heading>
                <DatePicker
                    name="date-input"
                    showIcon
                    selected={dayjs(game.date).toDate()}
                    onChange={(date) => setGame({ ...game, date: dayjs(date).format('YYYY-MM-DD') })}
                />
            </>}

            {(game.recurring === "1" || game.recurring === "2") && <>
                <Heading size="md" mb={3} variant="subhead">Date Range</Heading>
                <HStack justify="space-between" w="100%">
                    <Text>Recur from</Text>
                    <DatePicker
                        name="date-input"
                        showIcon
                        selected={dayjs(game.date).toDate()}
                        onChange={(date) => setGame({ ...game, date: dayjs(date).format('YYYY-MM-DD') })}
                    />
                    <Text>to</Text>
                    <DatePicker
                        name="date-input"
                        showIcon
                        selected={dayjs(game.endDate).toDate()}
                        onChange={(date) => setGame({ ...game, endDate: dayjs(date).format('YYYY-MM-DD') })}
                    />
                </HStack>
                <ValidationError mt={2}>{errors.endDate}</ValidationError>

                <RadioGroup alignItems="start" w="100%" my={6} value={game.recurPeriod} onChange={(ev) => setGame({ ...game, recurPeriod: ev })} >
                    <HStack align="start">
                        <Radio value='day' mr={2}>Daily</Radio>
                        <Radio value='week' mr={2}>Weekly (on {dayjs(game.date).format('dddd')}s - change start date to change Day)</Radio>
                    </HStack>
                    <HStack align="start" mt={2}>
                        <Radio value='month' mr={2}>Monthly</Radio>
                        < Radio value='year' mr={2}>Yearly</Radio>
                    </HStack>
                </RadioGroup>
                <HStack justify="left" w="100%">
                    <Text>Recur&nbsp;every</Text>
                    <Icon as={FaMinus} onClick={() => setGame({ ...game, recurEveryX: Math.max(1, game.recurEveryX - 1) })} cursor="pointer" />
                    <Input type="number" w={70} value={game.recurEveryX} onChange={(ev) => setGame({ ...game, recurEveryX: ev.target.value })} />
                    <Icon as={FaPlus} onClick={() => setGame({ ...game, recurEveryX: game.recurEveryX + 1 })} cursor="pointer" />
                </HStack>
            </>}


        </>
    );
};

const Step3 = ({ step, venueHosted, errors, onOpen, game, banner, bannerImage, setBannerImage, setGame, venuesQuery, venueId }) => {
    const [tab, setTab] = useState(0);

    let img = null;
    if (bannerImage != null) {
        img = 'url(' + bannerImage + ')';
    }

    const handleTabsChange = (index) => {
        if (index == 1) setGame({ ...game, locationAddress: '' });
        if (index == 0) setGame({ ...game, venueId: null });
        setTab(index);
    };



    if (step != 3) return null;
    return (
        <>
            {(game.online == 0 && venueId == null) && <Heading size="md" mb={3} variant="subhead">Location</Heading>}
            {(game.online == 0 && venueId == null) && <Tabs index={tab} onChange={handleTabsChange} w="100%">
                <TabList>
                    <Tab w="50%">Enter Address</Tab>
                    <Tab w="50%">Select Venue</Tab>
                </TabList>
                <TabPanels>
                    <TabPanel>
                        <FormControl variant="floating" pb={6}>
                            <GooglePlacesAutocomplete
                                apiKey={process.env.REACT_APP_MAPS_KEY}
                                placeholder=" "
                                selectProps={{
                                    placeholder: "Enter an Address...",
                                    chakraStyles: reactSelectStyles,
                                    classNamePrefix: "chakra-react-select",
                                    isClearable: true,
                                    value: game.locationAddress,
                                    onChange: (value) => { console.log(value); setGame({ ...game, locationAddress: value }) },
                                }} />
                            <FormLabel>Address</FormLabel>
                        </FormControl>
                    </TabPanel>
                    <TabPanel>
                        <FormControl>
                            <Select
                                placeholder=" "
                                selected={game.venueId}
                                onChange={(ev) => setGame({ ...game, venueId: ev.value })}
                                chakraStyles={reactSelectStyles}
                                classNamePrefix="chakra-react-select"
                                options={venuesQuery?.data.map((v) => { return { value: v.id, label: v.name } })}
                            />
                            <FormHelperText>
                                <Icon as={FaInfoCircle} style={{ position: 'relative', top: 2 }} /> Please make sure you have permission from the venue to host your game.
                            </FormHelperText>
                        </FormControl>
                    </TabPanel>
                </TabPanels>
            </Tabs>}

            <Heading size="md" mb={3} variant="subhead">Background Banner</Heading>
            <HStack gap={4} alignItems={"start"}>
                <Box
                    style={{
                        width: 300,
                        height: 200,
                        border: "1px solid white",
                        fontSize: "100px",
                        borderRadius: 10,
                        lineHeight: '200px',
                        textAlign: 'center',
                        backgroundImage: img,
                        backgroundSize: 'cover'
                    }}
                    onClick={onOpen}
                >+</Box>
                {game.venueHosted && <Box textAlign="center">
                    <Heading size="md" mb={3} variant="subhead" textAlign="center">OR<br />Upload Custom Image</Heading>
                    <FormControl variant="floating" pb={6} >
                        <Button onClick={() => banner.current?.click()}>Upload Image</Button>
                    </FormControl>
                </Box>}
            </HStack >
            <ValidationError>{errors.image}</ValidationError>
        </>
    );
};


export default CreateGamePage;
