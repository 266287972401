import { create } from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'


const useFilterState = create(
    persist(
        (set, get) => ({
            filters: { name: '', system: false, type: false },
            saveFilters: (filters) => set((state) => ({ filters })),
            resetFilters: () => set((state) => ({ filters: { timezone: state.filters.timezone, name: '', system: false, type: false } })),
        }),
        { name: 'advnexus-filter' }
    ));

export default useFilterState;
