import dayjs from 'dayjs';

const openStatus = (venue) => {
    let now = dayjs();
    let dayNum = dayjs().day();
    let dayName;
    switch (dayNum) {
        case 0: dayName = 'Sunday'; break;
        case 1: dayName = 'Monday'; break;
        case 2: dayName = 'Tuesday'; break;
        case 3: dayName = 'Wednesday'; break;
        case 4: dayName = 'Thursday'; break;
        case 5: dayName = 'Friday'; break;
        case 6: dayName = 'Saturday'; break;
    }

    let open = venue[`${dayName.toLowerCase()}Open`];
    let close = venue[`${dayName.toLowerCase()}Close`];
    if (!open || !close) return 'Closed Today';

    open = parseInt(open.split(':')[0]);
    close = parseInt(close.split(':')[0]);
    let hour = now.hour();
    if (hour < open) {
        if ((open - hour) < 1) return 'Opens Soon';
        else {
          let inh = Math.round(open - hour);
           return `Opens in ${inh} hour${inh > 1 ? 's' : ''}`;
        }
    } else if (hour >= close) {
        return 'Closed';
    } else if (hour + 1 >= close) {
        return 'Closing Soon';
    } else {
        return 'Open';
    }
};

const linkify = (text) => {
  if (text.match(/https?:/)) return text;
  else return `http://${text}`
}

export  {
    openStatus,
    linkify
};
  
