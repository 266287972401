import {
    React,
    useState,
    useRef,
} from "react";
import {
    Box,
    Button,
    Container,
    Flex,
    FormControl,
    FormLabel,
    HStack,
    Input,
    Link,
    Icon,
    Image,
    Divider,
    Heading,
    Text,
    VStack,

    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Textarea,
} from '@chakra-ui/react';
import api from "../../utils/api";
import { useQuery } from 'react-query';
import Header from '../../components/Header';
import useFilterState from '../../stores/filters';
import reverse from "reverse-geocode";


import { FaWindowClose, FaSearch } from "react-icons/fa";


const NotificationsPage = ({ }) => {
    const notificationsQuery = useQuery('notification-requests', () => api('notifications/'));
    const { filters, saveFilters, resetFilters } = useFilterState();

    const removeNotification = async (notification) => {
        await api('notifications/' + notification.id, null, 'DELETE');
        notificationsQuery.refetch();
    };

    const research = (notification) => {
        saveFilters(notification.filter.filters);
        window.location.href = "/games";
    }

    const city_state = (lat, lon) => {
        let tmp = reverse.lookup(lat, lon, 'us');
        return tmp.city + ", " + tmp.state
    }

    return <Box>
        <Header back="/account" backLabel="Back to Account" />
        <Flex direction={'column'} w={'100%'} p={8} alignItems={'center'}>
            <Heading >My Custom Notifications</Heading>
            {notificationsQuery.data && notificationsQuery.data?.map((notification) => {
                return <Box key={notification.id} w={'100%'} p={4} borderWidth="1px" borderRadius="lg" maxW="800px" m={4}>
                    <HStack justify="space-between" alignItems="center">
                        <VStack alignItems="start">
                            {notification.filter?.filters?.name && <HStack>
                                <Text fontWeight="bold">Search:</Text>
                                <Text>{notification.filter?.filters?.name}</Text>
                            </HStack>}
                            {notification.filter?.filters?.online && <HStack>
                                <Text fontWeight="bold">Type:</Text>
                                <Text>{notification.filter?.filters?.online == "1" ? "Online" : "In-Person"}</Text>
                            </HStack>}
                            {notification.filter?.filters?.system && <HStack>
                                <Text fontWeight="bold">System{notification.filter?.filters?.system?.length > 1 ? "s" : ""}:</Text>
                                <Text>{notification.filter?.filters?.system?.map && notification.filter?.filters?.system?.map((s) => s.label).join(', ')}</Text>
                            </HStack>}
                            {notification.filter?.filters?.tags?.map && <HStack>
                                <Text fontWeight="bold">Tag{notification.filter?.filters?.tags?.length > 1 ? "s" : ""}:</Text>
                                <Text>{notification.filter?.filters?.tags?.map && notification.filter?.filters?.tags?.map((s) => s.label).join(', ')}</Text>
                            </HStack>}
                            {notification.filter?.filters?.location && notification.filter?.filters?.distance && <HStack>
                                <Text fontWeight="bold">Within {notification.filter?.filters?.distance} miles:</Text>
                                <Text>{notification.filter?.filters?.location?.value?.place_id ? notification.filter?.filters?.location?.label : city_state(notification.filter?.filters?.location?.lat, notification.filter?.filters?.location?.lon)}</Text>
                            </HStack>}
                        </VStack>
                        <VStack alignItems="start">
                            <HStack onClick={() => removeNotification(notification)} cursor="pointer" color="red">
                                <a href="#"><Icon pt={1} as={FaWindowClose} /></a>
                                <Text size="lg"> Stop Notifications </Text>
                            </HStack>
                            <HStack onClick={() => research(notification)} cursor="pointer" color="orange">
                                <a href="#"><Icon pt={1} as={FaSearch} /></a>
                                <Text size="lg">Search Matching Games</Text>
                            </HStack>
                        </VStack>
                    </HStack>

                </Box>
            })}
        </Flex >
    </Box >
};

export default NotificationsPage;
