import { React, useState, useRef } from "react";
import {
  Box,
  Button,
  Container,
  FormControl,
  FormLabel,
  Heading,
  Image,
  Input,
  Text,
  Link,
  VStack,
  Code,
  Grid
} from '@chakra-ui/react';
import logo from "../assets/logo.png";
import Error from "../components/Error";
import Success from "../components/Success";
import useUserState from "../stores/user";
import api from "../utils/api";

const LoginPage = (props) => {
  const [ username, setUsername ] = useState('');
  const [ loading, setLoading ] = useState(false);
  const [ error, setError ] = useState(false);
  const [ success, setSuccess ] = useState(false);
  const userRef = useRef();

  const handleForgot = () => {
    setLoading(true);
    setError('');
    api('auth/forgot-password', {username}).then((res) => {
      if (res.error) setError(res.error);
      else {
        setUsername('');
        userRef.current.value = '';
        userRef.current.blur();
        setSuccess(res.data.message);
      }
    }).catch((e) => {
      setError("Something went wrong");
    }).finally(() => setLoading(false));
        
  }

  return <Container variant="md" >
      <VStack w={'100%'} p={8}>
        <Image src={logo} w={103} mb={2}/>
        <Heading>Forgot Your Password</Heading>
        <Text fontWeight="light">Please enter your username or email adddress and we'll send you an email.</Text>
        {error && <Error>{error}</Error>}
        {success && <Success>{success}</Success>}
          
        <FormControl isRequired variant="floating" pb={4}>
          <Input onChange={(ev) => setUsername(ev.target.value)} onKeyUp={(ev) => ev.key === 'Enter' && handleForgot()} type="text" placeholder=" " ref={userRef} />
          <FormLabel>Username or Email</FormLabel>
        </FormControl>
        <Button w="100%" onClick={handleForgot} disabled={loading} style={{opacity: loading ? 0.3 : 1}}>Send Password Reset Email</Button>
        <Text fontWeight="light"> Remember your password? <Link href="/login">Login here</Link></Text>
    </VStack>
  </Container>
}

export default LoginPage;
