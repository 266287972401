import { create } from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'


const useUserState = create(
  persist(
    (set, get) => ({
      user: null,
      loggedOut: null,
      filters: { name: '', system: false, type: false },
      saveFilters: (filters) => set((state) => ({ filters })),
      resetFilters: () => set((state) => ({ filters: {} })),
      login: (user) => set((state) => ({ user })),
      update: (user) => set((state) => ({ user })),
      logout: () => set((state) => ({ user: null })),
      setLoggedOutMsg: (msg) => set((state) => ({ loggedOut: msg })),
    }),
    { name: 'advnexus-login' }
  ));

export default useUserState;
