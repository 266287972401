import {
    React,
    useState,
} from "react";
import {
    Box,
    Button,
    Container,
    Flex,
    FormControl,
    FormLabel,
    HStack,
    Input,
    Image,
    Heading,
    Text,
    VStack,

    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,

    useDisclosure,
} from '@chakra-ui/react';
import { useTransition } from 'transition-hook';
import { Link, useNavigate } from "react-router-dom";
import LiteYouTubeEmbed from 'react-lite-youtube-embed';
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'


import LandingBG from "../components/LandingBG";
import Error from '../components/Error'
import logo from "../assets/logo.png";
import api from "../utils/api";

const speed = 2.5;

const LandingPage = ({ }) => {
    const navigate = useNavigate();
    const [error, setError] = useState(false);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [zip, setZip] = useState('');

    const [clicked, setClicked] = useState(true);
    const [complete, setComplete] = useState(false);
    const { isOpen, onOpen, onClose } = useDisclosure()
    const { stage, shouldMount } = useTransition(clicked, 1000 * speed)

    const register = async () => {
        if (!firstName || !lastName || !email || !zip) {
            setError('Please fill out all fields.');
            return;
        }
        if (!email.match(/.+@.+\..+/)) {
            setError('Please enter a valid email address.');
            return;
        }
        if (!zip.match(/\d{5}/)) {
            setError('Please enter a valid zip code.');
            return;
        }
        let res = await api('user/landing', { firstName, lastName, email, zip }, 'POST');
        setError(false);

        if (zip.match(/^1[34]/)) {
            navigate('/signup');
            return;
        }

        setComplete(true);
        document.getElementById('dragon').style.transform = 'translateY(-20px)'
    };

    return <Container variant="md" p={0}>
        {true && <Link to="/login" style={{
            position: 'fixed',
            top: 5,
            right: 10,
            color: 'orange',
            textDecoration: 'underline'
        }}>Already have a Beta account?</Link>}
        <VStack w={'100%'}>
            <Image src={logo} w={153} mb={4} mt={4} />
            <Heading size="md" textAlign="center">
                Register Now!
            </Heading>
            <Text fontWeight="light" px={4} align="justify">The Adventure Nexus is a web-based platform and community hub for players of Tabletop Games (TTGs) and Game Masters (GMs) to connect, run, and safely transact games. It is now in Beta with the goal of creating a one-stop shop for all things TTGs, making it easy for players to find and join games, and for GMs to create and manage their own adventures.</Text>
            <Button onClick={onOpen}>See a Sneak Peek!</Button>

            <Box w="100%" align="center" style={{ position: 'relative' }}>
                <LandingBG />
                {!shouldMount && <Button w="300px" style={{
                    position: 'fixed',
                    bottom: 50,
                    left: '50%',
                    marginLeft: '-150px',
                    transition: speed + 's',
                    opacity: stage == 'enter' ? 0 : 1
                }} onClick={() => { window.gtag('event', 'click', { 'button': 'Landing-Register' }); setClicked(true); document.getElementById('dragon').style.transform = 'translateY(-100px)'; }}>Register</Button>}
                {shouldMount && !complete && <Box w="400px" style={{
                    borderRadius: '20px',
                    position: 'fixed',
                    bottom: 50,
                    left: '50%',
                    marginLeft: '-200px',
                    transition: speed + 's',
                    padding: '1rem',
                    opacity: stage == 'enter' ? 1 : 0,
                    backgroundColor: 'rgba(0,0,0,0.5)',
                }}>
                    <Heading size="md">Register Now</Heading>
                    {error && <Error>{error}</Error>}
                    <HStack>
                        <FormControl isRequired variant="floating" pb={4}>
                            <Input onChange={(ev) => setFirstName(ev.target.value)} type="text" placeholder=" " />
                            <FormLabel>First Name</FormLabel>
                        </FormControl>
                        <FormControl isRequired variant="floating" pb={4}>
                            <Input onChange={(ev) => setLastName(ev.target.value)} type="text" placeholder=" " />
                            <FormLabel>Last</FormLabel>
                        </FormControl>
                    </HStack>
                    <FormControl isRequired variant="floating" pb={4}>
                        <Input onChange={(ev) => setEmail(ev.target.value)} type="text" placeholder=" " />
                        <FormLabel>Email</FormLabel>
                    </FormControl>
                    <FormControl isRequired variant="floating" pb={4}>
                        <Input onChange={(ev) => setZip(ev.target.value)} type="text" placeholder=" " />
                        <FormLabel>Zip Code</FormLabel>
                    </FormControl>
                    <Button w="50%" onClick={register}>Register</Button>
                </Box>}
                {complete && <Box w="100%" style={{
                    position: 'absolute',
                    bottom: 250,
                }}>
                    <Heading size="lg">Registration Complete</Heading>
                    <p>We'll notify you when we're launching in your area!</p>
                </Box>}
            </Box>
        </VStack>

        <Modal isOpen={isOpen} onClose={onClose} size="full">
            <ModalOverlay />
            <ModalContent>
                <ModalCloseButton color="orange" bg="gray" />
                <ModalBody>
                    <Box px={8}>
                        <LiteYouTubeEmbed
                            id="HGe8t2vHguA"
                            title="What’s new in Material Design for the web (Chrome Dev Summit 2019)"
                        />
                    </Box>
                </ModalBody>

            </ModalContent>
        </Modal>
    </Container>
}



export default LandingPage;
