import { React, useEffect } from "react";
import {
  Box,
  Button,
  Container,
  Heading,
  Image,
  Text,
  VStack,
  Link,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import { useAuth0 } from "@auth0/auth0-react";

import { useNavigate } from "react-router-dom";
import logo from "../assets/logo.png";
import useUserState from "../stores/user";
import api from "../utils/api";

const LoginPage = ({ mode }) => {
  const { login } = useUserState((state) => state);
  const { isLoading, isAuthenticated, user: auth0user, loginWithRedirect, getAccessTokenSilently, error: auth0error } = useAuth0();
  const { isOpen, onOpen } = useDisclosure()
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      let token;
      try {
        token = await getAccessTokenSilently();
      } catch (e) {
        console.log('error getting token', e);
        token = null
      }
      if (!token || !auth0user) {
        return;
      }
      auth0user.token = token;
      api('/user/auth0', auth0user).then((res) => {
        login({ ...res.data.user, access_token: res.data.access_token });
        navigate('/');
      });
    })();
  }, [isLoading, auth0user, getAccessTokenSilently, login, navigate]);

  const open = () => {
    onOpen();
    setTimeout(() => { document.getElementById('terms').scrollTop = 0; }, 50);
  }
  let content;
  if (auth0error) {
    if (auth0error.message.match(/blocked/)) {
      content = <>
        <Text size="lg">Your account has been disabled due to policy violations. Please contact support for more information.</Text>
      </>
    } else {
      content = <>
        <Text size="lg">These was a problem logging you in: {auth0error.message}</Text>
        <Link href="/login" color="orange">Back to Login</Link>
      </>
    }
  }
  else if (!isLoading && !isAuthenticated) {
    content = <>
      <Heading>{mode === 'signup' ? 'Create Account' : 'Sign In'}</Heading>
      <Text fontWeight="light">{mode === 'signup' ? 'You must read and agree to the community guidelines to continue.' : 'Please log in to continue'}</Text>
      <Button w="100%" onClick={open} >{mode === 'signup' ? 'View Guidelines' : 'Sign In'}</Button>
    </>
  } else {
    content = <><Heading>Logging you in...</Heading></>
  }


  return <Box>
    <Container variant="md" >
      <VStack w={'100%'} p={8}>
        <Image src={logo} w={103} mb={2} />
        {content}
      </VStack>
    </Container>
    <Drawer placement={"bottom"} isOpen={isOpen} >
      <DrawerOverlay />
      <DrawerContent>
        <div style={{ flexDirection: 'row', justifyContent: 'center', display: 'flex', backgroundColor: 'var(--chakra-colors-lightNavy)' }}>
          <div style={{ maxWidth: '80vw', padding: '1em', maxHeight: '60vh', overflow: 'auto' }} id="terms">
            <Heading size="lg">Community Guidelines</Heading>

            <p>Welcome to The Adventure Nexus Beta Community!</p>

            <p>We're excited to have you join us as we build this app together. As a beta tester, you play a crucial role in shaping the future of The Adventure Nexus. Your feedback is invaluable!</p>

            <p>Please be patient with any bugs or glitches you encounter. We're still working hard to improve the app. Feel free to share your ideas and suggestions for new features or improvements and help us spread the word about the beta by inviting others to join the community. Together, we can create something amazing!</p>

            <p>To ensure a positive and safe experience for everyone, please follow the community guidelines listed below. Failure to these guidelines may result in a temporary or permanent ban from the app.</p>

            <Heading size="md" mt={4}>Hacking and Reverse Engineering</Heading>
            <p>You shall not attempt to access, modify, or damage any part of the app or its underlying systems; disassemble, decompile, or reverse engineer the app's code or any of its components; or circumvent or disable any security or access control features of the app. Violation of this policy will not only result in expulsion from the app but may also result in legal actions against those suspected of doing so.</p>

            <Heading size="md" mt={4}>Harassment and Hate Speech</Heading>
            <p>The Adventure Nexus does not tolerate harassment or hate speech in any form. Users shall not partake in trolling, stalking, slander, shaming, threats, bullying, intimidation, or threats of violence. Any content that promotes, advocates for, or condones racism, bigotry, hatred, or violence against individuals or groups based on factors like race, ethnicity, religious affiliation, politics, disability, gender, age, national origin, sexual orientation, or gender identity may result in permanent expulsion from the app. This includes content that suggests or glorifies acts of self-harm.</p>

            <Heading size="md" mt={4}>Illegal Usage</Heading>
            <p>You shall not use The Adventure Nexus to do anything which might be in violation of Federal, State, or local laws.</p>

            <Heading size="md" mt={4}>Impersonation</Heading>
            <p>The Adventure Nexus is meant for personal use. Impersonation of others through use of their account credentials is prohibited. </p>

            <Heading size="md" mt={4}>Minors</Heading>
            <p>You must be 18 years of age or older to use The Adventure Nexus.</p>

            <Heading size="md" mt={4}>Sexual Content</Heading>
            <p>No sexually explicit content shall be posted or shared via The Adventure Nexus. This includes promoting or advocating for commercial sexual services, human trafficking or other non-consensual sexual acts.</p>

            <Heading size="md" mt={4}>Spam and Solicitation</Heading>
            <p>Outside of approved “venue” pages, The Adventure Nexus shall not be used to promote commercial agendas including advertising an event, business, non-profit, political campaign, contest, or to conduct research.</p>


            <p>By clicking below you are acknowledging you understand and are agreeing to follow the community guidelines set forth above.</p>

            <Button mt={4} w="100%" onClick={() => loginWithRedirect()}>I agree, Proceed to Sign In</Button>
          </div>
        </div>
      </DrawerContent>
    </Drawer>
  </Box>

}

export default LoginPage;
