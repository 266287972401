import {
    Box,
    Icon,
} from '@chakra-ui/react';
import { BsPerson } from "react-icons/bs";


const Avatar = ({ onClick, avatar, style, size = 60, icon = BsPerson }) => {
    return <Box
        style={{
            ...style,
            width: size + 'px',
            height: size + 'px',
            border: "1px solid white",
            fontSize: size + 'px',
            borderRadius: Math.floor(size / 10), lineHeight: size + 'px',
            textAlign: 'center',
            backgroundImage: avatar ? 'url("' + avatar + '")' : null,
            backgroundSize: 'cover'
        }}
        onClick={onClick}
    >
        {!avatar && <Icon as={icon} size={size} />}
    </Box>
};

export default Avatar;
