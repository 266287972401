import React, {
    useState,
    useEffect,
} from "react";

import {
    Box,
    Button,
    Container,
    Flex,
    FormControl,
    FormLabel,
    Heading,
    HStack,
    VStack,
    Icon,
    Input,
    InputGroup,
    InputLeftElement,
    Image,
    SimpleGrid,
    Text,
    Textarea,
    Drawer,
    DrawerContent,
    DrawerOverlay,
    useDisclosure,
} from '@chakra-ui/react';
import { Link } from "react-router-dom";
import { useNavigate, useParams } from "react-router-dom";
import useUserState from "../../stores/user";
import _ from "underscore";
import Header from '../../components/Header';
import { useQuery } from 'react-query';
import api from '../../utils/api'
import { Select } from "chakra-react-select";
import reactSelectStyles from "../../theme/reactSelectStyles";
import GooglePlacesAutocomplete from 'chakra-ui-google-places-autocomplete';
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);


const ValidationError = ({ children }) => {
    return children ? <Text ml={2} px={2} color="warning" style={{ border: '1px dashed red', fontWeight: 'bold', float: 'right' }}>{children}</Text> : null
};

const CreateVenuePage = ({ }) => {
    const user = useUserState((state) => state.user);
    const { venueId } = useParams();
    const navigate = useNavigate();
    const image = React.createRef()
    const banner = React.createRef()
    const { isOpen, onOpen, onClose } = useDisclosure()


    const addLink = () => {
        setVenue({ ...venue, links: [...venue.links, { label: "", url: "" }] });
    };
    const { data: currentVenue, isLoading } = useQuery(['venue', venueId], () => api('/venues/' + venueId));
    useEffect(() => {
        if (currentVenue) {
            setVenue({
                ...currentVenue,
                links: [{ label: "", url: "" }]
            });
        }
    }, [currentVenue]);

    const [venue, setVenue] = useState({
        userId: user.id,
        name: '',
        address: '',
        contact: '',
        email: '',
        phone: '',
        website: '',
        image: '',
        banner: '',
        facebookLink: '',
        instagramLink: '',
        twitterLink: '',
        discordLink: '',
        sundayOpen: '',
        sundayClose: '',
        mondayOpen: '',
        mondayClose: '',
        tuesdayOpen: '',
        tuesdayClose: '',
        wednesdayOpen: '',
        wednesdayClose: '',
        thursdayOpen: '',
        thursdayClose: '',
        fridayOpen: '',
        fridayClose: '',
        saturdayOpen: '',
        saturdayClose: '',
        links: [{ label: "", url: "" }],
    });
    const [errors, setErrors] = useState({});
    const [submitted, setSubmitted] = useState(false);

    const validate = (override) => {
        if (!submitted && !override) return;
        let err = {};
        if (venue.name === "") err.name = 'You must provide a name';
        if (venue.phone === "") err.phone = 'You must provide a phone #';
        if (venue.address === "") err.address = 'You must provide an address';
        if (venue.contact === "") err.contact = 'You must provide a contact person';
        const files = image.current?.files;
        if (!files || files.length == 0) {
            if (!venue.id) {
                err.image = 'You must upload an image';
            }
        }

        setErrors(err);
        return err;
    };
    useEffect(() => { validate(); }, [venue, submitted]);

    const preSaveVenue = () => {
        let err = validate(true);
        if (Object.keys(err).length) return;

        if (venue.id) saveVenue();
        else onOpen();
    };

    const saveVenue = () => {
        setSubmitted(true);
        const err = validate(true);

        if (Object.keys(err).length) return;
        let data = { ...venue, address: venue.address.label };

        let body = new FormData();
        Object.keys(data).forEach((key) => {
            body.append(key, data[key]);
        });

        const files = image.current?.files;
        if (files && files.length) {
            body.append('image', files[0]);
        }

        const banners = banner.current?.files;
        if (banners && banners.length) {
            body.append('banner', banners[0]);
        }

        api('venues', body).then((res) => {
            // TODO errors
            navigate('/venues/mine');
        });
    };

    const setLink = (index, key, value) => {
        setVenue({ ...venue, links: venue.links.map((l, i) => i == index ? { ...l, [key]: value } : l) });
    };

    return <Box>
        <Header />
        <Container variant="md" >
            <Flex direction={'column'} w={'100%'} p={8} alignItems={'center'}>
                <Heading>{venue && venue.id ? 'Edit' : 'Create New'} Venue</Heading>
            </Flex>
            <Flex wrap="wrap" justifyContent="center">
                <Heading size="md" mb={3} variant="subhead">Info</Heading>

                <FormControl variant="floating" pb={6} isRequired>
                    <Input placeholder=" " value={venue.name} onChange={(ev) => setVenue({ ...venue, name: ev.target.value })} />
                    <FormLabel>Venue Name
                        <ValidationError>{errors.name}</ValidationError>
                    </FormLabel>
                </FormControl>

                <FormControl variant="floating" pb={6} isRequired>
                    <Input placeholder=" " value={venue.contact} onChange={(ev) => setVenue({ ...venue, contact: ev.target.value })} />
                    <FormLabel>Contact Person
                        <ValidationError>{errors.contact}</ValidationError>
                    </FormLabel>
                </FormControl>

                <FormControl variant="floating" pb={6} >
                    <Input placeholder=" " value={venue.email} onChange={(ev) => setVenue({ ...venue, email: ev.target.value })} />
                    <FormLabel>Contact Email
                        <ValidationError>{errors.email}</ValidationError>
                    </FormLabel>
                </FormControl>

                <FormControl variant="floating" pb={6} isRequired>
                    <GooglePlacesAutocomplete
                        apiKey={process.env.REACT_APP_MAPS_KEY}
                        placeholder=" "
                        selectProps={{
                            chakraStyles: reactSelectStyles,
                            classNamePrefix: "chakra-react-select",
                            isClearable: true,
                            value: venue.address,
                            placeholder: "Enter an Address...",
                            onChange: (value) => setVenue({ ...venue, address: value }),
                        }} />
                    <FormLabel>Address
                        <ValidationError>{errors.address}</ValidationError>
                    </FormLabel>
                </FormControl>

                <FormControl variant="floating" pb={6} isRequired>
                    <Input placeholder=" " value={venue.phone} onChange={(ev) => setVenue({ ...venue, phone: ev.target.value })} />
                    <FormLabel>Phone #
                        <ValidationError>{errors.phone}</ValidationError>
                    </FormLabel>
                </FormControl>

                <FormControl variant="floating" pb={6} >
                    <Input placeholder=" " value={venue.website} onChange={(ev) => setVenue({ ...venue, website: ev.target.value })} />
                    <FormLabel>Website
                        <ValidationError>{errors.website}</ValidationError>
                    </FormLabel>
                </FormControl>

                <FormControl variant="floating" pb={6}>
                    <Textarea placeholder=" " value={venue.description} onChange={(ev) => setVenue({ ...venue, description: ev.target.value })} />
                    <FormLabel>Description</FormLabel>
                </FormControl>

                <Heading size="md" mb={3} mt={6} variant="subhead">Hours</Heading>
                <SimpleGrid columns={3} spacingY={4} spacingX={10}>
                    <Box></Box>
                    <Box w="300px">Open</Box>
                    <Box w="100px">Close</Box>
                    {['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map((day) => {
                        return <React.Fragment key={day}>
                            <Box key={`${day}1`}>{day}</Box>
                            <Box key={`${day}2`}>
                                <Select
                                    placeholder=" "
                                    value={venue[`${day.toLowerCase()}Open`] ? { value: venue[`${day.toLowerCase()}Open`], label: dayjs(venue[`${day.toLowerCase()}Open`], 'H:mm').format('h:mm A') } : null}
                                    onChange={(ev) => setVenue({ ...venue, [`${day.toLowerCase()}Open`]: ev.value })}
                                    chakraStyles={reactSelectStyles}
                                    classNamePrefix="chakra-react-select"
                                    options={_.flatten([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23].map((t) => [
                                        { value: `${t}:00`, label: t == 0 ? '12:00 AM' : (t > 12 ? t - 12 : t) + ':00' + (t > 11 ? ' PM' : ' AM') },
                                        { value: `${t}:30`, label: t == 0 ? '12:30 AM' : (t > 12 ? t - 12 : t) + ':30' + (t > 11 ? ' PM' : ' AM') }
                                    ]))}
                                />
                            </Box>
                            <Box key={`${day}3`}>
                                <Select
                                    placeholder=" "
                                    value={venue[`${day.toLowerCase()}Close`] ? { value: venue[`${day.toLowerCase()}Close`], label: dayjs(venue[`${day.toLowerCase()}Close`], 'H:mm').format('h:mm A') } : null}
                                    onChange={(ev) => setVenue({ ...venue, [`${day.toLowerCase()}Close`]: ev.value })}
                                    chakraStyles={reactSelectStyles}
                                    classNamePrefix="chakra-react-select"
                                    options={_.flatten([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23].map((t) => [
                                        { value: `${t}:00`, label: t == 0 ? '12:00 AM' : (t > 12 ? t - 12 : t) + ':00' + (t > 11 ? ' PM' : ' AM') },
                                        { value: `${t}:30`, label: t == 0 ? '12:30 AM' : (t > 12 ? t - 12 : t) + ':30' + (t > 11 ? ' PM' : ' AM') }
                                    ]))}
                                />
                            </Box>
                        </React.Fragment>
                    })}
                </SimpleGrid>


                <Heading size="md" mb={3} mt={10} variant="subhead">Banner Image</Heading>
                <FormControl variant="floating" pb={6} >
                    <Input placeholder=" " type="file" ref={banner} />
                    <FormLabel>
                        <ValidationError>{errors.banner}</ValidationError>
                    </FormLabel>
                </FormControl>

                <Heading size="md" mb={3} mt={10} variant="subhead">Logo Image</Heading>
                <FormControl variant="floating" pb={6} >
                    <Input placeholder=" " type="file" ref={image} />
                    <FormLabel>
                        <ValidationError>{errors.image}</ValidationError>
                    </FormLabel>
                </FormControl>


                <Heading size="md" mb={3} mt={10} variant="subhead">Links
                    <Button ml={4} variant="outline" onClick={() => addLink()}>+ Add a Link</Button>
                </Heading>
                {venue.links.map((link, idx) => <FormControl variant="floating" pb={4}>
                    <HStack>
                        <InputGroup>
                            <Input onChange={(ev) => setLink(idx, "url", ev.target.value)} type="text" placeholder=" " value={link.url} />
                            <FormLabel>Enter a Link</FormLabel>
                        </InputGroup>
                        <InputGroup>
                            <Input onChange={(ev) => setLink(idx, "label", ev.target.value)} type="text" placeholder=" " value={link.label} />
                            <FormLabel>Enter a Label</FormLabel>
                        </InputGroup>
                    </HStack>
                </FormControl>)}

                <Button w="100%" my={6} onClick={preSaveVenue}>Submit Venue for Approval</Button>
                <HStack w="100%" justify="space-between">
                    <Link to="/venues/mine">Cancel</Link>
                </HStack>

            </Flex>
        </Container>
        <Drawer placement={"bottom"} isOpen={isOpen} >
            <DrawerOverlay />
            <DrawerContent>
                <div style={{ flexDirection: 'row', justifyContent: 'center', display: 'flex', backgroundColor: 'var(--chakra-colors-lightNavy)' }}>
                    <div style={{ maxWidth: '80vw', padding: '1em', maxHeight: '60vh', overflow: 'auto' }}>
                        <Heading size="lg">Venue Beta Program Terms and Conditions</Heading>
                        <p><i>You must read and agree to these terms and conditions to submit your venue.</i></p>
                        <p>This Beta Testing Program Agreement (this “Agreement”) constitutes a legal agreement between you (“You,” “Your” or “Tester”) and Adventure Nexus, Inc., with its principal offices located at 235 Harrison St, #56, Syracuse NY 13202 (“TAN”) and states the terms and conditions that govern Your participation in TAN’s products and/or services offered on a preliminary, evaluation or “beta” testing basis. By using or participating in any such products and/or services, You agree to abide by the terms of this Agreement.</p>
                        <Heading mt={4} size="md">1. Scope of Agreement</Heading>
                        <p>Tester is being granted rights under this Agreement for the purpose of testing and providing input and other Feedback to TAN regarding one or more of TAN’s proprietary, non-commercially available products and/or services on a temporary basis (collectively, the “Beta Program”). This Agreement covers all Beta Products made available to Tester by TAN, including, without limitation, any Beta Products specifically identified by TAN as being in a preliminary, evaluation, “beta” or any similar state of development (individually and collectively, the “Beta Product(s)”). TAN retains sole and absolute discretion over the Beta Products and may modify them at any time. Tester’s use of and access to such Beta Products are subject to the terms of this Agreement.</p>
                        <Heading mt={4} size="md">2. Term and Termination</Heading>
                        <p>This Agreement shall commence on the date of Your first use of the Beta Product and continue until the earlier of: (i) termination of this Agreement in accordance with this Section 2; or (ii) a Public Launch (as defined below).</p>
                        <p>This Agreement may be terminated, and your access to the Beta Products may be revoked by TAN for any or no reason upon written notice to You.</p>
                        <p>Subject to Section 3, upon termination of this Agreement the license to the Beta Product granted under Section 4 shall immediately terminate, and You shall immediately cease use of the Beta Product and shall promptly return to TAN, if requested, any and all documentation or Confidential Information (including all copies thereof) then in Your possession, custody, or control.</p>
                        <Heading mt={4} size="md">3. Public Launch</Heading>
                        <p>TAN may, in its sole discretion and at any time, choose to make a Beta Product generally available to the public and remove the Beta Product from the Beta Program. Prior to TAN making a Beta Product generally available, TAN makes no representation that the Beta Product will comply with any potentially applicable laws (including regulations) during the Beta Program. You acknowledge that (i) additional laws and regulations may apply to a Beta Product upon general release, and (ii) You may be required to accept additional contract terms, pay for access to the generally available version of a Beta Product to enable continued use, or both. You acknowledge that participation is voluntary and for the sole purpose of assisting and benefiting TAN in the advancement of the Beta Program and Beta Product. You acknowledge that (a) TAN has not made any representations, promises, or guarantees that a Beta Product will ever be announced or made generally available or otherwise available to anyone, and (b) TAN has no express or implied obligation to You to announce or make a Beta Product generally available or otherwise available.</p>
                        <Heading mt={4} size="md">4. Limited Use Rights.</Heading>
                        <p>For the Term of this Agreement, TAN hereby grants Tester a nonexclusive, non-transferable, non-sublicensable, revocable, limited license to allow Tester’s designated participants to use the Beta Products to test their functionality and provide Feedback to TAN. TAN hosts and retains control over the Beta Products and only makes them available for access and use by Tester over the Internet through a Web-browser. TAN reserves the right to, in its sole discretion, (i) revoke access and use of the Beta Products any time, (ii) monitor or measure Tester’s use of the Beta Products, (iii) validate certain features or functionality of the Beta Products, and/or (iv) provide services or support necessary to maintain the Beta Products. Tester and/or its designated participants shall not and shall not attempt to directly or indirectly, (i) copy, modify, translate, or create derivative works of the Beta Products, (ii) reverse engineer, decompile, disassemble or otherwise attempt to reconstruct, identify or discover any source code, underlying ideas, underlying user interface techniques, or algorithms of any part of the Beta Products, (iii) lend, lease, offer for sale, sell or otherwise use any part of the Beta Products for the benefit of any other third parties, or (iv) attempt to circumvent any license, timing or use restrictions that are built into any part of the Beta Products.</p>
                        <Heading mt={4} size="md">5. Intellectual Property and Feedback</Heading>
                        <p>You acknowledge and agree that TAN owns all legal right, title, and interest in and to the Beta Program and each Beta Product, Feedback (as defined below), and any and all intellectual property (and other proprietary) rights therein or arising therefrom, including, but not limited to, any and all copyrights, patents, patent applications, trade secrets, trademarks, and other rights therein (collectively, the “Intellectual Property Rights”). To the extent You have the right to enforce or license any intellectual property (or other proprietary) rights needed for TAN’s exercise of the Intellectual Property Rights specified to it under this Agreement, You hereby grant to TAN a perpetual, irrevocable, sublicensable, royalty-free, worldwide license under such rights to exercise (including to utilize the subject matter of) such TAN rights. Except for the license and other rights expressly granted to You in this Agreement, You acknowledge that nothing contained in this Agreement shall be deemed to give You, directly or by implication, estoppel, or otherwise, any rights, forbearances, or waivers under any Intellectual Property Rights or other proprietary rights of TAN.</p>
                        <p>Each Beta Product is made available for purposes of evaluation and feedback in order to allow TAN to create improvements for the benefit of You and others, but without any financial compensation or reimbursement of any kind from TAN. You acknowledge the importance of communication with TAN while participating in the Beta Program and agree to receive correspondence and updates from TAN. You shall provide reasonable feedback and information, as requested. In the event You request to opt out from such communications, Your participation in the Beta Program, Your access to a Beta Product, or both, may be canceled. During the Beta Program, You and Your end users will be asked to provide feedback and information regarding the use of the Beta Product through monthly one (1) hour meetings which shall take place on such dates and times as mutually agreed to by You and TAN, and You acknowledge that TAN owns, and shall retain ownership of, all right, title, and interest in any suggestions, ideas, know-how, concepts, enhancements, feedback, recommendations or other information provided by You or any other party relating to a Beta Product or the Beta Program, including without limitation, new features or functionality relating thereto (collectively, “Feedback”). TAN or its licensors own and shall retain all proprietary rights, including all copyright, patent, trade secret, trademark, trade name, and all other intellectual property rights in and to each Beta Product and the Feedback. You hereby assign to TAN all right, title, and interest in, and TAN is free to use without any attribution or compensation to You, any Feedback relating to a Beta Product or underlying technology of a Beta Product. All Feedback is and will be treated as confidential until TAN, in its sole discretion, chooses to make any specific Feedback non-confidential.</p>
                        <Heading mt={4} size="md">6. Content</Heading>
                        <p>You or Your end user may provide, upload, or originate data, content, or other materials in the use of a Beta Product, which may be imported, accessed, used, stored, transmitted, reviewed, extracted, or processed by TAN based on instructions from You or the nature of the Beta Product (collectively, “Content”). Content also includes any resulting derivatives, analytics, outputs, visual text, displays, or data sets created by TAN resulting from the data, content, or other materials or sources provided by You or Your end user. You consent to TAN’s use of Content for the following reasons (the “Permitted Uses”): (i) consistent with this Agreement and as required to perform our obligations and provide the Beta Product; (ii) as authorized or instructed by You; (iii) as required by law; or (iv) for legal, safety or security purposes. You hereby grant TAN a perpetual, worldwide, nonexclusive, royalty-free, sublicensable, and transferable license and all other rights required or necessary for the Permitted Uses. You further agree that TAN owns all rights, title, and interest in and to product usage data, diagnostic data, and similar data that TAN collects or generates in connection with Your or Your designated users’ use of the Beta Product.</p>
                        <p>You are responsible for any Claims relating to any Content, including Claims that Content violates the intellectual property rights of any other third party. TAN reserves the right to remove from the Beta Product or its systems any Content that creates a risk to TAN, as determined by TAN in its sole discretion. You agree that TAN has no responsibility or liability for the deletion or failure to store Content and other communications maintained or transmitted through use of a Beta Product. You are solely responsible for securing and backing up Content.</p>
                        <p>You represent and warrant that, prior to making any Content available through Your use of a Beta Product, You have obtained all necessary consents with respect to the Content for participation in the Beta Program and use of the Beta Product, including, without limitation, for the sharing of such Content with TAN, its employees, consultants, and its authorized third parties. You acknowledge that You are ultimately responsible for assuring compliance with applicable privacy and data security requirements, including, without limitation, providing sufficient notice to any designated users regarding the collection and use of Content by You, TAN, and others as required by applicable law.</p>
                        <p>TAN may also access, use, preserve, or disclose Content if legally required to do so or on a good faith belief that such access, use, preservation, or disclosure is reasonably necessary to comply with legal process or request; to enforce this Agreement; to detect or prevent fraud, security, or technical issues; or to protect the rights, property, or safety of TAN, its end users, customers, or the public as permitted by law.</p>
                        <Heading mt={4} size="md">7. Confidential Information. </Heading>
                        <p>Tester acknowledges and agrees that its use of the Beta Products will result in TAN disclosing certain confidential, proprietary and/or trade secret information related to the Beta Products and/or Intellectual Property Rights (the “Confidential Information”). Tester agrees that it will not, without the express prior written consent of TAN, disclose any Confidential Information or any part thereof to any third party, except to the extent that such Confidential Information (i) is or becomes generally available to the public through any means other than as a result of any act or omission by Tester; (ii) is rightfully received by Tester from a third party that is not subject to any obligation of confidentiality with respect thereto and without limitation as to its use; (iii) is independently developed by Tester without any reliance on any Confidential Information; or (iv) with the express written consent of TAN.</p>
                        <Heading mt={4} size="md">8. Indemnification.</Heading>
                        <p>You shall defend, indemnify, and hold harmless TAN, its affiliates, and their respective officers, directors, members, employees, consultants, agents, suppliers and representatives from any third-party allegations, suits, claims, or demands and associated liabilities, costs, damages, and expenses (including, but not limited to, attorneys’ fees, expert fees, and court costs) (collectively, “Claims”) arising from or related to Your or Your end users’: (a) violation of this Agreement; (b) infringement or violation of the intellectual property rights of another; or (c) violation of any law, regulatory order, or similar government order.</p>
                        <Heading mt={4} size="md">9. Limitation on Liability</Heading>
                        <p>IN NO EVENT WILL TAN OR ITS AFFILIATES BE LIABLE TO YOU OR ANY OTHER PARTY FOR DAMAGES OF ANY KIND ARISING OUT OF OR RELATED TO THIS AGREEMENT, WHETHER RESULTING FROM A TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT, WARRANTY, OR OTHER FORM OF ACTION, AND INCLUDING, BUT NOT LIMITED TO, DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, PUNITIVE, OR EXEMPLARY DAMAGES, AND EVEN IF YOU HAVE ADVISED TAN OF THE POSSIBILITY OF SUCH DAMAGES. THE PARTIES AGREE THAT THE FOREGOING LIMITATION OF LIABILITY IS MATERIAL TO THIS AGREEMENT, AND THAT THESE LIMITATIONS APPLY NOTWITHSTANDING ANY FAILURE OF ESSENTIAL PURPOSE OF ANY REMEDY. BECAUSE SOME STATES AND JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY, THE ABOVE LIMITATION MAY NOT APPLY TO YOU. YOU ACKNOWLEDGE THAT THE BETA PRODUCT IS BEING PROVIDED AND MADE AVAILABLE ON AN “AS IS” OR “AS AVAILABLE” BASIS WITHOUT ANY WARRANTY OF ANY KIND.</p>
                        <p>YOU AGREE THAT THE BETA PRODUCT: (A) IS NOT COMPLETE IN DEVELOPMENT AND HAS NOT BEEN COMMERCIALLY RELEASED FOR SALE BY TAN; (B) MAY NOT BE FULLY FUNCTIONAL AND MAY CONTAIN BUGS, ERRORS, DESIGN FLAWS, OR OTHER PROBLEMS, INCLUDING PROBLEMS THAT MAY ADVERSELY IMPACT THE OPERATION OF YOUR INFRASTRUCTURE OR SERVICES PROVIDED BY TAN OR ANOTHER PARTY; (C) MAY NOT BE RELIABLE; (D) MAY NOT HAVE BEEN EVALUATED FOR REGULATORY COMPLIANCE AND MAY NOT MEET REQUIREMENTS FOR TRANSMITTING, STORING, CREATING, OR OTHERWISE PROCESSING ELECTRONIC PROTECTED HEALTH INFORMATION, PERSONALLY IDENTIFIABLE INFORMATION, PERSONAL INFORMATION, OR OTHER SENSITIVE OR CONFIDENTIAL INFORMATION; (E) WHEN USED, MAY RESULT IN UNEXPECTED RESULTS, LOSS OF CONTENT OR DATA, OR OTHER UNPREDICTABLE DAMAGE OR LOSS TO YOU. TAN EXPRESSLY DISCLAIMS ANY WARRANTY AS TO THE ACCURACY OR COMPLETENESS OF ANY INFORMATION, CONTENT, OR DATA ACCESSED OR USED IN CONNECTION WITH A BETA PRODUCT</p>
                        <Heading mt={4} size="md">10. Miscellaneous</Heading>
                        <p>This Agreement does not create a partnership, agency relationship, or joint venture between the parties. Tester may not assign this Agreement in whole or in part, by operation of law or otherwise, without TAN’s prior written consent, and the terms hereof are binding on Tester’s successors and permitted assigns. If any provision of this Agreement is deemed unenforceable by a court of competent jurisdiction, that provision will be modified to render it enforceable to the extent possible to affect the parties’ intention and the remaining provisions will remain in full force and effect. Failure of TAN to enforce a right under this Agreement shall not act as a waiver of that right or the ability to later assert that right relative to the particular situation involved. This Agreement shall be governed exclusively by the laws of the State of New York, without regard to the State of New York’s conflict of law provisions. The state and federal courts in Monroe County, New York shall have exclusive jurisdiction and venue over any dispute arising out of or relating to this Agreement, and each party consents to the personal jurisdiction and venue of these courts.</p>

                        <Button mt={4} w="100%" onClick={saveVenue}>I agree, Submit Venue</Button>
                    </div>
                </div>
            </DrawerContent>
        </Drawer>
    </Box>
}

export default CreateVenuePage;
